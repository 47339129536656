const formatDate = (value) => {
  const date = new Date(value);
  const now = new Date();

  const interval = {
    days: Math.floor((now.valueOf() - date.valueOf()) / (1000 * 60 * 60 * 24)),
    hours: Math.floor((now.valueOf() - date.valueOf()) / (1000 * 60 * 60)),
    minutes: Math.floor((now.valueOf() - date.valueOf()) / (1000 * 60))
  };

  const day = date.getDate(); // Day of the month without leading zeros
  const month = date.toLocaleString("default", { month: "long" }); // Full textual representation of the month
  const year = date.getFullYear(); // Full numeric representation of the year

  let suffix;
  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  } else {
    suffix = "th";
  }

  const formatted_date = `${month} ${day}${suffix}, ${year}`;

  if (interval.days < 7) {
    if (interval.days > 0) {
      if (interval.days == 1) {
        return `${interval.days} day ago`;
      } else {
        return `${interval.days} days ago`;
      }
    } else if (interval.hours > 0) {
      if (interval.hours == 1) {
        return `${interval.hours} hour ago`;
      } else {
        return `${interval.hours} hours ago`;
      }
    } else if (interval.minutes > 0) {
      if (interval.minutes == 1) {
        return `${interval.minutes} minute ago`;
      } else {
        return `${interval.minutes} minutes ago`;
      }
    } else {
      return "Just now";
    }
  } else {
    return formatted_date;
  }
};

export default formatDate;
