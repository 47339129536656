import React from "react";
import { BaseIconProps } from "./icon.types";

function LinkIcon({ color = "#9D9D9D", className }: BaseIconProps) {
  const classes = className ?? "";

  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.58293 7.32519C4.46305 7.43709 4.37028 7.51199 4.27547 7.56674C3.81136 7.83469 3.23957 7.83469 2.77547 7.56674C2.60444 7.46799 2.44005 7.30359 2.11125 6.97484C1.78246 6.64604 1.61806 6.48164 1.51932 6.31059C1.25137 5.84649 1.25137 5.27469 1.51932 4.81059C1.61806 4.63959 1.78246 4.47519 2.11125 4.14639L3.52547 2.73218C3.85426 2.40339 4.01866 2.23899 4.18968 2.14025C4.65378 1.8723 5.22559 1.8723 5.68969 2.14025C5.86069 2.23899 6.02509 2.40339 6.35389 2.73218C6.68269 3.06098 6.84709 3.22537 6.94584 3.39639C7.21379 3.86049 7.21379 4.43229 6.94584 4.89639C6.89109 4.99119 6.81619 5.08399 6.70424 5.20384M5.29644 3.79604C5.18449 3.91589 5.10959 4.00869 5.05484 4.10349C4.78691 4.56759 4.78691 5.13939 5.05484 5.60349C5.15359 5.77454 5.31799 5.93894 5.64679 6.26769C5.97559 6.59649 6.13999 6.76089 6.31099 6.85964C6.77509 7.12759 7.34689 7.12759 7.81099 6.85964C7.98204 6.76089 8.14644 6.59649 8.47524 6.26769L9.88944 4.85349C10.2182 4.52469 10.3826 4.36029 10.4813 4.18929C10.7493 3.72519 10.7493 3.15339 10.4813 2.68929C10.3826 2.51827 10.2182 2.35387 9.88944 2.02507C9.56064 1.69628 9.39624 1.53188 9.22524 1.43314C8.76109 1.16519 8.18934 1.16519 7.72524 1.43314C7.63039 1.48788 7.53764 1.5628 7.41774 1.67472"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default LinkIcon;
