import React from "react";
import { requestAccess } from "@/services/asset-service";
import { useState } from "react";
import styles from "../SuperTables/FilesSuperTable/FilesSuperTable.module.scss";
import { ACCESS_REQUEST_STATUSES } from "./RequesAccessTemplate.types";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";

function RequestAccessTemplate({ assetId }: { assetId: string }) {
  const [requestStatus, setRequestStatus] = useState<ACCESS_REQUEST_STATUSES>(
    ACCESS_REQUEST_STATUSES.UNREQUESTED
  );

  const { addToast } = useSuperToast();

  const handleRequestAccess = async () => {
    const response = await requestAccess(assetId);
    switch (response.data?.message) {
      case "Accepted asset":
        setRequestStatus(ACCESS_REQUEST_STATUSES.GRANTED);
        break;
      case "Requested asset":
        setRequestStatus(ACCESS_REQUEST_STATUSES.PENDING);
        break;
      case "The email field is required.":
        setRequestStatus(ACCESS_REQUEST_STATUSES.UNREQUESTED);
        break;
      default:
        setRequestStatus(ACCESS_REQUEST_STATUSES.UNREQUESTED);
        if (response?.error) {
          addToast("There was an error sending your request.", ToastTypes.FAIL);
        }
        break;
    }
  };

  return (
    <div className={styles.rowActionCell}>
      <button
        className={styles.requestAccessButton}
        onClick={handleRequestAccess}
        disabled={requestStatus !== ACCESS_REQUEST_STATUSES.UNREQUESTED}
      >
        {requestStatus}
      </button>
    </div>
  );
}

export default RequestAccessTemplate;
