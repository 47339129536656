import { Button } from "@mui/material";
import React from "react";
import styles from "./PromptSignIn.module.scss";

import { useModal } from "@/components/shared/Modal/useModal";
import { useCurrentScreen } from "@/contexts/screen/screen.hook";
import { SCREEN_TYPES } from "@/types/contexts/screen-context";
import { useBottomSheet } from "@/components/shared/BottomSheet/useBottomSheet";

const PromptSignIn = () => {
  const { closeModal } = useModal();
  const { closeBottomSheet } = useBottomSheet();
  const { setCurrentScreen } = useCurrentScreen();

  return (
    <div className={styles.promptSignInContainer}>
      <span className="title">Sign in or sign up to send a file</span>

      <div>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          className={`super-button brand-secondary ${styles.goToLoginButton}`}
          size="small"
          onClick={() => {
            closeModal();
            closeBottomSheet();
            setCurrentScreen(SCREEN_TYPES.LOGIN);
          }}
        >
          Go to Login
        </Button>
      </div>
    </div>
  );
};

export default PromptSignIn;
