import React, { useCallback, useEffect, useState, useRef } from "react";
import AssetsContext from "./assets.context";
import {
  createAsset,
  deleteAsset,
  getAssetInformation,
  getAssets,
  updateAsset,
  getActivity,
  getSharedUsers,
  updateSharedUserAccessType,
  deleteSharedUserAccess,
  getAssetsFromPathWithFolder,
  getAssetByOwner,
  getManifests
} from "@/services/asset-service";
import { components, operations } from "@/types/beta.schema";
import { isApiErrorResponse } from "@/lib/error";
import { useAuth } from "../auth/auth.hook";
import {
  AssetProviderProps,
  AssetContextType,
  FolderState
} from "./assets.types";
import { getSortData } from "@/lib/sort";
import { sortQuery } from "@/components/screens/Dashboard/partials/SuperTables/FilesSuperTable/data/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import {
  doesPathContainAssets,
  getAssetPathFromPath,
  getOwnerNameFromPath,
  getPathForRequest
} from "@/lib/files";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { useProfile } from "../profile/profile.hook";
import { getUserInfo } from "@/services/users-service";
import { SortField } from "@/components/screens/Dashboard/partials/SuperTables/FilesSuperTable/data/helpers.types";
import { SortOrder } from "primereact/datatable";
import { MapViewer } from "@/components/SuperMap/partials/CustomMarker/CustomMarker.types";
import { IStreamConfiguration } from "@/components/shared/ShakaPlayer/ShakaPlayer.interface";
import { LinkDto, MetaDto } from "@/types/network";

export const AssetsProvider = ({ children }: AssetProviderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { addToast } = useSuperToast();
  const { viewingProfile } = useProfile();

  const [assets, setAssets] = useState<
    Array<components["schemas"]["AssetData"]>
  >([]);
  const [meta, setMeta] = useState<MetaDto>(null);
  const [links, setLinks] = useState<Array<LinkDto>>(null);

  const [uploadBuffer, setUploadBuffer] = useState<
    Array<components["schemas"]["AssetData"]>
  >([]);

  const [currentAsset, setCurrentAsset] = useState<string>("");

  const [assetActivity, setAssetActivity] = useState<
    Array<components["schemas"]["ActivityData"]>
  >([]);

  const [manifests, setManifests] = useState<IStreamConfiguration>(null);

  const assetChannel = useRef(null);

  const [currentlyViewing, setCurrentlyViewing] = useState(
    new Map<number, MapViewer>()
  );

  const [folderState, setFolderState] = useState<FolderState>({
    currentFolder: null,
    breadcrumbs: []
  });

  // Load sort data stored in sessionStorage
  const sortData = getSortData();
  const [sortField, setSortField] = useState<SortField>(sortData?.sortField);
  const [sortOrder, setSortOrder] = useState<SortOrder>(sortData?.sortOrder);

  // Search Query
  const [searchQuery, setSearchQuery] = useState<string>("");

  const fetchActivityOnSelect = async () => {
    if (currentAsset) {
      const activityResponse = await activityLog(currentAsset);
      if (isApiErrorResponse(activityResponse)) {
        console.error(activityResponse.message);
      } else {
        setAssetActivity(activityResponse.data);
      }
    }
  };

  const fetchManifestsOnSelect = async () => {
    if (currentAsset) {
      const manifestResponse = await getManifests(currentAsset);
      setManifests(manifestResponse);
    }
  };

  const refresh = async (
    payload?: operations["assets.index"]["parameters"]["query"],
    reload?: boolean
  ) => {
    const sortPayload = {
      ...payload,
      sort: payload?.sort ?? sortQuery(sortField, sortOrder),
      "filter[search]": payload?.["filter[search]"] ?? searchQuery
    };
    const fetchedAssets = await fetchAssets(sortPayload);
    if (isApiErrorResponse(fetchedAssets)) {
      console.error(fetchedAssets.message);
    } else {
      if (!reload && assets.length < meta?.total) {
        setAssets((prev) => {
          const merged = [...prev, ...fetchedAssets.data];

          // Remove duplicates based on unique ID
          const uniqueAssets = Array.from(
            new Map(merged.map((item) => [item.id, item])).values()
          );

          return uniqueAssets;
        });
      } else {
        setAssets(fetchedAssets.data);
      }
      setMeta(fetchedAssets.meta);
      setLinks(fetchedAssets.links);
    }
  };

  useEffect(() => {
    fetchActivityOnSelect();
    fetchManifestsOnSelect();
  }, [currentAsset]);

  const retrieveAssets = useCallback(
    async (path: string) => {
      try {
        const pathContainsAssets = doesPathContainAssets(path);
        if (pathContainsAssets) {
          const _path = getPathForRequest(path);

          const assetPathFromPath = getAssetPathFromPath(path);
          const ownerNameFromPath = getOwnerNameFromPath(path);

          const { data: incomingAssetDetails } = await getAssetByOwner(
            ownerNameFromPath,
            assetPathFromPath
          );

          const isIncomingAssetAFolder = incomingAssetDetails.type === "folder";

          if (isIncomingAssetAFolder) {
            const assetsResponse = await getAssetsFromPathWithFolder(_path);
            if (assetsResponse.status !== 200) {
              addToast("This asset does not exist", ToastTypes.FAIL);

              const usernameFromParams = `${location.pathname.split("/")[1]}`;
              const { data: incomingUser } =
                await getUserInfo(usernameFromParams);

              const {
                data: {
                  meta: incomingMeta,
                  data: incomingAssets,
                  links: incomingLinks
                },
                error
              } = await getAssets({
                owner_id: incomingUser.data.id
              });
              if (error) {
                addToast(error.message, ToastTypes.FAIL);
                return navigate("/");
              } else {
                setAssets(incomingAssets);
                setFolderState({
                  currentFolder: null,
                  breadcrumbs: []
                });
                setMeta(incomingMeta);
                setLinks(incomingLinks);
                return navigate(`/${usernameFromParams}`, {
                  state: viewingProfile
                });
              }
            }

            const {
              error,
              data,
              breadcrumbs,
              folder,
              meta: incomingMeta,
              links: incomingLinks
            } = assetsResponse;
            if (error) {
              addToast(error.message, ToastTypes.FAIL);
            } else {
              setAssets(data);
              setFolderState({
                currentFolder: folder,
                breadcrumbs
              });
              setMeta(incomingMeta);
              setLinks(incomingLinks);
            }
          }
        }
      } catch (error) {
        console.error("Error retrieving assets:", error);
        navigate("/");
      }

      return () => {
        setAssets([]);
      };
    },
    [user]
  );

  useEffect(() => {
    const path = location.pathname;
    retrieveAssets(path);
  }, [uploadBuffer, user, retrieveAssets, location.pathname]);

  const upload = async (payload: components["schemas"]["CreateAssetData"]) => {
    const { data, error } = await createAsset(payload);
    if (data) {
      return data.data;
    } else {
      return { message: error.message };
    }
  };

  const fetchAssets = async (
    payload?: operations["assets.index"]["parameters"]["query"]
  ) => {
    const { data, error } = await getAssets(payload);
    return data ? data : { message: error.message };
  };

  const remove = async (assetId: string) => {
    const data = await deleteAsset(assetId);
    return { ...data, assetId };
  };

  const getAssetDetails = async (assetId: string) => {
    const { data, error } = await getAssetInformation(assetId);
    return data ?? { message: error.message };
  };

  const update = async (
    assetId: string,
    payload: components["schemas"]["UpdateAssetData"]
  ) => {
    const { data, error } = await updateAsset(assetId, payload);
    return data ? data.data : { message: error.message };
  };

  const activityLog = async (
    assetId: string,
    payload?: operations["assets.activity.show"]["parameters"]["query"]
  ) => {
    const { data, error } = await getActivity(assetId, payload);
    return data ? data : { message: error.message };
  };

  const getAssetSharedUsers = async (assetId: string) => {
    const { data, error } = await getSharedUsers(assetId);
    return data ? data.data : { message: error.message };
  };

  const updateAccessType = async (
    assetId: string,
    payload: components["schemas"]["UpdateAssetShareData"]
  ) => {
    const { data, error } = await updateSharedUserAccessType(assetId, payload);
    return data ? data.data : { message: error.message };
  };

  const deleteUserAccess = async (assetId: string, userId: string) => {
    const { statusCode, error } = await deleteSharedUserAccess(assetId, userId);

    return { statusCode, error };
  };

  const joinAssetChannel = (assetId?: string) => {
    if (assetId || currentAsset) {
      assetChannel.current = window.Echo.join(
        `asset.${assetId ?? currentAsset}`
      );
      assetChannel.current.here((users) => {
        const temp = new Map();
        users.forEach((user) => {
          temp.set(user.id, user);
        });
        setCurrentlyViewing(temp);
      });
    }
  };

  const leaveAssetChannel = (assetId?: string) => {
    if (assetChannel.current && (assetId ?? currentAsset)) {
      window.Echo.leave(`asset.${assetId ?? currentAsset}`);
      assetChannel.current = null;
    }
  };

  const value: AssetContextType = {
    assets,
    uploadBuffer,
    setUploadBuffer,
    meta,
    setMeta,
    remove,
    upload,
    update,
    fetchAssets,
    getAssetDetails,
    refresh,
    activityLog,
    getAssetSharedUsers,
    currentAsset,
    setCurrentAsset,
    updateAccessType,
    deleteUserAccess,
    setAssets,
    folderState,
    setFolderState,
    sortOrder,
    setSortOrder,
    sortField,
    setSortField,
    assetChannel,
    joinAssetChannel,
    leaveAssetChannel,
    assetActivity,
    manifests,
    currentlyViewing,
    setCurrentlyViewing,
    searchQuery,
    setSearchQuery,
    links,
    setLinks
  };

  return (
    <AssetsContext.Provider value={value}>{children}</AssetsContext.Provider>
  );
};
