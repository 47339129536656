import { ApiErrorResponse } from "@/types/network";

/**
 * Checks if the payload is considered an error response from the Supers API.
 *
 * @param {object} payload - The payload to check.
 * @returns {boolean} `true` if the payload contains the field `message`; otherwise, `false`.
 */
export const isApiErrorResponse = (payload): payload is ApiErrorResponse => {
  return (
    payload !== null && typeof payload === "object" && "message" in payload
  );
};
