import React from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Button,
  FormHelperText
} from "@mui/material";
import { useFormik } from "formik";
import { FolderSelectionTemplateProps } from "./FolderSelectionTemplate.types";
import styles from "./FolderSelectionTemplate.module.scss";

function FolderSelectionTemplate({
  foldersInfo,
  onSubmit
}: FolderSelectionTemplateProps) {
  const defaultFolderId = foldersInfo[0].folderId;

  const formik = useFormik({
    initialValues: {
      folderId: defaultFolderId
    },
    onSubmit: (values) => {
      const selectedFolder = foldersInfo.find(
        (folderInfo) => folderInfo.folderId === values.folderId
      );

      onSubmit(selectedFolder.folderId);
    }
  });

  return (
    <div>
      <span className="title">Select a folder</span>

      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <FormControl
          fullWidth
          error={Boolean(formik.touched.folderId && formik.errors.folderId)}
          sx={{
            backgroundColor: "var(--dark-200) !important",
            borderRadius: "30px",
            paddingLeft: "16px",
            paddingRight: "16px",
            border: "1px solid var(--dark-300)"
          }}
        >
          <Select
            id="folder-select"
            value={formik.values.folderId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="folderId"
            displayEmpty
            sx={{
              "& .MuiSelect-select": {
                padding: "8px 16px 8px 16px"
              },
              color: "white",
              "& .MuiSelect-icon": {
                color: "white"
              },
              "& .MuiSelect-root": {
                backgroundColor: "#ffffff !important",
                borderRadius: "8px"
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
              }
            }}
          >
            <MenuItem value="" disabled>
              Select a folder
            </MenuItem>
            {foldersInfo.map((folderInfo) => (
              <MenuItem
                key={folderInfo.folderId}
                value={folderInfo.folderId}
                sx={{
                  padding: "10px 16px",
                  "&:hover": {
                    backgroundColor: "#f0f0f0"
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#e0e0e0",
                    "&:hover": {
                      backgroundColor: "#d6d6d6"
                    }
                  }
                }}
              >
                {folderInfo.folderName}
              </MenuItem>
            ))}
          </Select>
          {formik.touched.folderId && formik.errors.folderId && (
            <FormHelperText>{formik.errors.folderId}</FormHelperText>
          )}
        </FormControl>

        <Button
          color="primary"
          variant="contained"
          type="submit"
          className={`${styles.button} super-button brand-secondary`}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

export default FolderSelectionTemplate;
