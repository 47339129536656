import React, { useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./PreviewModal.module.scss";
import { usePreviewModal } from "./PreviewModal.hook";
import { modalVariants } from "./data/animation";
import FullscreenAnalogPreview from "@/components/shared/asset-previews/fullscreen/fullscreen-analog-preview/FullscreenAnalogPreview.component";

function PreviewModal() {
  const { isOpen, assetInfo } = usePreviewModal();
  const modalRef = useRef(null);

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className={styles.overlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          />
          <motion.div
            ref={modalRef}
            className={styles.modal}
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={modalVariants}
            transition={{
              duration: 0.5,
              type: "spring",
              damping: 25,
              stiffness: 150
            }}
          >
            <div className={styles.modalContent}>
              <FullscreenAnalogPreview
                isPopup
                ownerName={assetInfo.ownerName}
                assetId={assetInfo.assetId}
              />
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}

export default PreviewModal;
