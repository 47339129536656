import { InvitationResponse } from "@/types/services/auth-service";

export enum RegistrationScreen {
  PASSWORD = "PASSWORD",
  CODE = "CODE",
  ACCEPTED = "ACCEPTED",
  CONFIRMATION = "CONFIRMATION"
}
/* eslint-disable @typescript-eslint/no-empty-interface */
export interface InvitationDetailsState extends InvitationResponse {}
