import React from "react";
import styles from "../SuperTables/FilesSuperTable/FilesSuperTable.module.scss";
import {
  FOLDER_ACTION_OPTIONS,
  MenuActionTemplateProps
} from "./MenuActionTemplate.types";
import { useAssets } from "@/contexts/assets/assets.hook";
import { usePreviewPanel } from "@/components/shared/asset-previews/preview-panel/usePreviewPanel";
import useResponsive from "@/hooks/useResponsive";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { deleteAsset } from "@/services/asset-service";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { useModal } from "@/components/shared/Modal/useModal";
import RenameFolderTemplate from "../rename-folder-template/RenameFolderTemplate.component";
import FolderSettingsTemplate from "../folder-settings-template/FolderSettingsTemplate.component";
import { useBottomSheet } from "@/components/shared/BottomSheet/useBottomSheet";

function MenuActionTemplate({ asset }: MenuActionTemplateProps) {
  const { setCurrentAsset, refresh, folderState, assetChannel } = useAssets();
  const { openPreviewPanel } = usePreviewPanel();
  const { addToast } = useSuperToast();
  const { openModal, closeModal } = useModal();
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const { isMobile, isDesktop } = useResponsive();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const isFolder = asset.type === "folder";

  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (isFolder) {
      return setAnchorEl(event.currentTarget);
    }

    setCurrentAsset((prev) => {
      if (prev !== asset.id && window?.Echo) {
        window?.Echo?.leave(`asset.${prev}`);
        assetChannel.current = null;
      }
      return asset.asset_id ?? asset.id;
    });
    openPreviewPanel({ content: null });
  };

  const handlePopupClose = () => {
    closeModal();
    closeBottomSheet();
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (
    e: React.MouseEvent<HTMLElement>,
    option: FOLDER_ACTION_OPTIONS
  ) => {
    switch (option) {
      case FOLDER_ACTION_OPTIONS.DELETE: {
        const { message } = await deleteAsset(asset.id);

        if (message !== "Asset deleted") {
          console.error(message);
          return addToast(message, ToastTypes.FAIL);
        }

        addToast("Folder has been deleted", ToastTypes.SUCCESS);
        return refresh({ folder_id: folderState?.currentFolder?.id }, true);
      }

      case FOLDER_ACTION_OPTIONS.RENAME: {
        handleMenuClose(e);

        if (isMobile) {
          return openBottomSheet({
            content: (
              <RenameFolderTemplate asset={asset} onClose={handlePopupClose} />
            ),
            showActions: false
          });
        }

        return openModal({
          content: (
            <RenameFolderTemplate asset={asset} onClose={handlePopupClose} />
          ),
          showActions: false
        });
      }

      case FOLDER_ACTION_OPTIONS.TRANSFER:
        console.log("Transfer Ownership clicked");
        break;

      case FOLDER_ACTION_OPTIONS.SETTINGS:
        handleMenuClose(e);

        if (isMobile) {
          return openBottomSheet({
            content: (
              <FolderSettingsTemplate
                asset={asset}
                onClose={handlePopupClose}
              />
            ),
            showActions: false
          });
        }

        return openModal({
          content: (
            <FolderSettingsTemplate asset={asset} onClose={handlePopupClose} />
          ),
          showActions: false
        });
      default:
        console.warn("Unhandled menu action:", option);
    }
  };

  const getInfoIcon = () => {
    return isDesktop ? (
      <img src="/images/information-circle-solid.svg" alt="info icon" />
    ) : (
      <img
        className={styles.infoIconMobile}
        src="/images/mobile_info_icon.svg"
        alt="info icon"
      />
    );
  };

  const renderContent = () => {
    return (
      <button className={styles.iconContainer} onClick={handleIconClick}>
        {getInfoIcon()}
        {isFolder && (
          <Menu
            id={asset.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
          >
            {Object.values(FOLDER_ACTION_OPTIONS).map((option) => (
              <MenuItem
                key={option}
                onClick={(e) => handleMenuItemClick(e, option)}
                selected={false}
                classes={{ root: styles.menuItem }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        )}
      </button>
    );
  };

  return renderContent();
}

export default MenuActionTemplate;
