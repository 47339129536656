import React from "react";
import { BaseIconProps } from "./icon.types";

function FriendsIcon({ color = "white", className }: BaseIconProps) {
  const classes = className ? `${className}` : "";

  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="friend_svgrepo.com">
        <path
          id="Vector"
          d="M8.56137 11.28C10.1167 11.28 11.3776 10.0191 11.3776 8.46371C11.3776 6.90834 10.1167 5.64746 8.56137 5.64746C7.006 5.64746 5.74512 6.90834 5.74512 8.46371C5.74512 10.0191 7.006 11.28 8.56137 11.28Z"
          stroke={color}
        />
        <path
          id="Vector_2"
          d="M14.2499 18.7273C14.2499 17.9785 14.1024 17.237 13.8157 16.5452C13.529 15.8534 13.1088 15.2249 12.5791 14.6955C12.0494 14.1662 11.4206 13.7464 10.7286 13.4602C10.0366 13.1739 9.29504 13.0269 8.54619 13.0273C7.03446 13.0273 5.58464 13.6279 4.51568 14.6968C3.44673 15.7658 2.84619 17.2156 2.84619 18.7273H14.2499Z"
          stroke={color}
        />
        <path
          id="Vector_3"
          d="M16.5485 12.4765C17.8015 12.4765 18.8173 11.4607 18.8173 10.2077C18.8173 8.95472 17.8015 7.93896 16.5485 7.93896C15.2955 7.93896 14.2798 8.95472 14.2798 10.2077C14.2798 11.4607 15.2955 12.4765 16.5485 12.4765Z"
          stroke={color}
        />
        <path
          id="Vector_4"
          d="M15.9001 18.7277H21.1501C21.1501 18.124 21.031 17.5262 20.7996 16.9686C20.5682 16.411 20.2291 15.9045 19.8017 15.4781C19.3743 15.0517 18.867 14.7139 18.3088 14.4839C17.7506 14.2539 17.1525 14.1362 16.5488 14.1377C15.797 14.1367 15.0564 14.3209 14.3926 14.674"
          stroke={color}
        />
      </g>
    </svg>
  );
}

export default FriendsIcon;
