import React from "react";
import { BaseIconProps } from "./icon.types";

function DownloadIcon({ className }: BaseIconProps) {
  const classes = className ? `${className}` : "";

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <g clipPath="url(#clip0_1978_8611)">
        <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
        <path
          d="M13.5061 11.4386C14.6016 10.6683 15.0714 9.27665 14.6669 7.99992C14.2624 6.72319 13.0237 6.02372 11.6844 6.02475H10.9107C10.405 4.05369 8.73623 2.59849 6.71466 2.36565C4.69306 2.13281 2.73723 3.17052 1.7966 4.97502C0.855966 6.77952 1.12531 8.97715 2.47388 10.5011"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.0028 13.6667L8 7.66675"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1216 11.5454L8.00024 13.6667L5.87891 11.5454"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1978_8611">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DownloadIcon;
