import { components } from "@/types/beta.schema";

export interface NotificationItemTemplateProps {
  notificationItem: NotificationItem;
  updateNotifications: (refreshOnClose?: boolean) => void;
}

export interface NotificationItem {
  notification: components["schemas"]["NotificationData"];
  sharedAsset: components["schemas"]["AssetData"];
}

export enum ACCESS_REQUEST_RESPONSE {
  REQUEST_APPROVED = "Request approved",
  REQUEST_DENIED = "Request denied",
  NO_RESPONSE = "No response"
}
