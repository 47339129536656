import React from "react";
import { BackButtonProps } from "./BackButton.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import styles from "./BackButton.module.scss";

function BackButton({ text, onClick }: BackButtonProps) {
  return (
    <motion.button
      className={styles.actionText}
      onClick={onClick}
      whileHover="hover"
      whileFocus="hover"
      initial="initial"
      variants={{
        initial: {},
        hover: {}
      }}
    >
      <motion.div
        initial={{ width: 0, opacity: 0 }}
        variants={{
          initial: { width: 0, opacity: 0, marginRight: "0px" },
          hover: { width: "auto", opacity: 1, marginRight: "8px" }
        }}
        transition={{ type: "tween", duration: 0.25 }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </motion.div>
      <span className={styles.text}>{text}</span>
    </motion.button>
  );
}

export default BackButton;
