import React, { memo } from "react";
import styles from "../information-tab.module.scss";
import createdDate from "@/lib/created-date";
import { AssetMetadataProps } from "../information-tab.types";
import { useAuth } from "@/contexts/auth/auth.hook";
import { getUserProfileImage } from "@/lib/user";
import { useProfile } from "@/contexts/profile/profile.hook";
import { UserDetails } from "@/types/contexts/user-context";

function AssetMetadata({
  assetResource,
  isShortcut,
  isOwner
}: AssetMetadataProps) {
  const { user } = useAuth();
  const { viewingProfile } = useProfile();

  let owner: UserDetails | null;

  if (!assetResource) return null;

  if (isShortcut) {
    owner = assetResource.asset.owner;
  } else if (viewingProfile) {
    owner = viewingProfile;
  } else if (isOwner) {
    owner = user;
  } else {
    owner = null;
  }

  return (
    <ul className={styles.metadataList}>
      {owner && (
        <li>
          <span>Owner</span>
          <span className={styles.metadataOwner}>
            <img src={getUserProfileImage(owner.profile?.avatar)} />
            {owner.username}
          </span>
        </li>
      )}
      <li>
        <span>File name</span>
        <span>
          {isShortcut ? assetResource.asset.name : assetResource.name}
        </span>
      </li>
      <li>
        <span>Creation Date</span>
        <span>
          {createdDate(
            isShortcut
              ? assetResource.asset.created_at
              : assetResource.created_at
          )}
        </span>
      </li>
      <li className={styles.metadataHash}>
        <span>Hash</span>
        <span>{isShortcut ? assetResource.asset.md5 : assetResource.md5}</span>
      </li>
    </ul>
  );
}

export default memo(AssetMetadata, (prevProps, nextProps) => {
  if (
    (prevProps.assetResource?.id ?? prevProps.assetResource?.asset_id) !==
    (nextProps.assetResource?.id ?? nextProps.assetResource?.asset_id)
  ) {
    return false;
  }
  return true;
});
