import React, { useEffect, useState } from "react";
import PlayerContext from "./player.context";
import { PlayerProviderProps } from "@/types/contexts/player-context";
import { getPlayerCapabilities } from "@/utils/player";
import usePlayerCapabilities from "@/hooks/usePlayerCapabilities";
import {
  DRM_SYSTEM,
  IPlayerCapabilities
} from "@/components/shared/ShakaPlayer/ShakaPlayer.interface";

const defaultPlayerCapabilities: IPlayerCapabilities = {
  allowPlayback: false,
  drm: DRM_SYSTEM.NODRM,
  drmError: false,
  ipAddress: "",
  isDeviceSupported: false,
  isLocationAllowed: true,
  isL1Enabled: false,
  loaded: false,
  supportedDevice: null,
  isAppleDevice: false,
  isWindowsDevice: false
};

export const PlayerProvider = ({ children }: PlayerProviderProps) => {
  usePlayerCapabilities();

  const [playerCapabilities, setPlayerCapabilities] =
    useState<IPlayerCapabilities>(defaultPlayerCapabilities);

  const [playerRef, setPlayerRef] = useState<null | HTMLVideoElement>(null);

  useEffect(() => {
    if (!playerCapabilities.loaded) {
      getPlayerCapabilities().then((response) => {
        setPlayerCapabilities({
          ...playerCapabilities,
          loaded: true,
          ...response
        });
      });
    }
  }, [playerCapabilities]);

  const value = {
    playerCapabilities,
    setPlayerCapabilities,
    playerRef,
    setPlayerRef
  };

  return (
    <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
  );
};
