import React, { useEffect } from "react";
import Step1 from "./step-1/Step1.component";
import { useSecureFile } from "./useSecureFile";
import { SecureFileContentProps } from "./secure-file.types";

function SecureFileContent({
  files,
  initialSharedUsers
}: SecureFileContentProps) {
  const { setFiles } = useSecureFile();

  useEffect(() => {
    setFiles(files);
  }, [setFiles, files]);

  const renderContent = () => {
    return <Step1 initialSharedUsers={initialSharedUsers} />;
  };

  return <div>{renderContent()}</div>;
}

export default SecureFileContent;
