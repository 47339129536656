import React, { useState } from "react";
import BottomSheetContext from "./BottomSheet.context";
import {
  BottomSheetContextType,
  BottomSheetProviderProps,
  OpenBottomSheetParams
} from "./BottomSheet.types";

function BottomSheetProvider({ children }: BottomSheetProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<React.ReactNode | null>(null);
  const [showActions, setShowActions] = useState(null);
  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(
    null
  );
  const [classes, setClasses] = useState(null);

  const openBottomSheet = (params: OpenBottomSheetParams) => {
    const { content, showActions, classes: incomingClasses } = params;

    setTriggerElement(document.activeElement as HTMLElement);
    setContent(content);
    setIsOpen(true);
    setShowActions(showActions);
    setClasses(incomingClasses);
  };

  const closeBottomSheet = () => {
    setContent(null);
    setIsOpen(false);
    triggerElement?.focus();
  };

  const value: BottomSheetContextType = {
    isOpen,
    content,
    openBottomSheet,
    closeBottomSheet,
    showActions,
    classes
  };

  return (
    <BottomSheetContext.Provider value={value}>
      {children}
    </BottomSheetContext.Provider>
  );
}

export default BottomSheetProvider;
