import { useEffect, useState } from "react";
import debounce from "@/lib/debounce";
import { InfiniteScrollHookProps } from "@/types/hooks/useInfiniteScroll";

function useInfiniteScroll({
  callback,
  wait = 250,
  threshold = 150,
  nextPage,
  scrollContainerRef
}: InfiniteScrollHookProps) {
  const [loading, setLoading] = useState(false);

  const handleScroll = debounce(() => {
    if (!nextPage) {
      return;
    }

    const container = scrollContainerRef?.current || document.documentElement;
    const nearBottom =
      container.scrollHeight - container.scrollTop - container.clientHeight <=
      threshold;

    if (nearBottom && nextPage) {
      setLoading(true);
      try {
        callback();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    } else {
      return;
    }
  }, wait);

  const checkIfNeedsMoreContent = () => {
    if (!nextPage) return;

    const container = scrollContainerRef?.current || document.documentElement;
    const hasEnoughContent = container.scrollHeight > container.clientHeight;

    if (!hasEnoughContent) {
      setLoading(true);
      callback();
      setLoading(false);
    }
  };

  useEffect(() => {
    const scrollElement = scrollContainerRef?.current || window;

    scrollElement.addEventListener("scroll", handleScroll);
    checkIfNeedsMoreContent();

    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
    };
  }, [nextPage, callback, scrollContainerRef]);

  return { loading };
}

export default useInfiniteScroll;
