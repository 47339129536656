import React from "react";
import styles from "./ProfileStatsTemplate.module.scss";
import { useAssets } from "@/contexts/assets/assets.hook";

function ProfileStatsTemplate() {
  const { meta } = useAssets();

  return (
    <div className={styles.container}>
      <div className={styles.fileStats}>
        <span className={styles.statsTitle}>Total files</span>
        <span className={styles.statsValue}>{meta?.total}</span>
      </div>
      <div className={styles.fileStats}>
        <span className={styles.statsTitle}>Shared with me</span>
        <span className={styles.statsValue}>0</span>
      </div>
    </div>
  );
}

export default ProfileStatsTemplate;
