import Echo from "laravel-echo";
import Pusher from "pusher-js";
import {
  REVERB_APP_KEY,
  REVERB_PORT,
  REVERB_SCHEME,
  REVERB_PATH,
  API_URL,
  ENV
} from "@/config/constants";
import { getAccessToken } from "@/lib/network";

declare global {
  interface Window {
    Echo: Echo<"reverb">;
    Pusher: Pusher;
  }
}

export function createSocketConnection() {
  const token = getAccessToken() ?? null;
  const tls = REVERB_SCHEME === "https";
  //eslint-disable-next-line
  (window as any).Pusher = Pusher;
  Pusher.logToConsole = ENV !== "PROD" && ENV !== "UAT" ? true : false;
  const headers = {
    Accept: "application/json"
  };

  //Echo doesn't expose a type for the config object. This is just so eslint stops yelling.
  //eslint-disable-next-line
  const config: any = {
    broadcaster: "reverb",
    key: REVERB_APP_KEY,
    wsHost: API_URL.substring(API_URL.lastIndexOf("/") + 1),
    wsPath: REVERB_PATH,
    wsPort: REVERB_PORT,
    wssPort: REVERB_PORT,
    forceTLS: tls,
    enabledTransports: ["ws"],
    channelAuthorization: {
      endpoint: `${API_URL}/broadcasting/auth`,
      headers: headers
    }
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
    config["bearerToken"] = token;
  }
  window.Echo = new Echo({
    ...config
  });
}
