import React, { useState } from "react";
import classNames from "classnames";
import styles from "./DropZone.module.scss";
import { DropZoneProps } from "./DropZone.types";
import { useDropzone } from "react-dropzone";
import { useAssets } from "@/contexts/assets/assets.hook";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { createAssetFormData } from "@/lib/files";
import { isApiErrorResponse } from "@/lib/error";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { supportedFileTypes } from "@/utils/filetypes";
import { useAuth } from "@/contexts/auth/auth.hook";

function DropZone({
  resourcePresent,
  currentNumberOfFiles,
  onUploadComplete,
  viewingProfile
}: DropZoneProps) {
  const [isHovered, setIsHovered] = useState(false);

  const { addToast } = useSuperToast();
  const { user } = useAuth();
  const { upload, setUploadBuffer, refresh, folderState } = useAssets();

  const dropZoneHeightStyles = !resourcePresent ? "h-70" : "";

  const classes = classNames(styles.dropZone, dropZoneHeightStyles);

  const onDrop = async (acceptedFiles: Array<File>) => {
    /**
     * Build an asset object for each dropped file, up to a maximum of 15
     * All visibility changes / user invites are applied to every file being uploaded.
     * Because of this, we keep an array buffer to enable us to apply those changes without searching the listing of all assets every time.
     */
    if (!user) onUploadComplete({ files: [] });

    if (currentNumberOfFiles + acceptedFiles.length > 15) {
      addToast(
        "You cannot upload more than 15 files at a time.",
        ToastTypes.FAIL
      );
      return;
    }
    acceptedFiles.forEach(async (file) => {
      if (file) {
        const folder_id = viewingProfile
          ? null
          : (folderState?.currentFolder?.id ?? null);
        const form = createAssetFormData(file, folder_id);
        const uploadResponse = await upload(form);
        if (isApiErrorResponse(uploadResponse)) {
          addToast(uploadResponse.message, ToastTypes.FAIL);
        } else {
          setUploadBuffer((prev) => [...prev, uploadResponse]);
          if (!viewingProfile)
            refresh({ folder_id: folderState?.currentFolder?.id ?? null });
        }
      }
    });
    onUploadComplete({
      files: acceptedFiles
    });
  };

  const {
    getRootProps,
    getInputProps,
    open: openFilePicker
  } = useDropzone({
    onDrop,
    accept: supportedFileTypes
  });

  const handleClick = () => {
    if (!user) {
      onUploadComplete({
        files: []
      });
      return;
    }
    openFilePicker();
  };

  return (
    <div
      className={classes}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...getRootProps()}
      onClick={handleClick}
    >
      <input {...getInputProps()} />
      <img
        src="/images/upload.svg"
        alt="Upload Image"
        className={classNames({
          bounce: isHovered
        })}
      />
      <div className={styles.dropZoneTextGroup}>
        <span className="text-bold">
          {viewingProfile ? "Secure and send file" : "Select a file to secure"}
        </span>
        <span className="text-dimmed">or drag and drop here</span>
      </div>
    </div>
  );
}

export default DropZone;
