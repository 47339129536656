import React, { useRef, useEffect, useState } from "react";
import { Button, Switch, TextField } from "@mui/material";
import styles from "./index.module.scss";
import { useFormik } from "formik";
import { useModal } from "@/components/shared/Modal/useModal";
import { useSecureFile } from "../useSecureFile";
import { bytesToMB, removeExtensionFromName } from "@/lib/files";
import { isApiErrorResponse } from "@/lib/error";
import { sanitizeInput } from "@/lib/sanitize";
import debounce from "@/lib/debounce";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTimes } from "@fortawesome/free-solid-svg-icons";
import { UploadedFile } from "../secure-file.types";
import classNames from "classnames";
import DropZone from "@/components/DropZone/DropZone.component";
import { useBottomSheet } from "@/components/shared/BottomSheet/useBottomSheet";
import { useAssets } from "@/contexts/assets/assets.hook";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import UserShareSearch from "@/components/screens/Dashboard/partials/UserShareSearch/UserShareSearch.component";
import { shareAssetWithUser } from "@/services/users-service";
import { SharedUserDetails } from "@/types/contexts/share-context";
import SharedUser from "../../SharedUser/SharedUser.component";
import { ShareUploadsResponse, Step1Props } from "./Step1.types";
import useResponsive from "@/hooks/useResponsive";
import { components } from "@/types/beta.schema";
import { useProfile } from "@/contexts/profile/profile.hook";

function Step1({ initialSharedUsers }: Step1Props) {
  const { closeModal, isOpen: modalOpen } = useModal();
  const { closeBottomSheet, isOpen: bottomSheetOpen } = useBottomSheet();
  const {
    files,
    setIsPublic,
    removeFile,
    setFileForEdit,
    updateFileName,
    fileForEdit,
    setFiles,
    sharedUsers,
    setSharedUsers
  } = useSecureFile();
  const {
    update,
    remove,
    refresh,
    uploadBuffer,
    setUploadBuffer,
    folderState
  } = useAssets();
  const { addToast } = useSuperToast();
  const { isMobile } = useResponsive();
  const { viewingProfile } = useProfile();
  const fileText = useRef("file");
  const [removeUploads, setRemoveUploads] = useState(false);

  useEffect(() => {
    if (initialSharedUsers) {
      setSharedUsers((prevUsers) => [...prevUsers, ...initialSharedUsers]);
    }
  }, []);

  const uploadedFileNameFormik = useFormik({
    initialValues: {
      name: removeExtensionFromName(fileForEdit?.name) ?? ""
    },
    enableReinitialize: true,
    onSubmit: async ({ name }) => {
      if (!name) {
        return;
      }
      const nameWithRemovedExtension = removeExtensionFromName(name);
      const sanitizedName = sanitizeInput(nameWithRemovedExtension);
      const assetToUpdateIndex = uploadBuffer.findIndex((a) => {
        return a.name === removeExtensionFromName(fileForEdit?.name);
      });
      const assetToUpdate = uploadBuffer[assetToUpdateIndex];
      const updateNameResponse = await update(assetToUpdate.id, {
        name: sanitizedName
      });
      if (isApiErrorResponse(updateNameResponse)) {
        console.error(updateNameResponse.message);
        addToast(updateNameResponse.message, ToastTypes.FAIL);
      } else {
        setUploadBuffer((prev) =>
          prev.map((item, i) =>
            i === assetToUpdateIndex ? updateNameResponse : item
          )
        );
        addToast("File renamed successfully!", ToastTypes.SUCCESS);
      }
      updateFileName(sanitizedName);
    }
  });

  const publicToggleSwitchLabel = {
    inputProps: { "aria-label": "Make file public switch" }
  };

  const handleSwitchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const isPublic = e.target.checked;
    setIsPublic(isPublic);
    for (let i = 0; i < uploadBuffer.length; i++) {
      const asset = uploadBuffer[i];
      const updateAssetResponse = await update(asset.id, {
        visibility: isPublic ? "public" : "private"
      });
      if (isApiErrorResponse(updateAssetResponse)) {
        console.error(
          `There was an error for ${asset.name}: ${updateAssetResponse.message}`
        );
        addToast(updateAssetResponse.message, ToastTypes.FAIL);
      } else {
        setUploadBuffer((prev) =>
          prev.map((item, j) => (i === j ? updateAssetResponse : item))
        );
      }
    }
  };

  const handleShareUploads = (): ShareUploadsResponse => {
    let error = false;

    uploadBuffer.forEach((upload) => {
      sharedUsers.forEach(async (user) => {
        const payload: components["schemas"]["CreateAssetShareData"] = {
          email: user.email,
          access_type: user.access.access_type
        };

        const { error: responseError } = await shareAssetWithUser(
          upload.id,
          payload
        );

        if (!error && responseError) {
          error = true;
        }
      });
    });

    return { error };
  };

  const handleClose = () => {
    if (isMobile) {
      closeBottomSheet();
    } else {
      closeModal();
    }
  };

  const handleSubmit = () => {
    const { error } = handleShareUploads();

    if (error) {
      return addToast(
        "There was an error while sharing the files.",
        ToastTypes.FAIL
      );
    }
  };

  const handleCancel = async () => {
    let error = false;
    for (const asset of uploadBuffer) {
      const { message } = await remove(asset.id);
      if (message !== "Asset deleted") {
        console.error(message);
        error = true;
      } else {
        setUploadBuffer((prev) => prev.filter((item) => item.id !== asset.id));
        if (!viewingProfile)
          refresh(
            {
              folder_id: folderState?.currentFolder?.id
            },
            true
          );
      }
    }
    if (!error) {
      addToast("File uploads cancelled successfully.", ToastTypes.SUCCESS);
    } else {
      addToast("Not all files were deleted successfully.", ToastTypes.FAIL);
    }
  };

  useEffect(() => {
    if (!bottomSheetOpen && !modalOpen) {
      if (removeUploads) {
        setRemoveUploads(false);
        handleCancel();
      } else {
        handleSubmit();
      }
      setUploadBuffer([]);
    }
  }, [bottomSheetOpen, modalOpen, removeUploads]);

  const renderFileNameElement = (file: UploadedFile) => {
    if (file !== fileForEdit) {
      return (
        <>
          <span className={styles.fileName}>
            {removeExtensionFromName(file.name)}
          </span>
          <span className={styles.fileSize}>
            {file.type} | {bytesToMB(file.size)} MB
          </span>
        </>
      );
    }

    return (
      <form onSubmit={uploadedFileNameFormik.handleSubmit}>
        <TextField
          fullWidth
          id="name"
          name="name"
          value={uploadedFileNameFormik.values.name}
          onChange={uploadedFileNameFormik.handleChange}
          onBlur={uploadedFileNameFormik.handleBlur}
          error={
            uploadedFileNameFormik.touched.name &&
            Boolean(uploadedFileNameFormik.errors.name)
          }
          helperText={
            uploadedFileNameFormik.touched.name &&
            uploadedFileNameFormik.errors.name
          }
          className="text-input w-100"
          placeholder=""
        />
      </form>
    );
  };

  const renderFileActions = (file: UploadedFile) => {
    return (
      <div
        className={`${styles.fileActions} ${file === fileForEdit && styles.cancelButtonSpacing}`}
      >
        {file === fileForEdit ? (
          <Button
            size="small"
            color="primary"
            variant="text"
            type="button"
            className={`clear-cancel`}
            onClick={() => setFileForEdit(null)}
          >
            Cancel
          </Button>
        ) : (
          <>
            <motion.button
              className={styles.fileActionWrapperEdit}
              whileHover={{
                scale: 1.2,
                cursor: "pointer"
              }}
              onClick={() => setFileForEdit(file)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </motion.button>
            <motion.button
              className={styles.fileActionWrapperDelete}
              whileHover={{
                scale: 1.2,
                backgroundColor: "#EE434D",
                cursor: "pointer"
              }}
              onClick={async () => {
                const index = uploadBuffer.findIndex(
                  (asset) => asset.name === removeExtensionFromName(file.name)
                );
                const removeResponse = await remove(uploadBuffer[index]?.id);
                if (isApiErrorResponse(removeResponse)) {
                  console.error(removeResponse.message);
                } else {
                  setUploadBuffer((prev) =>
                    prev.filter((_, idx) => index !== idx)
                  );
                }
                removeFile(file);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </motion.button>
          </>
        )}
      </div>
    );
  };

  const renderFiles = () => {
    if (files.length > 1) {
      fileText.current = "files";
    } else {
      fileText.current = "file";
    }

    return files.map((file, index) => {
      const fileMetaClasses = classNames(styles.fileMeta, {
        "w-100": file === fileForEdit
      });

      return (
        <div key={`${file.name}-${index}`} className={styles.fileItem}>
          <div className={styles.fileContent}>
            <div className={styles.fileIcon}>
              <img src="/images/mov_icon.png" />
            </div>
            <div className={fileMetaClasses}>{renderFileNameElement(file)}</div>
          </div>

          {renderFileActions(file)}
        </div>
      );
    });
  };

  const handleUploadComplete = async ({ files }) => {
    setFiles((prev) => [...prev, ...files]);
  };

  const handleAccessTypeChange = ({ email, accessType }) => {
    setSharedUsers((prevSharedUsers) =>
      prevSharedUsers.map((user) =>
        user.email === email
          ? { ...user, access: { access_type: accessType } }
          : user
      )
    );
  };

  const handleRemoveAccess = ({ email }) => {
    setSharedUsers((prevSharedUsers) =>
      prevSharedUsers.filter((user) => user.email !== email)
    );
  };

  const renderSharedUsers = () => {
    if (sharedUsers.length < 1) {
      return null;
    }

    return (
      <ul className={styles.sharedUsersList}>
        {sharedUsers.map((user, index) => (
          <SharedUser
            key={user.email + index}
            avatar={user.profile?.avatar}
            id={user.id}
            email={user.email}
            accessType={user.access.access_type}
            handleAccessTypeChange={handleAccessTypeChange}
            handleRemoveAccess={handleRemoveAccess}
          />
        ))}
      </ul>
    );
  };

  return (
    <div className={styles.step1}>
      <div className={styles.header}>
        <div>
          <h2>Secure a file</h2>
          <div className={styles.subtextContainer}>
            <span className={styles.subtext}>
              The maximum supported file size is 50 MB.
            </span>
          </div>
        </div>
        <button
          onClick={() => {
            setRemoveUploads(true);
            handleClose();
          }}
          className={styles.mobileCancelButton}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>

      <DropZone
        resourcePresent={true}
        currentNumberOfFiles={files.length}
        onUploadComplete={handleUploadComplete}
      />

      <div className={styles.filesList}>{renderFiles()}</div>

      <div className={styles.publicToggle}>
        <span className={styles.toggleText}>
          Make {fileText.current} public
        </span>
        <Switch
          {...publicToggleSwitchLabel}
          onChange={debounce(handleSwitchChange, 250)}
        />
      </div>

      <div className={styles.sharedWrapper}>
        <div className={styles.shareWithText}>
          <span>Share with</span>
        </div>

        <UserShareSearch
          onUserSelect={(user: SharedUserDetails) => {
            setSharedUsers((prevSharedUsers) => [...prevSharedUsers, user]);
          }}
          sharedUsers={sharedUsers}
        />

        <div className={styles.sharedUsersWrapper}>{renderSharedUsers()}</div>
      </div>

      <div className={styles.footerActions}>
        <Button
          size="large"
          color="primary"
          variant="text"
          type="button"
          className={classNames("clear-cancel", styles.desktopCancelButton)}
          onClick={() => {
            setRemoveUploads(true);
            handleClose();
          }}
        >
          Cancel
        </Button>
        <motion.div
          className={styles.superButtonWrapper}
          whileHover={{ scale: 1.1 }}
        >
          <Button
            size="large"
            color="primary"
            variant="contained"
            type="submit"
            className="super-button brand-secondary-bg text-dark"
            onClick={handleClose}
          >
            Done
          </Button>
        </motion.div>
      </div>
    </div>
  );
}

export default Step1;
