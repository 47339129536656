import React, { useEffect, ComponentType } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/auth/auth.hook";

const withAuth = <P extends object>(
  Component: ComponentType<P>
): React.FC<P> => {
  const WrappedComponent: React.FC<P> = (props: P) => {
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (!user) {
        navigate("/");
      }
    }, [user, navigate]);

    return user !== null ? <Component {...props} /> : null;
  };

  // Set the display name for better debugging
  WrappedComponent.displayName = `WithAuth(${Component.displayName || Component.name || "Component"})`;

  return WrappedComponent;
};

export default withAuth;
