import Button from "@mui/material/Button";
import styles from "./SuperPayPrompt.module.scss";
import classNames from "classnames";
import { SuperPayPromptProps } from "./SuperPayPrompt.types";
import { getUserProfileImage } from "@/lib/user";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";

const SuperPayPrompt = ({
  assetPrice,
  ownerInfo,
  deviceSupported,
  setHasPaid
}: SuperPayPromptProps) => {
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const { addToast } = useSuperToast();

  const submitPayment = () => {
    addToast("Purchase successful", ToastTypes.SUCCESS);
    setHasPaid(true);
  };
  return (
    <div className={styles.superPayContainer}>
      <div className={styles.promptContainer}>
        <img
          src={getUserProfileImage(ownerInfo?.profile?.avatar)}
          alt="User Profile"
          className={styles.profilePicture}
        />
        <div className={styles.payToUnlockText}>Pay To Unlock</div>
        <div className={styles.priceContainer}>
          <div className={styles.priceText}>${assetPrice}</div>
          <div className={styles.currencyText}>USD</div>
        </div>
        {!showPaymentDetails && (
          <Button
            className={classNames(
              "super-button",
              "brand-secondary",
              styles.unlockButton
            )}
            onClick={() => setShowPaymentDetails(true)}
          >
            Unlock
          </Button>
        )}
        {!deviceSupported && (
          <div className={styles.unsupportedDeviceWarning}>
            Viewing SuperFiles in this browser is not currently supported
          </div>
        )}
      </div>
      {showPaymentDetails && (
        <div className={styles.paymentInfoContainer}>
          <Card elevation={0} className={styles.paymentCard}>
            <CardContent>
              <TextField
                label="Card Number"
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <div className={styles.rowContainer}>
                <TextField
                  label="Expiration Date"
                  variant="outlined"
                  margin="normal"
                  placeholder="MM/YY"
                  className={styles.expiryField}
                />
                <TextField
                  label="Security Code"
                  variant="outlined"
                  margin="normal"
                  placeholder="CVC"
                  className={styles.cvcField}
                />
              </div>
            </CardContent>
          </Card>
          <Button
            className={classNames(
              "super-button",
              "brand-secondary",
              styles.unlockButton
            )}
            onClick={submitPayment}
          >
            Pay now
          </Button>
          <div className={styles.termsText}>
            By confirming your payment you allow Supers.com to charge you for
            this payment and save your payment information in accordance with
            their terms.
          </div>
        </div>
      )}
    </div>
  );
};
export default SuperPayPrompt;
