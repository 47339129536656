import React, { useEffect, useState, useMemo } from "react";
import { useAuth } from "@/contexts/auth/auth.hook";
import {
  getNotifications,
  markAllAsRead
} from "@/services/notifications-service";
import NotificationBellActive from "@/components/icons/NotificationBellActive";
import NotificationBellInactive from "@/components/icons/NotificationBellInactive";
import Menu from "@mui/material/Menu";
import styles from "./NotificationsMenu.module.scss";
import NotificationItemTemplate from "../notification-item-template/NotificationItemTemplate";
import { paginatedQuery } from "@/components/screens/Dashboard/partials/SuperTables/FilesSuperTable/data/helpers";
import { isApiErrorResponse } from "@/lib/error";
import { useAssets } from "@/contexts/assets/assets.hook";
import { NotificationItem } from "../notification-item-template/NotificationItemTemplate.types";
import { NotificationsData } from "@/types/services/notifications-service";
import { LinkDto, MetaDto } from "@/types/network";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";

const NotificationsMenu: React.FC = () => {
  const [notificationItems, setNotificationItems] = useState<
    Array<NotificationItem>
  >([]);

  const notifications = notificationItems.map((item) => item.notification);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [links, setLinks] = useState<Array<LinkDto>>(null);
  const [meta, setMeta] = useState<MetaDto>(null);
  const [refreshAfterClose, setRefreshAfterClose] = useState<boolean>(false);
  const { addToast } = useSuperToast();

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const hasUnread = useMemo(
    () => notifications.some((notif) => !notif.read_at),
    [notifications]
  );

  const { user } = useAuth();

  const { getAssetDetails } = useAssets();

  const getAssetInfo = async (
    asset_id: string
  ): Promise<NotificationItem["sharedAsset"]> => {
    const asset = await getAssetDetails(asset_id);
    if (!isApiErrorResponse(asset)) {
      return asset;
    }
  };

  const constructNotificationItems = async (
    notifications: NotificationsData
  ): Promise<Array<NotificationItem>> => {
    const notificationPromises = notifications.data
      .filter((notification) => notification !== undefined)
      .map(async (notification) => {
        const sharedAsset = await getAssetInfo(notification.data.asset_id);
        return {
          notification,
          sharedAsset
        };
      });

    const resolvedNotificationItems = await Promise.all(notificationPromises);

    // For now, if the asset does not exist anymore for a notification, we will not display the notification.
    resolvedNotificationItems.filter(
      (notificationItem) => notificationItem.sharedAsset !== undefined
    );
    return resolvedNotificationItems;
  };

  const fetchNotifications = async () => {
    const { statusCode, data, error } = await getNotifications();
    if (statusCode === 200) {
      setNotificationItems(await constructNotificationItems(data));
      setLinks(data.links);
      setMeta(data.meta);
    }
    if (error) {
      console.error("Error fetching notifications", error.message);
      addToast(error.message, ToastTypes.FAIL);
    }
  };

  useEffect(() => {
    if (user) {
      fetchNotifications();
    }
    return () => {
      setNotificationItems([]);
      setLinks(null);
    };
  }, [JSON.stringify(user)]);

  const updateNotifications = (refreshAfterClose = false) => {
    if (refreshAfterClose) setRefreshAfterClose(refreshAfterClose);
    fetchNotifications();
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    if (refreshAfterClose) {
      // Wait for menu animation to fully close before refreshing
      setTimeout(() => {
        setRefreshAfterClose(false);
        fetchNotifications();
      }, 300);
    }
  };
  const handleIconButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleViewMoreClick = async () => {
    if (meta?.next_page_url) {
      const { data, error, statusCode } = await getNotifications(
        null,
        paginatedQuery(meta?.next_page_url)
      );
      if (statusCode === 200) {
        // Append the nextPage notification results to the end of the current state of notificationItems
        setNotificationItems([
          ...notificationItems,
          ...(await constructNotificationItems(data))
        ]);
        setLinks(data.links);
      }
      if (error) {
        console.error("Error fetching notifications", error.message);
        addToast(error.message, ToastTypes.FAIL);
      }
    }
  };

  return (
    <div>
      <button onClick={handleIconButtonClick} className={styles.iconButton}>
        {notifications.length && hasUnread ? (
          <NotificationBellActive />
        ) : (
          <NotificationBellInactive />
        )}
      </button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        className={styles.notificationMenu}
        sx={{
          "& .MuiMenu-paper": {
            backgroundColor: "#282828",
            minWidth: "300px",
            maxWidth: "400px",
            padding: "16px",
            borderRadius: "8px"
          }
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div className={styles.notifMenuHeader}>
          <div className={styles.left}>
            {notifications.length && hasUnread ? (
              <NotificationBellActive />
            ) : (
              <NotificationBellInactive />
            )}{" "}
            <span className={styles.headerText}>{"Notifications"}</span>
          </div>
          <button
            className={styles.markAllButton}
            onClick={async () => {
              if (hasUnread) {
                await markAllAsRead();
                await fetchNotifications();
              }
            }}
            disabled={!hasUnread}
          >
            {"Mark all as read"}
          </button>
        </div>
        {!notifications.length ? (
          <div className={styles.emptyMessage}>
            {"You have no notifications."}
          </div>
        ) : (
          <ul className={styles.notifItems}>
            {notificationItems.map((notifItem) => (
              <NotificationItemTemplate
                key={notifItem.notification.id}
                notificationItem={notifItem}
                updateNotifications={updateNotifications}
              />
            ))}
            <button
              className={styles.viewMoreButton}
              onClick={handleViewMoreClick}
              disabled={!meta?.next_page_url}
            >
              {"View more"}
            </button>
          </ul>
        )}
      </Menu>
    </div>
  );
};

export default NotificationsMenu;
