import React, { useState } from "react";
import classNames from "classnames";
import styles from "./BackgroundDropZone.module.scss";
import { useDropzone } from "react-dropzone";
import { useProfile } from "@/contexts/profile/profile.hook";
import { bytesToMB, createBackgroundUpdateFormData } from "@/lib/files";
import { isApiErrorResponse } from "@/lib/error";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";

function BackgroundDropZone() {
  const [isHovered, setIsHovered] = useState(false);
  const { addToast } = useSuperToast();
  const { updateBackground } = useProfile();

  const onDrop = async (acceptedFiles: Array<File>) => {
    const acceptedFile = acceptedFiles.pop();
    if (!acceptedFile) {
      return;
    }
    if (+bytesToMB(acceptedFile.size) > 10) {
      addToast("Please select a file under 10MB.", ToastTypes.FAIL);
      return;
    }

    const data = createBackgroundUpdateFormData(acceptedFile);
    const backgroundUpdateResponse = await updateBackground(data);

    if (isApiErrorResponse(backgroundUpdateResponse)) {
      console.error(backgroundUpdateResponse.message);
      addToast(
        "An error occurred while attempting to change your background.",
        ToastTypes.FAIL
      );
    } else {
      addToast("Your background was successfully updated.", ToastTypes.SUCCESS);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/apng": [".apng"],
      "image/avif": [".avif"],
      "image/bmp": [".bmp"],
      "image/gif": [".gif"],
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/svg+xml": [".svg"],
      "image/webp": [".webp"],
      "video/mp4": [".mp4", ".m4a", ".m4p", ".m4b", ".m4r", ".m4v"],
      "video/ogg": [".ogv"],
      "video/webm": [".webm"]
    },
    maxFiles: 1,
    multiple: false
  });

  return (
    <div
      className={styles.backgroundDropZone}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <img
        src="/images/upload.svg"
        alt="Upload Image"
        className={classNames({
          bounce: isHovered
        })}
      />
      <div className={styles.backgroundDropZoneTextGroup}>
        <span className="text-bold">Select a file to upload</span>
        <span className="text-dimmed">or drag and drop here</span>
      </div>
    </div>
  );
}

export default BackgroundDropZone;
