import React, { Dispatch, SetStateAction } from "react";
import styles from "./FileListFilter.module.scss";
import { DASHBOARD_FILTERS } from "@/types/pages/dashboard";

const FileListFilter: React.FC<{
  activeFilter: DASHBOARD_FILTERS;
  setActiveFilter: Dispatch<SetStateAction<DASHBOARD_FILTERS>>;
}> = ({ activeFilter, setActiveFilter }) => {
  return (
    <div className={styles.fileListFilter}>
      <div className={styles.quickActions}>
        <button
          className={`${styles.fileAction} ${activeFilter === DASHBOARD_FILTERS.FILES ? styles.active : ""}`}
          id="files"
          onClick={() => setActiveFilter(DASHBOARD_FILTERS.FILES)}
        >
          Files
        </button>
        <button
          className={`${styles.fileAction} ${activeFilter === DASHBOARD_FILTERS.GROUPS ? styles.active : ""}`}
          id="groups"
          onClick={() => setActiveFilter(DASHBOARD_FILTERS.GROUPS)}
        >
          Groups
        </button>
        <button
          className={`${styles.fileAction} ${activeFilter === DASHBOARD_FILTERS.USERS ? styles.active : ""}`}
          id="users"
          onClick={() => setActiveFilter(DASHBOARD_FILTERS.USERS)}
        >
          Users
        </button>
      </div>
    </div>
  );
};

export default FileListFilter;
