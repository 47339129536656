import { useMediaQuery } from "react-responsive";

function useResponsive() {
  const breakpoints = { mobile: 767, desktop: 768 };

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.mobile}px)`
  });

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.desktop}px)`
  });

  return {
    isMobile,
    isDesktop
  };
}

export default useResponsive;
