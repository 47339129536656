import { useNavigate, useLocation } from "react-router-dom";
import { useProfile } from "@/contexts/profile/profile.hook";
import { getAssets } from "@/services/asset-service";
import { useAuth } from "@/contexts/auth/auth.hook";
import { BreadcrumbType } from "@/contexts/assets/assets.types";
import { getPathForNavigation } from "@/lib/files";
import { useCurrentScreen } from "@/contexts/screen/screen.hook";
import { SCREEN_TYPES } from "@/types/contexts/screen-context";
import { components } from "@/types/beta.schema";
//Handles all logic when routing based on assets (Files, shortcuts, folders, breadcrumbs)
function useAssetRouting() {
  const navigate = useNavigate();
  const location = useLocation();
  const { viewingProfile } = useProfile();
  const { user } = useAuth();
  const { setCurrentScreen, setCurrentScreenProfile } = useCurrentScreen();

  const handleFolderNav = async (name: string, id: string): Promise<void> => {
    const encodedFolderName = encodeURIComponent(name);

    if (!viewingProfile) {
      const {
        data: { breadcrumbs }
      } = await getAssets({ folder_id: id });

      const crumbsForPath = new Array(...breadcrumbs);
      crumbsForPath.shift();

      const folderPath = crumbsForPath
        .map((crumb) => encodeURIComponent(crumb.name))
        .join("/");

      const path =
        crumbsForPath.length > 0
          ? `/${user.username}/${folderPath}/${encodedFolderName}`
          : `/${user.username}/${encodedFolderName}`;

      setCurrentScreenProfile({
        path,
        owner_id: user.id,
        username: user.username,
        folder_id: id
      });
      setCurrentScreen(SCREEN_TYPES.DASHBOARD_PROFILE);
    } else {
      const path = `${location.pathname}/${encodedFolderName}`;

      setCurrentScreenProfile({
        path,
        owner_id: viewingProfile.id,
        username: viewingProfile.username,
        folder_id: id
      });
      setCurrentScreen(SCREEN_TYPES.PROFILE);
    }
  };

  const handleShortcutNav = (shortcutAsset) => {
    const assetFields = shortcutAsset as components["schemas"]["AssetData"]; // TODO: Temp. casting until schema is updated
    const shortcutPath = `/${assetFields?.path}`;

    navigate(shortcutPath);
    return;
  };

  const handleFileNav = (name: string) => {
    const encodedFileName = encodeURIComponent(name);
    const path = location.pathname;
    if (path === "/") {
      const _path = `/${user.username}/${encodedFileName}`;
      navigate(_path, { state: viewingProfile });
      return;
    }

    if (path.startsWith(`/${user?.username}`)) {
      const _path = `${path}/${encodedFileName}`;
      navigate(_path, { state: viewingProfile });
      return;
    }

    const _path = `${path}/${encodedFileName}`;
    navigate(_path, { state: viewingProfile });
  };

  const handleBreadcrumbNav = async (
    breadcrumb: BreadcrumbType
  ): Promise<void> => {
    if (!breadcrumb.id) {
      let pathForOrigin = "/";

      if (viewingProfile) {
        pathForOrigin = `/${viewingProfile.username}`;

        setCurrentScreenProfile({
          path: pathForOrigin,
          owner_id: viewingProfile.id,
          username: viewingProfile.username
        });
        return setCurrentScreen(SCREEN_TYPES.PROFILE);
      } else {
        return setCurrentScreen(SCREEN_TYPES.DASHBOARD_ROOT);
      }
    }

    const encodedBreadcrumbName = encodeURIComponent(breadcrumb.name);
    const pathFromUrl = location.pathname;
    const pathForNavigation = getPathForNavigation(
      encodedBreadcrumbName,
      pathFromUrl
    );

    setCurrentScreenProfile({
      path: pathForNavigation,
      owner_id: viewingProfile ? viewingProfile.id : user.id,
      username: viewingProfile ? viewingProfile.username : user.username,
      folder_id: breadcrumb.id
    });
    setCurrentScreen(
      viewingProfile ? SCREEN_TYPES.PROFILE : SCREEN_TYPES.DASHBOARD_PROFILE
    );
  };
  return {
    handleFileNav,
    handleFolderNav,
    handleShortcutNav,
    handleBreadcrumbNav
  };
}

export default useAssetRouting;
