import React from "react";
import FileListFilter from "@/components/FileListFilter/FileListFilter.component";
import superTableStyles from "../SuperTables.module.scss";
import { DataTable } from "primereact/datatable";
import { GroupsSuperTableProps } from "./GroupsSuperTable.types";

function GroupsSuperTable({
  activeFilter,
  setActiveFilter
}: GroupsSuperTableProps) {
  const tableHeader = (
    <div className={superTableStyles.tableHeader}>
      <FileListFilter
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
    </div>
  );

  const emptyMessageTemplate = (
    <div className={superTableStyles.emptyMessage}>
      <strong>You have not created any groups</strong>
    </div>
  );

  return (
    <div className={superTableStyles.glassPane}>
      <DataTable
        value={[]}
        header={tableHeader}
        emptyMessage={emptyMessageTemplate}
        className={superTableStyles.datatable}
      />
    </div>
  );
}

export default GroupsSuperTable;
