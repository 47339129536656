import styles from "../information-tab.module.scss";
import SharedUser from "@/components/screens/Dashboard/partials/SharedUser/SharedUser.component";
import UserShareSearch from "@/components/screens/Dashboard/partials/UserShareSearch/UserShareSearch.component";
import { useAssets } from "@/contexts/assets/assets.hook";
import { isApiErrorResponse } from "@/lib/error";
import { shareAssetWithUser } from "@/services/users-service";
import { SHARED_ACCESS_TYPE } from "@/types/contexts/share-context";
import { components } from "@/types/beta.schema";
import React, { useCallback, useEffect, useState } from "react";
import { SharedWithListProps } from "../information-tab.types";
import { useAuth } from "@/contexts/auth/auth.hook";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";

function SharedWithList({ assetResource, isShortcut }: SharedWithListProps) {
  const [assetSharesList, setAssetSharesList] = useState<
    Array<components["schemas"]["UserData"]>
  >([]);
  const { user: authUser } = useAuth();
  const { addToast } = useSuperToast();

  const assetId = isShortcut ? assetResource.asset_id : assetResource.id;

  const { getAssetSharedUsers, updateAccessType, deleteUserAccess } =
    useAssets();

  const fetchOnLoad = useCallback(async () => {
    const sharesResponse = await getAssetSharedUsers(assetId);
    if (isApiErrorResponse(sharesResponse)) {
      console.error(sharesResponse.message);
    } else {
      setAssetSharesList(sharesResponse);
    }
  }, [getAssetSharedUsers, assetId]);

  useEffect(() => {
    fetchOnLoad();
  }, [assetId, fetchOnLoad]);

  const onUserSelect = async (user: components["schemas"]["UserData"]) => {
    const payload: components["schemas"]["CreateAssetShareData"] = {
      email: user.email,
      access_type: SHARED_ACCESS_TYPE.ViewOnly
    };

    const { data, error } = await shareAssetWithUser(assetId, payload);

    if (isApiErrorResponse(error)) {
      addToast(error.message, ToastTypes.FAIL);
      return;
    } else {
      setAssetSharesList((prevAssetSharesList) => [
        ...prevAssetSharesList,
        data.data
      ]);
    }
  };

  const handleRemoveAccess = async ({ id }) => {
    const { statusCode, error } = await deleteUserAccess(assetId, id);

    if (statusCode !== 200) {
      console.error(error.message);
    } else {
      setAssetSharesList((prevAssetSharesList) =>
        prevAssetSharesList.filter((user) => user.id !== id)
      );
    }
  };

  const handleAccessTypeChange = async ({ id, accessType }) => {
    const accessTypeChangeResponse = await updateAccessType(assetId, {
      user_id: id,
      access_type: accessType
    });

    if (isApiErrorResponse(accessTypeChangeResponse)) {
      console.error(accessTypeChangeResponse.message);
    } else {
      setAssetSharesList((prevAssetSharesList) =>
        prevAssetSharesList.map((user) => {
          return user.id === id
            ? { ...user, access: { ...user.access, access_type: accessType } }
            : user;
        })
      );
    }
  };

  const renderAssetShares = () => {
    if (assetSharesList.length < 1) {
      return null;
    }

    return (
      <ul className={styles.sharedUsersList}>
        {assetSharesList.map((user, index) => {
          if (authUser.id === user.id) {
            return null;
          }

          return (
            <SharedUser
              key={user.email + index}
              avatar={user.profile?.avatar}
              id={user.id}
              email={user.email}
              accessType={user.access.access_type}
              handleAccessTypeChange={handleAccessTypeChange}
              handleRemoveAccess={handleRemoveAccess}
            />
          );
        })}
      </ul>
    );
  };

  return (
    <div className={styles.sharedWithListWrapper}>
      <UserShareSearch
        onUserSelect={onUserSelect}
        sharedUsers={assetSharesList}
        shortcutAssetOwner={isShortcut ? assetResource.asset.owner : null}
      />
      <div className={styles.sharedUsersGrid}>
        <div>Users</div>
        <div>Access</div>
        {renderAssetShares()}
      </div>
      <div
        tabIndex={0}
        style={{ width: "1px", height: "1px", opacity: 0, overflow: "hidden" }}
      />
    </div>
  );
}

export default SharedWithList;
