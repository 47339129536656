import React from "react";
import { BaseIconProps } from "./icon.types";

function DeleteLeftIcon({ className }: BaseIconProps) {
  const classes = className ? `${className}` : "";

  return (
    <svg
      className={classes}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00264 4.75L8.50264 7.25M8.50264 4.75L6.00264 7.25M2.25656 6.9773L3.72227 8.8773C3.89853 9.10575 3.98666 9.22 4.09732 9.30235C4.19534 9.37525 4.30589 9.4296 4.4235 9.46265C4.55627 9.5 4.70056 9.5 4.98912 9.5H8.90264C9.46269 9.5 9.74274 9.5 9.95664 9.391C10.1448 9.29515 10.2978 9.14215 10.3936 8.954C10.5026 8.7401 10.5026 8.46005 10.5026 7.9V4.1C10.5026 3.53995 10.5026 3.25992 10.3936 3.04601C10.2978 2.85785 10.1448 2.70486 9.95664 2.60899C9.74274 2.5 9.46269 2.5 8.90264 2.5H4.98912C4.70056 2.5 4.55627 2.5 4.4235 2.53733C4.30589 2.5704 4.19534 2.62475 4.09732 2.69767C3.98666 2.77999 3.89853 2.89423 3.72227 3.12271L2.25656 5.0227C1.98676 5.37245 1.85186 5.54735 1.79997 5.7402C1.75418 5.91035 1.75418 6.08965 1.79997 6.2598C1.85186 6.45265 1.98676 6.62755 2.25656 6.9773Z"
        stroke="red"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeleteLeftIcon;
