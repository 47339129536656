import * as yup from "yup";
import { removeExtensionFromName } from "@/lib/files";

export const validationSchema = yup
  .object({
    name: yup.string().required().max(30)
  })
  .transform((object) => {
    object.name = removeExtensionFromName(object.name);
    return object;
  });
