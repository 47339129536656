import { API_URL } from "@/config/constants";
import { getAccessToken } from "@/lib/network";
import createClient, { Middleware } from "openapi-fetch";
import type { paths, components, operations } from "@/types/beta.schema";

const client = createClient<paths>({ baseUrl: `${API_URL}/api` });

//TO-DO: Add in any unprotected user-related routes here to use in middleware onRequest.
//const UNPROTECTED_ROUTES = [];

const myMiddleware: Middleware = {
  async onRequest({ request }) {
    const accessToken = getAccessToken();

    request.headers.set("Content-Type", "application/json");

    if (accessToken) {
      request.headers.set("Authorization", `Bearer ${accessToken}`);
    }
    request.headers.set("Accept", "application/json");
    return request;
  }
};

client.use(myMiddleware);

export const getUserInfo = async (username?: string) => {
  const userPath = username ?? "me";

  const { data, error, response } = await client.GET("/v1/users/{user}", {
    params: {
      path: {
        user: userPath
      }
    }
  });

  return { data, error, statusCode: response.status };
};

export const getUsers = async (
  payload?: operations["users.index"]["parameters"]["query"]
) => {
  const { data, error, response } = await client.GET("/v1/users", {
    params: {
      query: payload
    }
  });

  return { data, error, statusCode: response.status };
};

export const shareAssetWithUser = async (
  assetId: string,
  payload: components["schemas"]["CreateAssetShareData"]
) => {
  const { data, error, response } = await client.POST(
    "/v1/assets/{asset}/share",
    {
      params: {
        path: {
          asset: assetId
        }
      },
      body: payload
    }
  );

  return { data, error, statusCode: response.status };
};

export const updateUser = async (
  userId: string,
  payload: components["schemas"]["UpdateUserData"]
) => {
  const { data, error, response } = await client.PUT("/v1/users/{user}", {
    params: {
      path: {
        user: userId
      }
    },
    body: payload
  });

  return { data, error, statusCode: response.status };
};

export const getSharedUsersOnAssetsList = async (
  payload?: operations["shares.index"]["parameters"]["query"]
) => {
  const { data, error, response } = await client.GET("/v1/shares", {
    params: {
      query: payload
    }
  });

  return { data, error, statusCode: response.status };
};

export const unshareAllFilesWithUser = async (userId: string) => {
  const { data, error, response } = await client.DELETE("/v1/shares/{user}", {
    params: {
      path: {
        user: userId
      }
    }
  });

  return { data, error, statusCode: response.status };
};

export const displayUser = async (userId: string) => {
  const { data, error, response } = await client.GET("/v1/users/{user}", {
    params: {
      path: {
        user: userId
      }
    }
  });

  return { data, error, statusCode: response.status };
};

export const declineAccessRequest = async (assetId: string, userId: string) => {
  const { data, error, response } = await client.DELETE(
    "/v1/assets/{asset}/request",
    {
      params: {
        path: {
          asset: assetId
        },
        query: {
          user_id: userId
        }
      }
    }
  );

  return { data, error, statusCode: response.status };
};
