export function generateAssetLink(
  username: string,
  assetName: string,
  pathName: string,
  protocol: string,
  host: string,
  isFullscreen?: boolean
): string {
  const loggedInUserInPath = pathName.includes(username);

  const basePath = loggedInUserInPath ? pathName : `${username}/${pathName}`;
  const decodedBasePath = decodeURIComponent(basePath);
  const assetLink =
    `${protocol}//${host}/${decodedBasePath}/${isFullscreen ? "" : assetName}`.replace(
      /([^:]\/)\/+/g,
      "$1"
    );

  const encodedLink = encodeURI(assetLink);

  return encodedLink;
}
