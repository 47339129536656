const toRemove = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#39;",
  "/": "&#x2F;",
  "`": "&#x60;",
  "=": "&#x3D;"
};

export const sanitizeInput = (input: string) => {
  return String(input).replace(/[&<>"'`=/]/g, function (s) {
    return toRemove[s];
  });
};
