import { API_URL } from "@/config/constants";
import { getAccessToken } from "@/lib/network";
import createClient, { Middleware } from "openapi-fetch";
import type { paths, components } from "@/types/beta.schema";

// TODO: This entire file can be deleted once we are able to update the profile through the updateUser route

//TODO: abstract out to separate files
const client = createClient<paths>({ baseUrl: `${API_URL}/api` });

const myMiddleware: Middleware = {
  async onRequest({ request }) {
    const accessToken = getAccessToken();
    let newRequest = request;
    let hasFile = false;
    let formData = null;
    if (
      request.method !== "GET" &&
      request.headers.get("Content-Type") !== "application/json"
    ) {
      try {
        formData = await request.formData();
        for (const value of formData.values()) {
          if (value instanceof File) {
            hasFile = true;
            break;
          }
        }
      } catch (e) {
        if (e instanceof TypeError) {
          // No form data
        } else {
          // Another error occurred
          console.error(e);
        }
      }
    }
    if (hasFile && (request.method === "PUT" || request.method === "PATCH")) {
      formData.set("_method", request.method);
      newRequest = new Request(request.url, {
        method: "POST",
        body: formData
      });
    } else {
      newRequest.headers.set("Content-Type", "application/json");
    }
    if (accessToken) {
      newRequest.headers.set("Authorization", `Bearer ${accessToken}`);
    }
    newRequest.headers.set("Accept", "application/json");
    return newRequest;
  }
};

client.use(myMiddleware);

export const updateProfile = async (
  payload: components["schemas"]["UpdateProfileData"]
) => {
  const { data, error } = await client.PATCH("/v1/profile", {
    body: payload
  });

  return { data, error };
};
