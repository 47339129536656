import React, { useState, useMemo } from "react";
import styles from "./index.module.scss";
import { bytesToMB } from "@/lib/files";
import { Button, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import MyDetailsTab from "./MyDetailsTab/MyDetailsTab";
import PasswordTab from "./PasswordTab/PasswordTab";
import withAuth from "@/hoc/withAuth";
import BackButton from "@/components/BackButton/BackButton";
import { useProfile } from "@/contexts/profile/profile.hook";
import { createAvatarUpdateFormData } from "@/lib/files";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { isApiErrorResponse } from "@/lib/error";
import BackgroundChange from "@/components/BackgroundChange/BackgroundChange.component";
import { useAuth } from "@/contexts/auth/auth.hook";
import { useCurrentScreen } from "@/contexts/screen/screen.hook";
import { SCREEN_TYPES } from "@/types/contexts/screen-context";

const AccountBody = () => {
  const { setCurrentScreen } = useCurrentScreen();

  const [currentTab, setCurrentTab] = useState(0);
  const { addToast } = useSuperToast();

  const { updateAvatar, getAvatar } = useProfile();
  const { user } = useAuth();
  const avatar = useMemo(() => getAvatar(), [user]);

  const a11yProps = (index: number) => {
    return {
      id: `account-tab-${index}`,
      "aria-controls": `account-tabpanel-${index}`
    };
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleAvatarUpdate = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (+bytesToMB(file.size) > 10) {
      addToast("Please select a file under 10MB.", ToastTypes.FAIL);
      return;
    }
    const data = createAvatarUpdateFormData(file);
    const avatarUpdateResponse = await updateAvatar(data);
    if (isApiErrorResponse(avatarUpdateResponse)) {
      console.error(avatarUpdateResponse.message);
      addToast("There was an error setting your avatar.", ToastTypes.FAIL);
    } else {
      addToast("Your avatar was successfully updated.", ToastTypes.SUCCESS);
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1
  });

  return (
    <div className="page-content">
      <div className="page-content-wrapper">
        <div className={styles.backButton}>
          <BackButton
            text="Back to dashboard"
            onClick={() => setCurrentScreen(SCREEN_TYPES.DASHBOARD_ROOT)}
          />
        </div>

        <div className={styles.pfpSection}>
          <h1>Account</h1>
          <span className={styles.pfpText}>
            View and manage your account settings.
          </span>
          <div className={styles.pfpWrapper}>
            <div className={styles.userProfile}>
              <img src={avatar} alt="Logo" />
            </div>
            <div className={styles.buttonContainer}>
              <div className={styles.updateAvatar}>
                <Button
                  component="label"
                  variant="outlined"
                  size="medium"
                  id={styles.updateAvatarButton}
                >
                  Update photo
                  <VisuallyHiddenInput
                    type="file"
                    id="avatar"
                    name="avatar"
                    accept="image/*"
                    className={styles.hiddenInput}
                    onChange={handleAvatarUpdate}
                  />
                </Button>
              </div>
              <BackgroundChange />
            </div>
          </div>
        </div>

        <div className={styles.tabsWrapper}>
          <div className={styles.tabs}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              aria-label="Account section toggle"
            >
              <Tab label="My Details" {...a11yProps(0)} />
              <Tab label="Password" {...a11yProps(1)} />
            </Tabs>
          </div>

          <MyDetailsTab value={currentTab} index={0} />
          <PasswordTab value={currentTab} index={1} />
        </div>
      </div>
    </div>
  );
};
export default withAuth(AccountBody);
