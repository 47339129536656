import { SHARED_ACCESS_TYPE } from "@/types/contexts/share-context";
import React from "react";
import { SharedUserProps } from "./shared-user.types";
import CloseIcon from "@/components/icons/CloseIcon";
import styles from "./shared-user.module.scss";

function SharedUser({
  avatar,
  id,
  email,
  accessType,
  handleAccessTypeChange,
  handleRemoveAccess
}: SharedUserProps) {
  return (
    <li className={styles.sharedUserListItem}>
      <div className={styles.sharedUserLeft}>
        <img
          src={avatar ?? "/avatars/0.png"}
          className={styles.sharedUserAvatar}
        />
        <span>{email}</span>
      </div>
      <div className={styles.sharedUserRight}>
        <select
          className={styles.accessTypeSelect}
          value={accessType}
          onChange={(e) => {
            const newAccessType = parseInt(
              e.target.value
            ) as SHARED_ACCESS_TYPE;
            handleAccessTypeChange({ email, id, accessType: newAccessType });
          }}
        >
          <option value={SHARED_ACCESS_TYPE.ViewOnly}>View Only</option>
          <option value={SHARED_ACCESS_TYPE.Collaborator}>Collaborator</option>
          <option value={SHARED_ACCESS_TYPE.Manager}>Manager</option>
        </select>
        <button
          className={styles.removeButton}
          aria-label={`Remove access for ${email}`}
          onClick={() => handleRemoveAccess({ email, id })}
        >
          <CloseIcon className={styles.removeIcon} color="var(--danger-100)" />
        </button>
      </div>
    </li>
  );
}

export default SharedUser;
