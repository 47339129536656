export enum ACCESS_TYPE {
  PublicNoAccess = -2,
  NoAccess = -1,
  ViewOnly = 0,
  Collaborator = 1,
  Manager = 2,
  Owner = 99
}

export enum SHARED_ACCESS_TYPE {
  PublicNoAccess = -2,
  NoAccess = -1,
  ViewOnly = 0,
  Collaborator = 1,
  Manager = 2
}

export interface SharedUserDetails {
  id?: string;
  email: string;
  access: {
    access_type: SHARED_ACCESS_TYPE;
  };
  profile?: {
    avatar?: string;
  };
}
