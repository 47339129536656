import { GetExcludedEmailsProps, UserSearchQueryProps } from "./helpers.types";

// Exclude current user and extract IDs from sharedUsers
export function getExcludedEmails({ sharedUsers }: GetExcludedEmailsProps) {
  return sharedUsers?.map((user) => user.email) || [];
}

// Construct query for user search
export const userSearchQuery = ({
  searchStr,
  sharedUsers = [],
  assetOwner
}: UserSearchQueryProps) => {
  const excludedEmails = [
    assetOwner.email,
    ...getExcludedEmails({ sharedUsers })
  ].join(",");

  const query = {
    "filter[search]": searchStr,
    "filter[excluded_emails]": excludedEmails,
    include: "profile"
  };

  return query;
};
