import React from "react";
import { useAssets } from "@/contexts/assets/assets.hook";
import { sanitizeInput } from "@/lib/sanitize";
import { removeExtensionFromName } from "@/lib/files";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import { isApiErrorResponse } from "@/lib/error";
import { isValidFileName } from "@/lib/validate-file-name";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import styles from "./SettingsTab.module.scss";
import FormControl from "@mui/material/FormControl";
import { components } from "@/types/beta.schema";
import DownloadIcon from "@/components/icons/DownloadIcon";
import TransferIcon from "@/components/icons/TransferIcon";
import DeleteLeftIcon from "@/components/icons/DeleteLeftIcon";
import { usePreviewPanel } from "@/components/shared/asset-previews/preview-panel/usePreviewPanel";
import { useModal } from "@/components/shared/Modal/useModal";
import DeleteConfirmation from "./partials/DeleteConfirmation.component";
import { validationSchema } from "./data/form.data";
import { SettingsTabProps } from "./settings-tab.types";
import classNames from "classnames";
import { useProfile } from "@/contexts/profile/profile.hook";
import { useAuth } from "@/contexts/auth/auth.hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserXmark } from "@fortawesome/free-solid-svg-icons";

// TODO: All refresh functions called will probably need page and sort

const SettingsPanel: React.FC<SettingsTabProps> = ({
  assetResource,
  isShortcut,
  isOwner,
  isManager
}) => {
  const { user } = useAuth();
  const { update, refresh, remove, deleteUserAccess, folderState } =
    useAssets();
  const { viewingProfile } = useProfile();
  const { closePreviewPanel } = usePreviewPanel();
  const { addToast } = useSuperToast();
  const { openModal, closeModal } = useModal();
  let assetId: string,
    assetName: string,
    assetVisibility: components["schemas"]["VisibilityType"];

  if (isShortcut) {
    assetId = assetResource.asset_id;
    assetName = assetResource.asset.name;
    assetVisibility = assetResource.asset.visibility;
  } else {
    assetId = assetResource.id;
    assetName = assetResource.name;
    assetVisibility = assetResource.visibility;
  }

  const folderId = folderState?.currentFolder?.id ?? null;

  const fileNameFormik = useFormik({
    initialValues: {
      name: assetName
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async ({ name }) => {
      const nameWithRemovedExtension = removeExtensionFromName(name);
      const sanitized = sanitizeInput(nameWithRemovedExtension);
      //Currently no name validation on BE side, just does same checks as v1 (<30 chars and not empty)
      const { isValid, message } = isValidFileName(sanitized);
      if (!isValid) {
        addToast(message, ToastTypes.FAIL);
        return;
      }
      const updateNameResponse = await update(assetId, {
        name: sanitized
      });
      if (isApiErrorResponse(updateNameResponse)) {
        console.error(updateNameResponse.message);
        addToast(updateNameResponse.message, ToastTypes.FAIL);
      } else {
        addToast("File renamed successfully", ToastTypes.SUCCESS);
        refresh({ folder_id: folderId, owner_id: viewingProfile?.id });
      }
    }
  });

  const handlePrivacyChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newSetting = e.target
      .value as components["schemas"]["VisibilityType"];

    //No-op if the value isn't actually changing
    if (newSetting === assetVisibility) {
      return;
    }
    const updatePrivacyResponse = await update(assetId, {
      visibility: newSetting
    });
    if (isApiErrorResponse(updatePrivacyResponse)) {
      e.preventDefault();
      console.error(updatePrivacyResponse.message);
      addToast(updatePrivacyResponse.message, ToastTypes.FAIL);
    } else {
      addToast("File visibility was successfully updated.", ToastTypes.SUCCESS);
      refresh({ folder_id: folderId, owner_id: viewingProfile?.id });
    }
  };

  const handleDelete = async () => {
    const { message } = await remove(assetId);
    if (message !== "Asset deleted") {
      console.error(message);
      addToast(message, ToastTypes.FAIL);
    } else {
      addToast("File deleted successfully.", ToastTypes.SUCCESS);
      closeModal();
      closePreviewPanel();
      refresh({ folder_id: folderId, owner_id: viewingProfile?.id }, true);
    }
  };

  const handleUnshareWithSelf = async () => {
    const unshareWithSelfResponse = await deleteUserAccess(assetId, user.id);

    if (isApiErrorResponse(unshareWithSelfResponse)) {
      addToast(unshareWithSelfResponse.message, ToastTypes.FAIL);
    } else {
      addToast("You've been removed from this asset", ToastTypes.SUCCESS);
      closePreviewPanel();
      refresh({ folder_id: folderId, owner_id: viewingProfile?.id }, true);
    }
  };

  return (
    <div className={styles.settingsContainer}>
      <div className={styles.settingsSection}>
        <span className={styles.subtitle}>{"File Name"}</span>
        {isOwner || isManager ? (
          <form onSubmit={fileNameFormik.handleSubmit}>
            <TextField
              fullWidth
              id="name"
              name="name"
              value={fileNameFormik.values.name}
              onChange={fileNameFormik.handleChange}
              onBlur={fileNameFormik.handleBlur}
              error={
                fileNameFormik.touched.name && Boolean(fileNameFormik.errors)
              }
              helperText={
                fileNameFormik.touched.name && fileNameFormik.errors.name
              }
              className="text-input w-75"
              placeholder=""
            />
          </form>
        ) : (
          <div className={styles.unauthorizedName}>{assetName}</div>
        )}
      </div>
      {!isOwner && !isManager && (
        <div className={styles.unauthorizedMessage}>
          <img src="/images/information-circle-solid.svg" alt="info icon" />
          You do not have access to change these settings
        </div>
      )}
      <div
        className={classNames(styles.settingsSection, {
          [`${styles.unauthorizedSection}`]: isManager
        })}
      >
        <span className={styles.subtitle}>{"Manage"}</span>
        {isManager && (
          <div className={styles.unauthorizedMessage}>
            <img src="/images/information-circle-solid.svg" alt="info icon" />
            You do not have access to change these settings
          </div>
        )}
        <button disabled={!isOwner || true} className={styles.iconButton}>
          <DownloadIcon className={styles.icon} />
          {"Download"}
        </button>
        <button disabled={!isOwner || true} className={styles.iconButton}>
          <TransferIcon className={styles.icon} />
          {"Transfer ownership"}
        </button>
        <button
          disabled={!isOwner}
          className={`${styles.iconButton} ${styles.delete}`}
          onClick={() => {
            openModal({
              showActions: false,
              content: (
                <DeleteConfirmation
                  onCancel={closeModal}
                  onConfirm={() => {
                    handleDelete();
                  }}
                />
              )
            });
          }}
        >
          <DeleteLeftIcon className={styles.icon} />
          {"Delete"}
        </button>
      </div>
      <div className={styles.settingsSection}>
        <span className={styles.subtitle}>{"Privacy"}</span>
        <FormControl disabled={!isOwner && !isManager}>
          <RadioGroup
            name="privacy-radio-group"
            defaultValue={assetVisibility}
            value={assetVisibility}
          >
            <FormControlLabel
              value="public"
              control={
                <Radio
                  checked={assetVisibility === "public"}
                  sx={{
                    color: "white",
                    "&.Mui-checked": { color: "white" }
                  }}
                />
              }
              onChange={handlePrivacyChange}
              label="Public"
              sx={{
                ".Mui-disabled": {
                  color: "inherit !important",
                  opacity: "0.6",
                  cursor: "not-allowed"
                }
              }}
            />
            <FormControlLabel
              value="private"
              control={
                <Radio
                  checked={assetVisibility === "private"}
                  sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
                />
              }
              onChange={handlePrivacyChange}
              label="Private"
              sx={{
                ".Mui-disabled": {
                  color: "inherit !important",
                  opacity: "0.6",
                  cursor: "not-allowed"
                }
              }}
            />
            <FormControlLabel
              value="restricted"
              control={
                <Radio
                  checked={assetVisibility === "restricted"}
                  sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
                />
              }
              onChange={handlePrivacyChange}
              label="Restricted"
              sx={{
                ".Mui-disabled": {
                  color: "inherit !important",
                  opacity: "0.6",
                  cursor: "not-allowed"
                }
              }}
            />
          </RadioGroup>
        </FormControl>
        <div
          tabIndex={0}
          style={{
            width: "1px",
            height: "1px",
            opacity: 0,
            overflow: "hidden"
          }}
        />
      </div>
      {!isOwner && (
        <div className={styles.settingsSection}>
          <span className={styles.subtitle}>Manage your access</span>
          <button
            onClick={handleUnshareWithSelf}
            className={`${styles.iconButton} ${styles.delete}`}
          >
            <FontAwesomeIcon icon={faUserXmark} />
            Remove your access
          </button>
        </div>
      )}
    </div>
  );
};

export default SettingsPanel;
