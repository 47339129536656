import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { getInvitationDetails } from "@/services/auth-service";
import { useSearchParams } from "react-router-dom";
import { isServicesError } from "@/lib/services";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import SetPasswordScreen from "@/components/screens/Register/set-password/SetPasswordScreen.component";
import { InvitationDetailsState, RegistrationScreen } from "./register.types";
import CodeScreen from "@/components/screens/Register/code/CodeScreen.component";
import LoadingIcon from "@/components/icons/LoadingIcon";
import SuccessTemplate from "@/components/screens/Register/partials/SuccessTemplate";

function RegisterPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { addToast } = useSuperToast();

  const token = searchParams.get("token");
  const passcode = searchParams.get("passcode");

  const [screen, setScreen] = useState(RegistrationScreen.PASSWORD);
  const [loading, setLoading] = useState(true);

  const [invitationDetails, setInvitationDetails] =
    useState<InvitationDetailsState>({
      id: "",
      status: "",
      recipientEmail: ""
    });

  useEffect(() => {
    if (!token && !passcode) {
      return navigate("/");
    }

    (async () => {
      try {
        const res = await getInvitationDetails({ token });

        if (isServicesError(res)) {
          console.error(res);

          addToast(res.message, ToastTypes.FAIL);
          return navigate("/");
        }

        const { status } = res;

        setInvitationDetails(res);

        setLoading(false);

        if (status === "PENDING") {
          return setScreen(RegistrationScreen.PASSWORD);
        }

        if (status === "PENDING_VERIFICATION") {
          return setScreen(RegistrationScreen.CODE);
        }

        if (status === "ACCEPTED") {
          return setScreen(RegistrationScreen.ACCEPTED);
        }

        return navigate("/");
      } catch (error) {
        console.error(error);
        return addToast(error.message, ToastTypes.FAIL);
      }
    })();
  }, [searchParams]);

  const classes = classNames({
    [styles.container]: true,
    "page-layout": true
  });

  const renderContent = () => {
    if (loading) {
      return (
        <div className="loading-wrapper">
          <LoadingIcon />
        </div>
      );
    }

    if (screen === RegistrationScreen.CODE) {
      return <CodeScreen id={invitationDetails.id} setScreen={setScreen} />;
    }

    if (screen === RegistrationScreen.ACCEPTED) {
      return <SuccessTemplate />;
    }

    if (screen === RegistrationScreen.CONFIRMATION) {
      return <SuccessTemplate />;
    }

    return (
      <SetPasswordScreen
        email={invitationDetails.recipientEmail}
        setScreen={setScreen}
      />
    );
  };

  return (
    <div className={classes}>
      <div className={styles.imageWrapper}>
        <img src="/images/Super_Secure_Combomark 2.svg" alt="Logo" />
      </div>
      {renderContent()}
    </div>
  );
}

export default RegisterPage;
