import React, { useEffect } from "react";
import { RenameFolderTemplateProps } from "./RenameFolderTemplate.types";
import { validationSchema } from "./data/form";
import { components } from "@/types/beta.schema";
import { useFormik } from "formik";
import { updateAsset } from "@/services/asset-service";
import { useAssets } from "@/contexts/assets/assets.hook";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { Button, TextField } from "@mui/material";
import styles from "./RenameFolderTemplate.module.scss";

function RenameFolderTemplate({ asset, onClose }: RenameFolderTemplateProps) {
  const { refresh, folderState } = useAssets();
  const { addToast } = useSuperToast();

  useEffect(() => {
    formik.setFieldValue("name", asset.name);
  }, []);

  const formik = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload: components["schemas"]["UpdateAssetData"] = {
        name: values.name
      };

      const { error } = await updateAsset(asset.id, payload);

      if (error) {
        addToast(error.message, ToastTypes.FAIL);
      } else {
        refresh({ folder_id: folderState?.currentFolder?.id });
        addToast("Success", ToastTypes.SUCCESS);
      }

      onClose();
    }
  });

  return (
    <div>
      <span className="title">Rename folder</span>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          className="text-input"
          placeholder="Folder name"
        />
        <div className={styles.actions}>
          <Button
            onClick={onClose}
            size="small"
            color="primary"
            variant="contained"
            type="button"
            className={`super-button ${styles.button}`}
          >
            {"Cancel"}
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            type="submit"
            className={`super-button brand-secondary ${styles.button}`}
          >
            {"Confirm"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default RenameFolderTemplate;
