import { TextField, Button } from "@mui/material";
import styles from "./SetPasswordScreen.module.scss";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { validationSchema } from "./data/form";
import { SignUpPayload } from "@/types/services/auth-service";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { isServicesError } from "@/lib/services";
import { signUp } from "@/services/auth-service";
import { useSearchParams } from "react-router-dom";
import { SetPasswordScreenProps } from "./SetPasswordScreen.types";
import { RegistrationScreen } from "@/pages/register/register.types";

function SetPasswordScreen({ email, setScreen }: SetPasswordScreenProps) {
  const [searchParams] = useSearchParams();
  const { addToast } = useSuperToast();

  const token = searchParams.get("token");

  useEffect(() => {
    if (email) {
      formik.setFieldValue("email", email);
    }
  }, [email]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordConfirmation: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const payload: SignUpPayload = {
          token,
          email: values.email,
          password: values.password
        };

        const res = await signUp(payload);

        if (isServicesError(res)) {
          console.error(res);
          return addToast(res.message, ToastTypes.FAIL);
        }

        setScreen(RegistrationScreen.CODE);
      } catch (error) {
        console.error(error);
        return addToast(error.message, ToastTypes.FAIL);
      }
    }
  });

  return (
    <div className={styles.content}>
      <div className={styles.dialog}>
        <span className="description">
          To create a secure password, please include at least 12 characters
          with a mix of uppercase and lowercase letters, a number, and one
          special character (e.g., !@#$%^&*).
        </span>
      </div>

      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          className="text-input"
          placeholder="Email"
          disabled
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "var(--light-500)"
            }
          }}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          className="text-input"
          placeholder="Password"
          type="password"
        />
        <TextField
          fullWidth
          id="passwordConfirmation"
          name="passwordConfirmation"
          value={formik.values.passwordConfirmation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.passwordConfirmation &&
            Boolean(formik.errors.passwordConfirmation)
          }
          helperText={
            formik.touched.passwordConfirmation &&
            formik.errors.passwordConfirmation
          }
          className="text-input"
          placeholder="Confirm Password"
          type="password"
        />
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          className="super-button"
        >
          Create Account
        </Button>
      </form>
    </div>
  );
}

export default SetPasswordScreen;
