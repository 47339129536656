import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { validationSchema } from "./data/form";
import { useFormik } from "formik";
import { ForgotPasswordPayload } from "@/types/services/auth-service";
import { forgotPassword } from "@/services/auth-service";
import ResetPasswordScreen from "@/components/screens/ForgotPassword/Reset/ResetPasswordScreen";

function ForgotPassword() {
  const navigate = useNavigate();
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const resetId = queryParams.get("reset_id");

  const isResetScreen = !!resetId;

  const classes = classNames({
    [styles.container]: true,
    "page-layout": true
  });

  const goToLogoScreen = () => {
    navigate("/");
  };

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const payload: ForgotPasswordPayload = {
          email: values.email
        };

        await forgotPassword(payload);
      } catch (error) {
        // Note: JG | Add error toast here
        console.error(error);
      }
    }
  });

  const renderContent = () => {
    if (isResetScreen) {
      return <ResetPasswordScreen resetId={resetId} />;
    }

    return (
      <div className={styles.content}>
        <div className={styles.dialog}>
          <span className="title">Forgot password?</span>
          <span className="description">
            No worries, we’ll send you reset instructions.
          </span>
        </div>

        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            className="text-input"
            placeholder="Email"
          />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            className="super-button"
          >
            Reset Password
          </Button>
        </form>

        <Link className={styles.back} to="/">
          Back to log in
        </Link>
      </div>
    );
  };

  return (
    <div className={classes}>
      <button className={styles.imageWrapper} onClick={goToLogoScreen}>
        <img src="/images/Super_Secure_Combomark 2.svg" alt="Logo" />
      </button>
      {renderContent()}
    </div>
  );
}

export default ForgotPassword;
