import React, { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { ForgotPasswordScreenProps } from "@/types/pages/forgot-password";

function ResetPasswordScreen({ resetId }: ForgotPasswordScreenProps) {
  const [step, setStep] = useState(1);

  const goToConfirmationScreen = () => {
    setStep(2);
  };

  const renderContent = () => {
    if (step === 1)
      return (
        <Step1
          resetId={resetId}
          goToConfirmationScreen={goToConfirmationScreen}
        />
      );

    return <Step2 />;
  };

  return <div>{renderContent()}</div>;
}

export default ResetPasswordScreen;
