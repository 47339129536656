export interface FullscreenPreviewProps {
  isModal?: boolean;
  ownerName?: string;
  assetName?: string;
  assetId?: string;
}

export enum BackgroundMode {
  GALLERY = "GALLERY",
  CUSTOM = "CUSTOM"
}

export interface FullscreenPreviewState {
  width: number;
  height: number;
}

export interface AdjustPlayerSizeParams {
  width: number;
  height: number;
}
