import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  memo,
  useRef
} from "react";
import { MapRef, Map as ReactMap } from "react-map-gl";
import { useAssets } from "@/contexts/assets/assets.hook";
import { MAPBOX_TOKEN, MAPBOX_STYLES } from "@/config/constants";
import { components } from "@/types/beta.schema";
import { isApiErrorResponse } from "@/lib/error";
import ActivityItem from "../SideSheetTabs/partials/ActivityItem/ActivityItem.component";
import "mapbox-gl/dist/mapbox-gl.css";
import styles from "./SuperMap.module.scss";
import ViewsIcon from "../icons/ViewsIcon";
import { getUserProfileImage } from "@/lib/user";
import CustomMarker from "./partials/CustomMarker/CustomMarker.component";
import LiveViewerItem from "./partials/LiveViewerItem/LiveViewerItem.component";
import { SuperMapProps } from "./SuperMap.types";

const SuperMap: React.FC<SuperMapProps> = ({
  initialViewState,
  markerToFocus,
  assetResource,
  isShortcut
}: SuperMapProps) => {
  const { activityLog, assetChannel, currentlyViewing, setCurrentlyViewing } =
    useAssets();
  const [viewsList, setViewsList] = useState<
    Array<components["schemas"]["ActivityData"]>
  >([]);

  const [activeView, setActiveView] = useState<string>("history");
  const mapRef = useRef<MapRef>(null);
  //Note: This is not stateful because any changes to the value would cause a re-paint of the map.
  let userInteracting = false;
  const SECONDS_PER_REVOLUTION = useMemo(() => 180, []);
  const MAX_SPIN_ZOOM = useMemo(() => 5, []);
  const SLOW_SPIN_ZOOM = useMemo(() => 3, []);
  const { getAssetDetails } = useAssets();
  const assetId = isShortcut ? assetResource.asset_id : assetResource.id;
  const [viewCount, setViewCount] = useState<number>(0);

  useEffect(() => {
    window.Echo.private(`asset.${assetId}.manager`).listen(
      "ActivityLogged",

      (activity) => {
        if (activity.type === "viewed") {
          setViewsList((prev) => {
            const merged = [activity, ...prev];
            const uniqueActivityLog = Array.from(
              new Map(merged.map((item) => [item.id, item])).values()
            );
            return uniqueActivityLog;
          });
          setViewCount((prevCount) => prevCount + 1);
        }
      }
    );
    if (assetChannel.current) {
      return () => {
        window.Echo.leave(`asset.${assetId}.manager`);
      };
    }
  }, [assetChannel, activityLog]);

  const fetchOnLoad = useCallback(async () => {
    /**
     * Note: No views will be recorded until the information tab is done.
     * Views are recorded upon hitting the /v1/assets/{asset} endpoint with a view parameter.
     */
    const activityResponse = await activityLog(assetId, {
      "filter[type]": "viewed"
    });
    if (isApiErrorResponse(activityResponse)) {
      console.error(activityResponse.message);
    } else {
      setViewsList(activityResponse.data);
    }

    const asset = await getAssetDetails(assetId);
    if (!isApiErrorResponse(asset)) {
      setViewCount(asset.view_count);
    }
  }, [activityLog, getAssetDetails, assetId]);

  useEffect(() => {
    fetchOnLoad();
  }, [assetId, fetchOnLoad]);

  useEffect(() => {
    fetchOnLoad();
  }, [assetId, fetchOnLoad]);

  useEffect(() => {
    if (assetChannel.current) {
      assetChannel.current.joining((user) => {
        console.log(user);
        setCurrentlyViewing((prev) => {
          const temp = new Map(prev);
          temp.set(user?.id ?? user.ip, user);
          return temp;
        });
      });
      assetChannel.current.leaving((user) => {
        console.log(user);
        setCurrentlyViewing((prev) => {
          const temp = new Map(prev);
          temp.delete(user?.id ?? user?.ip);
          return temp;
        });
      });
    }
  }, [assetChannel]);

  const spinGlobe = useCallback(
    (e) => {
      const map = e.target;
      const zoom = map.getZoom();
      if (!userInteracting && zoom < MAX_SPIN_ZOOM) {
        let speed = 360 / SECONDS_PER_REVOLUTION;
        if (zoom > SLOW_SPIN_ZOOM) {
          const zoomDif =
            (MAX_SPIN_ZOOM - zoom) / (MAX_SPIN_ZOOM - SLOW_SPIN_ZOOM);
          speed *= zoomDif;
        }
        const center = map.getCenter();
        center.lng -= speed;
        map.easeTo({ center: center, duration: 1000, easing: (n) => n });
      }
    },
    [MAX_SPIN_ZOOM, SECONDS_PER_REVOLUTION, SLOW_SPIN_ZOOM, userInteracting]
  );

  //TO-DO: Live viewers, requires websocket work.
  return (
    <div className={styles.mapContainer}>
      <ReactMap
        id={"map"}
        ref={mapRef}
        mapStyle={MAPBOX_STYLES}
        mapboxAccessToken={MAPBOX_TOKEN}
        style={{ height: "400px", width: "100%", borderRadius: "8px" }}
        initialViewState={initialViewState}
        reuseMaps
        attributionControl={false}
        onLoad={(e) => {
          spinGlobe(e);
        }}
        onMouseDown={() => {
          userInteracting = true;
        }}
        onMouseUp={() => {
          userInteracting = false;
        }}
        onDragEnd={(e) => {
          userInteracting = false;
          spinGlobe(e);
        }}
        onPitchEnd={(e) => {
          userInteracting = false;
          spinGlobe(e);
        }}
        onRotateEnd={(e) => {
          userInteracting = false;
          spinGlobe(e);
        }}
        onMoveEnd={(e) => {
          if (!userInteracting) {
            spinGlobe(e);
          }
        }}
      >
        {activeView === "live"
          ? Array.from(currentlyViewing.values()).map((entry) => {
              return (
                <CustomMarker
                  key={entry?.id ?? entry.ip}
                  id={entry?.id}
                  longitude={entry.location.longitude}
                  latitude={entry.location.latitude}
                  ip={entry.ip}
                  city={entry.location.city}
                  region={entry.location.region}
                  country={entry.location.country_code}
                  causer_id={entry?.id}
                />
              );
            })
          : viewsList.map((log) => (
              <CustomMarker
                key={log.id}
                id={`${log.id}`}
                longitude={log.properties.location.longitude}
                latitude={log.properties.location.latitude}
                className={styles.marker}
                ip={log.properties.ip}
                city={log.properties?.location?.city}
                region={log.properties?.location?.region}
                causer_id={log.causer_id}
                country={log.properties?.location?.country_code}
                isPopupOpen={markerToFocus?.current == log.id}
                isHistorical
              />
            ))}
      </ReactMap>
      <div className={styles.viewerInfo}>
        <div className={styles.viewData}>
          <ViewsIcon />
          {viewCount}
        </div>
        <div className={styles.recentViewers}>
          <h3 className={styles.sectionTitle}>Recent Viewers</h3>
          <div className={styles.switcherButtons}>
            <button
              className={`${styles.switcherButton} ${activeView === "live" ? styles.active : ""}`}
              onClick={() => {
                setActiveView("live");
              }}
            >
              <span>Live View</span>
            </button>
            <button
              className={`${styles.switcherButton} ${activeView === "history" ? styles.active : ""}`}
              onClick={() => {
                setActiveView("history");
              }}
            >
              <span>History</span>
            </button>
          </div>
        </div>
        {activeView === "history" ? (
          <ul className={styles.activity}>
            {viewsList.map((log) => (
              <ActivityItem
                key={log.id}
                activityItem={log}
                onClick={() => {
                  mapRef.current.flyTo({
                    center: [
                      log.properties.location.longitude,
                      log.properties.location.latitude
                    ],
                    zoom: 6,
                    speed: 0.75
                  });
                  (
                    document.getElementsByClassName(
                      `marker-${log.id}`
                    )[0] as HTMLElement
                  )?.click();
                }}
              />
            ))}
          </ul>
        ) : (
          <ul className={styles.activity}>
            {Array.from(currentlyViewing.values()).map((entry) => {
              return (
                <LiveViewerItem
                  key={entry?.id ?? entry.ip}
                  {...entry}
                  avatar={getUserProfileImage(entry?.avatar)}
                  username={entry?.username}
                />
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default memo(SuperMap);
