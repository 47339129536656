import React from "react";
import styles from "./Logo.module.scss";
import { RootPageProps } from "./Logo.types";
import { motion } from "framer-motion";

function Logo({ onClick }: RootPageProps) {
  return (
    <motion.div
      className={`${styles.logo} page-layout`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1.25 }}
    >
      <motion.button
        className={styles.wrapper}
        onClick={onClick}
        whileHover={{
          scale: 1.05,
          transition: { duration: 0.05 }
        }}
      >
        <img
          src="/images/SuperFileLogoYellow.svg"
          alt="Logo"
          className={styles.logoPulse}
        />
        <img
          src="/images/SuperFileLogoYellow.svg"
          alt="Logo"
          className={styles.logoOriginal}
        />
      </motion.button>
    </motion.div>
  );
}

export default Logo;
