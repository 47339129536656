import { useState, useEffect } from "react";
import { useAuth } from "@/contexts/auth/auth.hook";
import { getAccessToken } from "@/lib/network";
import { API_URL } from "@/config/constants";

const useFetchUserMiddleware = (id: string) => {
  const { knownUsers, addKnownUser } = useAuth();
  const [data, setData] = useState(knownUsers[id]);
  // This *mostly* works, but, the issue is that concurrent requests in the case of rendering multiple components
  // by mapping over an array will cause cache misses because the render / fetching are non-blocking.
  // This means that those concurrent renders will miss the cache on first render if trying to access the same user.
  // Not sure how to solve this yet?
  useEffect(() => {
    const token = getAccessToken();
    const fetchData = async () => {
      if (!id) {
        return;
      }
      if (knownUsers[id]) {
        setData(knownUsers[id]);
        return;
      } else {
        try {
          const response = await fetch(`${API_URL}/api/v1/users/${id}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              Accept: "application/json"
            }
          });
          const result = await response.json();
          setData(result.data);
          addKnownUser(result.data);
        } catch (error) {
          setData(error);
        }
      }
    };
    fetchData();
  }, [id]);
  return data;
};

export default useFetchUserMiddleware;
