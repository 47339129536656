export const breadcrumbsAnimation = {
  enter: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.3, delay: 0.05 }
  },
  exit: {
    x: 50,
    opacity: 0,
    transition: { duration: 0.15 }
  }
};
