import React, { memo } from "react";
import ViewsIcon from "@/components/icons/ViewsIcon";
import EditIcon from "@/components/icons/EditIcon";
import LinkIcon from "@/components/icons/LinkIcon";
import BrokenLinkIcon from "@/components/icons/BrokenLinkIcon";
import styles from "./ActivityItem.module.scss";
import formatDate from "@/lib/format-date";
import {
  ActivityItemProperties,
  ViewedActivityProperties,
  ActivityItemProps
} from "@/types/activity";
import { Link } from "react-router-dom";
import { useProfile } from "@/contexts/profile/profile.hook";
import { UserDetails } from "@/types/contexts/user-context";
import useFetchUserMiddleware from "@/hooks/useFetchUserMiddleware";
import { haveActivityItemPropsChanged } from "../ActivityLog/data/helpers";
import { usePreviewPanel } from "@/components/shared/asset-previews/preview-panel/usePreviewPanel";
import { getUserProfileImage } from "@/lib/user";
import { useAuth } from "@/contexts/auth/auth.hook";

const ActivityItem = ({ activityItem, onClick }: ActivityItemProps) => {
  //Note: ActivityResource also defines a causer_type. This can be safely ignored for now until groups exist.
  const { id, type, causer_id, properties, created_at } = activityItem;
  const causer: UserDetails = useFetchUserMiddleware(causer_id);
  const user: UserDetails = useFetchUserMiddleware(properties?.user_id);
  const { user: authUser } = useAuth();
  const { setViewingProfile } = useProfile();
  const { closePreviewPanel } = usePreviewPanel();

  const causerIsAuth = causer_id === authUser.id;
  const userIsAuth = properties?.user_id === authUser.id;

  if (activityItem.type === "request-created") {
    return null;
  }

  const renderUserIcon = () => {
    if (!causer_id) {
      return (
        <div className={styles.userProfilePic}>
          <img src="/images/logo-yellow-bg.png" />
        </div>
      );
    } else {
      return (
        <Link
          to={`${causer?.username}`}
          onClick={() => {
            setViewingProfile(causerIsAuth ? null : causer);
            closePreviewPanel();
          }}
          className={styles.userProfilePic}
        >
          <img src={getUserProfileImage(causer?.profile?.avatar)} />
        </Link>
      );
    }
  };

  const renderUserName = () => {
    if (!causer_id) {
      return <>Anonymous</>;
    } else {
      return (
        <Link
          to={`${causer?.username}`}
          onClick={() => {
            setViewingProfile(causerIsAuth ? null : causer);
            closePreviewPanel();
          }}
        >
          {causer?.username}
        </Link>
      );
    }
  };

  const renderActivityIcon = (type: string) => {
    switch (type) {
      case "viewed":
        return <ViewsIcon color={"rgb(22, 130, 212)"} />;
      case "updated":
      case "created":
      case "share-updated":
        return <EditIcon />;
      case "share-created":
        return <LinkIcon color="#FFCC00" />;
      case "share-deleted":
        return <BrokenLinkIcon />;
      default:
        break;
    }
  };

  const renderViewActivityCopy = (properties: ViewedActivityProperties) => {
    return causer_id ? (
      <div className={`${styles.logText} ${styles.view}`}>
        <Link
          to={`${causer?.username}`}
          className={styles.user}
          onClick={() => {
            setViewingProfile(causerIsAuth ? null : causer);
            closePreviewPanel();
          }}
        >
          {causer?.username}
        </Link>
        <span
          id={`${id}`}
          data-latitude={properties.location.latitude}
          data-longitude={properties.location.longitude}
          onClick={onClick}
        >
          {"\u00A0"}
          <b>{type}</b>
          {`\u00A0this file.`}
        </span>
      </div>
    ) : (
      <div className={`${styles.logText} ${styles.view}`}>
        <div>{`Someone (${properties.ip})`}</div>
        <span
          id={`${id}`}
          data-latitude={properties.location.latitude}
          data-longitude={properties.location.longitude}
          onClick={onClick}
        >
          {"\u00A0"}
          <b>{type}</b>
          {`\u00A0this file.`}
        </span>
      </div>
    );
  };

  const renderActivityCopy = (properties: ActivityItemProperties) => {
    switch (type) {
      case "updated":
        if (properties.attributes?.visibility) {
          return (
            <div className={styles.logText}>
              {renderUserName()}
              {"\u00A0"}
              <b>{type}</b>
              {`\u00A0this file's privacy settings.`}
            </div>
          );
        } else if (properties?.attributes?.name) {
          return (
            <div className={styles.logText}>
              {renderUserName()}
              {"\u00A0"}
              <b>{"renamed"}</b>
              {`\u00A0this file.`}
            </div>
          );
        }
        break;
      case "created":
        return (
          <div className={styles.logText}>
            {renderUserName()}
            {"\u00A0"}
            <b>{type}</b>
            {`\u00A0this file.`}
          </div>
        );
      case "share-created":
        return (
          <div className={styles.logText}>
            {renderUserName()}
            {"\u00A0"}
            <b>{"shared"}</b>
            {`\u00A0this file with\u00A0`}
            <Link
              to={`${user?.username}`}
              className={styles.user}
              onClick={() => {
                setViewingProfile(userIsAuth ? null : user);
                closePreviewPanel();
              }}
            >
              {user?.username}
            </Link>
          </div>
        );
      case "share-deleted":
        return (
          <div className={styles.logText}>
            {renderUserName()}
            {"\u00A0"}
            <b>{"unshared"}</b>
            {`\u00A0this file from\u00A0`}
            <Link
              to={`${user?.username}`}
              onClick={() => {
                setViewingProfile(userIsAuth ? null : user);
                closePreviewPanel();
              }}
            >
              {user?.username}
            </Link>
          </div>
        );
      case "share-updated":
        return (
          <div className={styles.logText}>
            {renderUserName()}
            {"\u00A0"}
            <b>{"updated"}</b>
            {`\u00A0access to this file.`}
          </div>
        );
    }
  };

  // Wait to render the item until causer exists
  if (causer_id && !causer) {
    return null;
  }

  return (
    <li className={styles.log}>
      <div className={styles.logInfo}>
        {renderUserIcon()}
        <div className={styles.activityIcon}>{renderActivityIcon(type)}</div>
        {type === "viewed"
          ? renderViewActivityCopy(properties)
          : renderActivityCopy(properties)}
      </div>
      <div className={styles.date}>{formatDate(created_at)}</div>
    </li>
  );
};

ActivityItem.displayName = "ActivityItem";

export default memo(ActivityItem, haveActivityItemPropsChanged);
