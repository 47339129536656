import React, { useEffect } from "react";
import { SHARED_ACCESS_TYPE } from "@/types/contexts/share-context";
import { UserDropdownProps } from "./user-search.types";
import styles from "./user-search.module.scss";
import { UserDetails } from "@/types/contexts/user-context";

function UserDropdown(
  {
    users,
    onUserSelect,
    onClose,
    nextFocusableElem,
    inputRef
  }: UserDropdownProps,
  ref: React.Ref<HTMLDivElement>
) {
  const listRef = ref as React.MutableRefObject<HTMLDivElement | null>;

  // Close the dropdown if focus leaves and does not move to the input
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if the click is outside both the dropdown and the input
      if (
        !listRef.current?.contains(e.target as Node) &&
        !inputRef.current?.contains(e.target as Node)
      ) {
        onClose(); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [listRef, inputRef, onClose]);

  // Handle keyboard navigation
  // Mimic same behavior as native select component
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const buttons = listRef.current?.querySelectorAll(".user-dropdown-item");

    if (!buttons || buttons.length === 0) return;

    const focusedElement = document.activeElement;
    const index = Array.from(buttons).indexOf(focusedElement as HTMLElement);

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault(); // Prevent page scroll
        if (index < buttons.length - 1) {
          (buttons[index + 1] as HTMLElement).focus();
        }
        break;

      case "ArrowUp":
        e.preventDefault(); // Prevent page scroll
        if (index > 0) {
          (buttons[index - 1] as HTMLElement).focus();
        }
        break;

      case "Escape":
        e.preventDefault();
        if (nextFocusableElem) {
          nextFocusableElem.focus();
        }
        onClose();
        break;

      case "Tab": {
        if (e.shiftKey) {
          if (index === 0 || index === -1) {
            e.preventDefault();
            onClose({ shouldFocusInput: true });
          }
        } else {
          if (index === buttons.length - 1) {
            e.preventDefault();
            nextFocusableElem?.focus();
            onClose();
          }
        }
        break;
      }

      default:
        break;
    }
  };

  const handleSelectUser = (user: UserDetails) => {
    const { id, email, profile } = user;
    onUserSelect({
      id,
      email,
      access: { access_type: SHARED_ACCESS_TYPE.ViewOnly },
      profile: { avatar: profile?.avatar }
    });
    onClose();

    if (nextFocusableElem) {
      nextFocusableElem.focus();
    }
  };

  const getScrollableParent = (
    element: HTMLElement | null
  ): HTMLElement | null => {
    if (!element) return null;
    let parent = element.parentElement;

    while (parent) {
      const overflowY = window.getComputedStyle(parent).overflowY;
      if (overflowY === "auto" || overflowY === "scroll") {
        return parent;
      }
      parent = parent.parentElement;
    }
    return document.body;
  };

  useEffect(() => {
    const updateDropdownPosition = () => {
      if (inputRef.current && listRef.current) {
        const inputRect = inputRef.current.getBoundingClientRect();
        const dropdownMaxHeight = Math.min(
          window.innerHeight - inputRect.bottom - 48,
          200
        );

        listRef.current.style.top = `${inputRect.bottom}px`;
        listRef.current.style.left = `${inputRect.left}px`;
        listRef.current.style.maxHeight = `${dropdownMaxHeight}px`;
      }
    };

    let animationFrameId;

    const handleResizeOrScroll = () => {
      if (animationFrameId) cancelAnimationFrame(animationFrameId);
      animationFrameId = requestAnimationFrame(() => {
        updateDropdownPosition();
      });
    };

    const scrollableParent = getScrollableParent(inputRef.current);

    window.addEventListener("resize", handleResizeOrScroll);
    scrollableParent?.addEventListener("scroll", handleResizeOrScroll);

    // Initial positioning
    updateDropdownPosition();

    return () => {
      window.removeEventListener("resize", handleResizeOrScroll);
      scrollableParent?.removeEventListener("scroll", handleResizeOrScroll);
      if (animationFrameId) cancelAnimationFrame(animationFrameId);
    };
  }, [inputRef, listRef]);

  return (
    <div
      className={styles.dropdownWrapper}
      ref={listRef}
      onKeyDown={handleKeyDown}
      id="user-search-dropdown"
    >
      <ul className={styles.dropdownList}>
        {users.map((user) => (
          <li key={user.id}>
            <div
              role="button"
              tabIndex={0}
              className={`${styles.dropdownItem} user-dropdown-item`}
              onClick={() => handleSelectUser(user)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSelectUser(user);
                }
              }}
            >
              <img
                src={user.profile?.avatar ?? "/avatars/0.png"}
                className={styles.dropdownItemAvatar}
              />
              {user.email}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default React.forwardRef(UserDropdown);
