import { components } from "@/types/beta.schema";
import { sanitizeInput } from "./sanitize";

export const videoMimeTypes = [
  "video/mp4",
  "video/quicktime",
  "video/ogg",
  "video/webm",
  "video/mpeg",
  "audio/mpeg",
  "audio/webm",
  "audio/x-wav"
];
export const gifMimeTypes = [
  "image/gif",
  "image/avif",
  "image/png",
  "image/webp"
];
export const pdfMimeTypes = "application/pdf";
export const documentMimeTypes = [
  // Document filetypes
  "application/rtf",
  "text/rtf",
  "text/plain",
  // Spreadsheet filetypes
  "text/csv",
  "text/html",
  "application/x-iwork-numbers-sffnumbers",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.spreadsheet-template",
  "application/vnd.oasis.opendocument.spreadsheet-flat-xml",
  "text/spreadsheet",
  "text/xml",
  "application/rss+xml",
  "application/vnd.ms-excel",
  "application/vnd.ms-excel.sheet.macroenabled.12",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  // Word processor filetypes
  "application/msword",
  "application/vnd.ms-word.template.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.oasis.opendocument.text-template",
  "application/vnd.oasis.opendocument.text-flat-xml",
  "application/x-iwork-pages-sffpages",
  "application/vnd.wordperfect",
  // Slideshow filetypes
  "application/x-iwork-keynote-sffkey",
  "application/vnd.oasis.opendocument.graphics",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.oasis.opendocument.presentation-template",
  "application/vnd.oasis.opendocument.presentation-flat-xml",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  // Database filetypes
  "application/vnd.dbf",
  "application/x-dbase",
  "video/x-dv"
];
export const allAcceptedTypes = [
  // Image filetypes
  ".apng",
  ".avif",
  ".bmp",
  ".gif",
  ".jpeg",
  ".jpg",
  ".png",
  ".ppm",
  ".webp",
  // Document filetypes
  ".pdf",
  ".rtf",
  ".txt",
  // Audio filetypes
  ".m4p",
  ".mp3",
  ".ogg",
  ".wav",
  // Video filetypes
  ".mov",
  ".mp4",
  ".ogv",
  ".webm",
  // Spreadsheet filetypes
  ".csv",
  ".fods",
  ".html",
  ".numbers",
  ".ods",
  ".ots",
  ".qpw",
  ".slk",
  ".xlc",
  ".xls",
  ".xlsm",
  ".xlsx",
  ".xlt",
  ".xltx",
  ".xlw",
  ".xml",
  // Word processor filetypes
  ".docm",
  ".docx",
  ".dotx",
  ".doc",
  ".dot",
  ".fodt",
  ".odt",
  ".ott",
  ".pages",
  ".wpd",
  // Slideshow filetypes
  ".fodp",
  ".key",
  ".odg",
  ".odp",
  ".otp",
  ".pom",
  ".pomx",
  ".pot",
  ".potx",
  ".ppmx",
  ".pps",
  ".ppsx",
  ".ppt",
  ".pptm",
  ".pptx",
  // Database filetypes
  ".dbf",
  ".heic"
  // ".dif"
];
export const videoExtensions = ["mp4", "m4p", "ogv", "ogg", "webm"];

/**
 * Converts a file size from bytes to megabytes (MB).
 *
 * @param {number} bytes - The file size in bytes.
 * @returns {string} The file size in megabytes (MB) rounded to two decimal places.
 *
 * @example
 * const sizeInMB = bytesToMB(1048576); // "1.00"
 */
export const bytesToMB = (bytes: number) => {
  return (bytes / (1024 * 1024)).toFixed(2);
};

/**
 * Creates FormData object for API request. All assets being sent must be submitted as multipart/form-data.
 * @param {File} file - The file object to be uploaded.
 * @returns {FormData} - The FormData object that will be submitted as a StoreAssetRequest.
 */
export const createAssetFormData = (file: File, folder_id: null | string) => {
  const form = new FormData();
  const extensionRemoved = removeExtensionFromName(file.name);
  const sanitizedName = sanitizeInput(extensionRemoved);
  form.append("name", sanitizedName);
  form.append("type", "file");
  form.append("visibility", "private");
  form.append("upload", file);
  if (folder_id) {
    form.append("folder_id", folder_id);
  }
  return form as unknown as components["schemas"]["CreateAssetData"];
};

/**
 * Creates FormData object for avatar update request.
 * @param {File} file
 * @returns {FormData} - The FormData object that will be submitted as an UpdateProfileRequest.
 */
export const createAvatarUpdateFormData = (file: File) => {
  const form = new FormData();
  const sanitizedName = sanitizeInput(file.name);
  const fileWithSanitizedName = new File([file], sanitizedName, {
    type: file.type
  });
  form.append("avatar", fileWithSanitizedName);
  return form as unknown as components["schemas"]["UpdateProfileData"];
};

/**
 * Create FormData object for background update request.
 * @param {File} file
 * @returns {FormData} - The FormData object that will be submitted as an UpdateProfileRequest.
 */
export const createBackgroundUpdateFormData = (file: File) => {
  const form = new FormData();
  const sanitizedName = sanitizeInput(file.name);
  const fileWithSanitizedName = new File([file], sanitizedName, {
    type: file.type
  });
  form.append("background", fileWithSanitizedName);
  return form as unknown as components["schemas"]["UpdateProfileData"];
};

/**
 * Checks if the given file path contains a filename with the `.super` extension.
 *
 * @param {string} path - The file path to check.
 * @returns {boolean} - Returns `true` if the filename in the path contains `.super`, otherwise `false`.
 */
export const doesPathContainFilename = (path: string): boolean => {
  const paths = path.split("/");
  const lastPath = paths.at(-1);

  return (
    lastPath.includes(".super") ||
    lastPath.includes(".png") ||
    lastPath.includes(".jpg") ||
    lastPath.includes(".gif")
  );
};

/**
 * Checks if the given file path has more than one segment.
 *
 * @param {string} path - The file path to check.
 * @returns {boolean} - Returns `true` if the path container folders therwise `false`.
 */
export const doesPathContainAssets = (path: string): boolean => {
  const paths = path.split("/");
  paths.shift();

  return paths.length > 1;
};

export const atProfileRoot = (path: string, username: string): boolean => {
  // Remove leading and trailing slashes for consistency
  const normalizedPath = path.replace(/^\/+|\/+$/g, "");

  return normalizedPath === username;
};

export const getPathForRequest = (path: string): string => {
  const splitPaths = path.split("/");
  splitPaths.shift();
  const pathForRequest = splitPaths.join("/");

  return pathForRequest;
};

/**
 * Truncate the given path to include only the folders up to and including the selected folder.
 *
 * @param {string} selectedFolder - The name of the folder to stop at.
 * @param {string} path - The full path to modify.
 * @returns {string} - The truncated path, ending at the selected folder.
 *
 * @example
 * getPathForNavigation("folder1", "/user/projects/folder1/folder2");
 * // Returns: "/user/projects/folder1"
 */
export const getPathForNavigation = (
  selectedFolder: string,
  path: string
): string => {
  const folderNames = path.split("/").filter(Boolean);
  const truncatedPath: Array<string> = [];

  for (const folder of folderNames) {
    truncatedPath.push(folder);

    if (folder === selectedFolder) {
      break;
    }
  }

  const _path = `/${truncatedPath.join("/")}`;
  return _path;
};

/**
 * Remove the file extension from the given file name if it exists.
 * @param {string} name - The name of the file to modify.
 * @returns {string} - The file name without the extension, or the original name if no file extension is found.
 */
export const removeExtensionFromName = (name: string): string => {
  if (!name) {
    return "";
  }
  const extensionIndex = name.lastIndexOf(".");
  const hasFileExtension = allAcceptedTypes.includes(
    name.toLowerCase().substring(extensionIndex)
  );
  //Only remove what is after the period if it's a file extension
  //We also call this any place where the file name is rendered
  //So adding .super here means we never have to worry about it again
  return extensionIndex > 0 && hasFileExtension
    ? `${name.slice(0, extensionIndex)}.super`
    : name.endsWith(".super")
      ? name
      : `${name}.super`;
};

export const isVideo = (source: string): boolean => {
  const extension = source.split(/[?]+/).shift().split(/[.]+/).pop();
  return videoExtensions.indexOf(extension) > -1;
};

export const getVideoBackgroundType = (fileName: string) => {
  const extension = fileName.split(/[?]+/).shift().split(/[.]+/).pop();
  return "video/" + extension;
};

export const getOwnerNameFromPath = (path: string): string => {
  const splitPath = path.split("/");
  return splitPath[1];
};

export const getAssetNameFromPath = (path: string): string => {
  const splitPath = path.split("/");
  return splitPath[splitPath.length - 1];
};

export const getAssetPathFromPath = (path: string): string => {
  const decodedPath = decodeURIComponent(path);
  const splitPath = decodedPath.split("/").slice(2);
  return splitPath.join("/");
};
