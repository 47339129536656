import {
  SCREEN_TYPES,
  CurrentScreenProfile
} from "@/types/contexts/screen-context";
import { ReactNode, useState } from "react";
import ScreenContext from "./screen.context";

export const ScreenProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [currentScreen, setCurrentScreen] = useState<SCREEN_TYPES>(null);
  const [currentScreenProfile, setCurrentScreenProfile] =
    useState<CurrentScreenProfile>(null);

  return (
    <ScreenContext.Provider
      value={{
        currentScreen,
        setCurrentScreen,
        currentScreenProfile,
        setCurrentScreenProfile
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
};
