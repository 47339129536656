import React from "react";
import { Button } from "@mui/material";
import styles from "./DeleteConfirmation.module.scss";
import { DeleteConfirmationProps } from "./types/DeleteConfirmation";

const DeleteConfirmation = ({
  onConfirm,
  onCancel
}: DeleteConfirmationProps) => {
  return (
    <div className={styles.deleteConfirmation}>
      <h2>{"Are you sure?"}</h2>
      <span className={styles.contentText}>
        {
          "Are you sure you want to delete this file? Once deleted this cannot be undone."
        }
      </span>
      <div className={styles.buttons}>
        <Button
          onClick={onCancel}
          size="large"
          color="primary"
          variant="text"
          type="button"
          className="clear-cancel"
        >
          {"Cancel"}
        </Button>
        <Button
          onClick={onConfirm}
          size="large"
          color="primary"
          variant="text"
          type="button"
          className="super-button danger-button"
        >
          {"Delete"}
        </Button>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
