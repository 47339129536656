import React from "react";
import { BaseIconProps } from "./icon.types";

function BrokenLinkIcon({ color = "#FB3449", className }: BaseIconProps) {
  const classes = className ?? "";

  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="%23000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.99 3.991c-1.553-1.521-3.681-1.58-4.765-.518l-3.7 3.625a.75.75 0 0 1-1.05-1.071l3.7-3.625c1.836-1.799 4.914-1.393 6.865.518c1.953 1.913 2.378 4.955.528 6.768l-2.907 2.848a.75.75 0 1 1-1.05-1.072l2.907-2.848c1.07-1.048 1.022-3.106-.528-4.625M6.624 3.584a.75.75 0 1 0-1.248.832l2 3a.75.75 0 0 0 1.248-.832zM2.237 7.289a.75.75 0 1 0-.474 1.423l6 2a.75.75 0 0 0 .474-1.423zm4.491 5.217a.75.75 0 0 0-1.107-1.012l-1.261 1.38c-1.727 1.889-1.35 5.053.504 7.081c1.865 2.04 4.87 2.505 6.655.552l4.035-4.414a.75.75 0 0 0-1.107-1.012l-4.035 4.414c-1.001 1.095-2.963 1.065-4.44-.552c-1.49-1.628-1.564-3.9-.505-5.058z"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default BrokenLinkIcon;
