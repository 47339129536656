import React, { motion, AnimatePresence } from "framer-motion";
import { useModal } from "./useModal";
import styles from "./Modal.module.scss";
import { Button } from "@mui/material";
import classNames from "classnames";
import { useRef } from "react";
import useTrapFocus from "@/hooks/useTrapFocus";

function Modal() {
  const { isOpen, content, closeModal, showActions, classes } = useModal();
  const modalRef = useRef(null);

  useTrapFocus({ isOpen, handleClose: closeModal, ref: modalRef });

  const modalVariants = {
    hidden: { opacity: 0, y: "100%", x: "-50%" },
    visible: { opacity: 1, y: "-50%", x: "-50%" },
    exit: { opacity: 0, y: "100%", x: "-50%" }
  };

  const modalClasses = classNames(styles.modal, { [classes]: !!classes });

  const renderActions = () => {
    if (showActions === false) return null;

    return (
      <div className={styles.modalActions}>
        <Button
          onClick={closeModal}
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          className="super-button"
        >
          Close
        </Button>
      </div>
    );
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className={styles.overlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={closeModal}
          />
          <motion.div
            ref={modalRef}
            className={modalClasses}
            onClick={(e) => e.stopPropagation()}
            onPointerDown={(e) => e.stopPropagation()}
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={modalVariants}
            transition={{
              duration: 0.5,
              type: "spring",
              damping: 25,
              stiffness: 150
            }}
          >
            <div className={styles.modalContent}>{content}</div>

            {renderActions()}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}

export default Modal;
