import React, { useEffect, useState } from "react";
import { useAssets } from "@/contexts/assets/assets.hook";
import styles from "./BreadcrumbsTemplate.module.scss";
import { BreadcrumbType } from "@/contexts/assets/assets.types";
import useAssetRouting from "@/hooks/useAssetRouting";
import { AnimatePresence, motion } from "framer-motion";
import { breadcrumbsAnimation } from "../../data/breadcrumbs";
import classNames from "classnames";

function BreadcrumbsTemplate() {
  const { handleBreadcrumbNav } = useAssetRouting();
  const { setFolderState, folderState } = useAssets();

  const breadcrumbSpacerClasses = classNames(styles.breadcrumbSpacer, {
    [styles.grow]: folderState.currentFolder,
    [styles.shrink]: !folderState.currentFolder
  });

  const handleClick = async (breadcrumb: BreadcrumbType) => {
    await handleBreadcrumbNav(breadcrumb);
  };

  const renderBreadcrumbs = () => {
    if (folderState.currentFolder) {
      folderState.breadcrumbs[0].name = "Home";

      const crumbs = folderState.breadcrumbs.map((breadcrumb, index) => {
        return (
          <button
            onClick={() => handleClick(breadcrumb)}
            key={breadcrumb.id ?? index}
            className={styles.breadcrumb}
          >
            {breadcrumb.name}
          </button>
        );
      });

      crumbs.push(
        <span
          className={styles.currentFolder}
          key={folderState.currentFolder.id}
        >
          {folderState.currentFolder.name}
        </span>
      );

      return (
        <AnimatePresence mode="wait">
          <motion.div
            key={folderState.currentFolder?.name}
            initial="exit"
            animate="enter"
            exit="exit"
            variants={breadcrumbsAnimation}
          >
            <div className={styles.crumbsContainer}>{crumbs}</div>
          </motion.div>
        </AnimatePresence>
      );
    }

    return null;
  };

  return (
    <div className={styles.container}>
      {renderBreadcrumbs()}
      <div className={`${breadcrumbSpacerClasses}`}></div>
    </div>
  );
}

export default BreadcrumbsTemplate;
