import { ENV } from "@/config/constants";

export const isBrowser = typeof window !== "undefined";

export const isProd = ENV === "PROD" || ENV === "UAT";

export const getBaseURL = (req = null) => {
  if (isBrowser) {
    // Client-side
    return `${window.location.protocol}//${window.location.host}/`;
  } else if (req) {
    // Server-side
    const protocol = req.headers["x-forwarded-proto"] || "http";
    return `${protocol}://${req.headers.host}/`;
  } else {
    return "";
  }
};
