import React, { useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useBottomSheet } from "./useBottomSheet";
import styles from "./BottomSheet.module.scss";
import { Button } from "@mui/material";
import useTrapFocus from "@/hooks/useTrapFocus";
import classNames from "classnames";

function BottomSheet() {
  const { isOpen, content, closeBottomSheet, showActions, classes } =
    useBottomSheet();
  const bottomSheetRef = useRef(null);

  useTrapFocus({ isOpen, handleClose: closeBottomSheet, ref: bottomSheetRef });

  const bottomSheetVariants = {
    hidden: { opacity: 0, y: "100%", x: "-50%" },
    visible: { opacity: 1, y: 0, x: "-50%" },
    exit: { opacity: 0, y: "100%", x: "-50%" }
  };

  const renderActions = () => {
    if (!showActions) return null;

    return (
      <div className={styles.sheetActions}>
        <Button
          onClick={closeBottomSheet}
          color="primary"
          variant="contained"
          fullWidth
          className="super-button"
        >
          Close
        </Button>
      </div>
    );
  };

  const sheetClases = classNames(styles.sheet, { [classes]: !!classes });

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className={styles.overlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={closeBottomSheet}
          />
          <motion.div
            ref={bottomSheetRef}
            className={sheetClases}
            onClick={(e) => e.stopPropagation()}
            onPointerDown={(e) => e.stopPropagation()}
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={bottomSheetVariants}
            transition={{
              duration: 0.5,
              type: "spring",
              damping: 25,
              stiffness: 150
            }}
          >
            <div className={styles.sheetContent}>{content}</div>
            {renderActions()}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}

export default BottomSheet;
