import React from "react";
import { BaseIconProps } from "./icon.types";

function BlockIcon({ color = "white", className }: BaseIconProps) {
  const classes = className ? `${className}` : "";

  return (
    <svg
      className={classes}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="block_svgrepo.com">
        <path
          id="Vector"
          d="M12.2427 3.75736C13.3284 4.84315 14 6.34315 14 8C14 11.3137 11.3137 14 8 14C6.34315 14 4.84315 13.3284 3.75736 12.2427M12.2427 3.75736C11.1569 2.67157 9.65687 2 8 2C4.68629 2 2 4.68629 2 8C2 9.65687 2.67157 11.1569 3.75736 12.2427M12.2427 3.75736L3.75736 12.2427"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default BlockIcon;
