export const API_URL = process.env.REACT_APP_API_URL;
export const SERVICES_API_URL = process.env.REACT_APP_SERVICES_API_URL;
export const AUTHENTIC_API_URL = `${SERVICES_API_URL}/authentic/v0`;
export const AUTHENTIC_CLIENT_ID = process.env.REACT_APP_AUTHENTIC_CLIENT_ID;
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
export const MAPBOX_STYLES = process.env.REACT_APP_MAPBOX_STYLES;
export const STREAM_FPS_ASSET_ID = process.env.REACT_APP_STREAM_FPS_ASSET_ID;
export const STREAM_FPS_CERT_URL = process.env.REACT_APP_STREAM_FPS_CERT_URL;
export const STREAM_PR_PX_VALUE = process.env.REACT_APP_STREAM_PR_PX_VALUE;
export const STREAM_WV_PX_VALUE = process.env.REACT_APP_STREAM_WV_PX_VALUE;
export const ENFORCE_L1 = process.env.REACT_APP_ENFORCE_L1 === "true";
export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const ALLOW_PLAYREADY = process.env.REACT_APP_ALLOW_PLAYREADY === "true";
export const ENABLE_GEO_RESTRICTION =
  process.env.REACT_APP_ENABLE_GEO_RESTRICTION === "true";
export const REVERB_APP_KEY = process.env.REACT_APP_REVERB_APP_KEY;
export const REVERB_PORT = process.env.REACT_APP_REVERB_PORT;
export const REVERB_SCHEME = process.env.REACT_APP_REVERB_SCHEME;
// export const REVERB_HOST = process.env.REACT_APP_REVERB_HOST;
export const REVERB_PATH = process.env.REACT_APP_REVERB_PATH;
