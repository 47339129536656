import { SortOrder } from "primereact/datatable";
import {
  SortField,
  UsersSortField
} from "../FilesSuperTable/data/helpers.types";

export interface MobileSortProps {
  onSort: (e: unknown) => void;
  sortField: SortField | UsersSortField;
  sortOrder: SortOrder;
  tableType: "files" | "users";
}

export const MobileSortLabelMap: Record<string, SortField> = {
  Name: SortField.NAME,
  "File Type": SortField.TYPE,
  Date: SortField.DATE,
  "Owned By": SortField.OWNER_NAME
};

export const UserMobileSortLabelMap: Record<string, UsersSortField> = {
  User: UsersSortField.USER
};
