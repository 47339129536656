import { components } from "@/types/beta.schema";

export interface MenuActionTemplateProps {
  asset: components["schemas"]["AssetData"];
}

export enum FOLDER_ACTION_OPTIONS {
  RENAME = "Rename",
  TRANSFER = "Transfer Ownership",
  SETTINGS = "Settings",
  DELETE = "Delete"
}
