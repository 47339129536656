import React from "react";
import { useFormik } from "formik";
import { TabPanelProps } from "../index.types";
import { validationSchema } from "./data/form.data";
import styles from "../index.module.scss";
import { Button, TextField } from "@mui/material";

function PasswordTab({ value, index, ...other }: TabPanelProps) {
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      passwordConfirmation: ""
    },
    validationSchema: validationSchema,
    onSubmit: () => null
  });

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={styles.tabsContainer}>
          <div className={styles.tabContent}>
            <div className={styles.sectionTitle}>
              <span>Password</span>
            </div>
            <div className={styles.contentBody}>
              <form className={styles.form} onSubmit={formik.handleSubmit}>
                <div className={styles.inputWrapper}>
                  <label htmlFor="currentPassword">Current Password</label>
                  <TextField
                    fullWidth
                    id="currentPassword"
                    name="currentPassword"
                    type="password"
                    value={formik.values.currentPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.currentPassword &&
                      Boolean(formik.errors.currentPassword)
                    }
                    helperText={
                      formik.touched.currentPassword &&
                      formik.errors.currentPassword
                    }
                    className="text-input"
                    placeholder="Current Password"
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <label htmlFor="newPassword">New Password</label>
                  <TextField
                    fullWidth
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.newPassword &&
                      Boolean(formik.errors.newPassword)
                    }
                    helperText={
                      formik.touched.newPassword && formik.errors.newPassword
                    }
                    className="text-input"
                    placeholder="New Password"
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <label htmlFor="passwordConfirmation">
                    Password Confirmation
                  </label>
                  <TextField
                    fullWidth
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    type="password"
                    value={formik.values.passwordConfirmation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.passwordConfirmation &&
                      Boolean(formik.errors.passwordConfirmation)
                    }
                    helperText={
                      formik.touched.passwordConfirmation &&
                      formik.errors.passwordConfirmation
                    }
                    className="text-input"
                    placeholder="Password Confirmation"
                  />
                </div>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={`${styles.saveButton} super-button brand-secondary-bg text-dark`}
                >
                  Save
                </Button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PasswordTab;
