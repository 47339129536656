import { useEffect, useState } from "react";
import { getPlayerCapabilities } from "@/utils/player";
import { ENFORCE_L1 } from "@/config/constants";
import {
  IPlayerCapabilities,
  DRM_SYSTEM
} from "@/components/shared/ShakaPlayer/ShakaPlayer.interface";

const usePlayerCapabilities = () => {
  const [playerCapabilities, setPlayerCapabilities] =
    useState<IPlayerCapabilities>({
      allowPlayback: false,
      drm: DRM_SYSTEM.NODRM,
      drmError: false,
      ipAddress: "",
      isDeviceSupported: false,
      isLocationAllowed: true,
      isL1Enabled: false,
      loaded: false,
      supportedDevice: null,
      isAppleDevice: false,
      isWindowsDevice: false
    });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!playerCapabilities.loaded) {
      getPlayerCapabilities().then((response) => {
        setPlayerCapabilities((prevCapabilities) => ({
          ...prevCapabilities,
          loaded: true,
          ...response
        }));

        setIsLoading(false);
      });
    }
  }, [playerCapabilities.loaded]);

  useEffect(() => {
    setPlayerCapabilities((prevCapabilities) => {
      if (ENFORCE_L1) {
        return {
          ...prevCapabilities,
          allowPlayback:
            prevCapabilities.loaded &&
            prevCapabilities.isLocationAllowed &&
            prevCapabilities.isDeviceSupported &&
            prevCapabilities.isL1Enabled &&
            prevCapabilities.drm !== DRM_SYSTEM.NODRM
        };
      } else {
        return {
          ...prevCapabilities,
          allowPlayback: prevCapabilities.loaded
        };
      }
    });
  }, [
    ENFORCE_L1,
    playerCapabilities.loaded,
    playerCapabilities.isLocationAllowed,
    playerCapabilities.isDeviceSupported,
    playerCapabilities.isL1Enabled,
    playerCapabilities.drm
  ]);

  useEffect(() => {
    if (
      ENFORCE_L1 &&
      playerCapabilities.loaded &&
      (playerCapabilities.drm === DRM_SYSTEM.NODRM ||
        !playerCapabilities.isDeviceSupported ||
        !playerCapabilities.isL1Enabled)
    ) {
      setPlayerCapabilities((prevCapabilities) => ({
        ...prevCapabilities,
        isDeviceSupported: true
      }));
    }
  }, [
    ENFORCE_L1,
    playerCapabilities.loaded,
    playerCapabilities.drm,
    playerCapabilities.isDeviceSupported,
    playerCapabilities.isL1Enabled
  ]);

  return { playerCapabilities, isLoading };
};

export default usePlayerCapabilities;
