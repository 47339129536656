import React, { useEffect, useRef, useState } from "react";
import styles from "./FullscreenBackground.module.scss";
import { FullscreenBackgroundProps } from "./FullscreenBackground.types";
import classNames from "classnames";
import { getVideoBackgroundType, isVideo } from "@/lib/files";
import { BackgroundMode } from "../../FullscreenCardPreview.types";
import { Stack, Typography, Switch } from "@mui/material";
import { isProd } from "@/utils";
import BackButton from "@/components/BackButton/BackButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/auth/auth.hook";

function FullscreenBackground({
  user,
  viewMode,
  setViewMode,
  isPopup
}: FullscreenBackgroundProps) {
  const defaultVideoSrc = "/videos/iridescence.mp4";

  const [backgroundSrc, setBackgroundSrc] = useState(defaultVideoSrc);

  const videoRef = useRef(null);
  const navigate = useNavigate();
  const { user: loggedInUser } = useAuth();

  useEffect(() => {
    if (isPopup && loggedInUser) {
      return setBackgroundSrc(
        loggedInUser?.profile?.background ?? defaultVideoSrc
      );
    }

    if (!user?.profile?.background) {
      setBackgroundSrc(defaultVideoSrc);
    } else if (isPopup && !loggedInUser) {
      setBackgroundSrc(defaultVideoSrc);
    } else {
      setBackgroundSrc(user.profile.background);
    }
  }, [user, user?.profile?.background]);

  useEffect(() => {
    if (isVideo(backgroundSrc)) {
      videoRef.current?.load();
    }
  }, [backgroundSrc]);

  const render = () => {
    return (
      <div className={styles.backgroundVideoWrapper}>
        <div
          className={classNames(
            styles.backgroundOverlay,
            "background-video-overlay"
          )}
        ></div>
        {isVideo(backgroundSrc) ? (
          <video
            className={styles.backgroundVideo}
            autoPlay
            loop
            muted
            playsInline
            ref={videoRef}
          >
            <source
              src={backgroundSrc}
              type={getVideoBackgroundType(backgroundSrc)}
            />
          </video>
        ) : (
          <img className={styles.backgroundVideo} src={backgroundSrc} />
        )}
      </div>
    );
  };

  const testingPanelClasses = classNames(styles.testingPanel, {
    [styles.blackText]: viewMode === BackgroundMode.GALLERY,
    [styles.whiteText]: viewMode === BackgroundMode.CUSTOM
  });

  return (
    <>
      {!isProd && (
        <div className={testingPanelClasses}>
          <b>For testing only</b>
          <div className={styles.wrapper}>
            <BackButton text={"Back"} onClick={() => navigate(-1)} />
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <Typography>Custom</Typography>
              <Switch
                onChange={() =>
                  viewMode === BackgroundMode.GALLERY
                    ? setViewMode(BackgroundMode.CUSTOM)
                    : setViewMode(BackgroundMode.GALLERY)
                }
                disabled={!user?.profile?.background}
                inputProps={{ "aria-label": "controlled" }}
                checked={viewMode === BackgroundMode.GALLERY}
              />
              <Typography>Gallery</Typography>
            </Stack>
          </div>
        </div>
      )}
      {render()}
    </>
  );
}

export default FullscreenBackground;
