import { API_URL } from "@/config/constants";
import { getAccessToken } from "@/lib/network";
import createClient, { Middleware } from "openapi-fetch";
import type { paths, components } from "@/types/beta.schema";
import { GetNotificationsClientResponse } from "@/types/services/notifications-service";

//TODO: abstract out to separate files
const client = createClient<paths>({ baseUrl: `${API_URL}/api` });

const myMiddleware: Middleware = {
  async onRequest({ request }) {
    const accessToken = getAccessToken();
    if (accessToken) {
      request.headers.set("Authorization", `Bearer ${accessToken}`);
    }
    request.headers.set("Accept", "application/json");
    request.headers.set("Content-Type", "application/json");
    return request;
  }
};

client.use(myMiddleware);

export const getNotifications = async (
  type?: components["schemas"]["NotificationType"],
  page?: number
): Promise<GetNotificationsClientResponse> => {
  const { data, error, response } = await client.GET("/v1/notifications", {
    params: {
      query: {
        ...(type && {
          "filter[type]": type
        }),
        page: page ?? 1
      }
    }
  });

  return { data, error, statusCode: response.status };
};

export const markAllAsRead = async () => {
  return client.POST("/v1/notifications");
};

export const deleteAllNotifications = async () => {
  return client.DELETE("/v1/notifications");
};

export const markAsRead = async (id: string) => {
  return client.PATCH("/v1/notifications/{id}", {
    params: {
      path: {
        id: id
      }
    },
    body: {
      read: true
    }
  });
};

export const deleteNotification = async (id: string) => {
  return client.DELETE("/v1/notifications/{id}", {
    params: {
      path: {
        id: id
      }
    }
  });
};
