import React from "react";
import styles from "./index.module.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Step2() {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.dialog}>
        <div className="title">All done</div>
        <div className="description">Your password has been reset.</div>
      </div>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        className="super-button"
        onClick={() => navigate("/")}
      >
        Sign In
      </Button>
    </div>
  );
}

export default Step2;
