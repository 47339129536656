import React, { useState } from "react";
import styles from "./RequestAccessPrompt.module.scss";
import { getUserProfileImage } from "@/lib/user";
import { motion } from "framer-motion";
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import { validationSchema } from "../fullscreen/fullscreen-card-preview/data/form.data";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { requestAccess } from "@/services/asset-service";
import { useAuth } from "@/contexts/auth/auth.hook";
import classNames from "classnames";
import { RequestAccessPromptProps } from "./RequestAccessPrompt.types";

const RequestAccessPrompt = ({ assetToRequest }: RequestAccessPromptProps) => {
  const { addToast } = useSuperToast();
  const [submitted, setSubmitted] = useState(false);
  const [touched, setTouched] = useState(false);
  const { user: loggedInUser } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema,
    onSubmit: async () => {
      return;
    }
  });

  const handleAccessRequest = async () => {
    if (!loggedInUser && !formik.values.email) {
      setTouched(true);
      return;
    }
    setSubmitted(true);
    const accessResponse = loggedInUser
      ? await requestAccess(assetToRequest.id)
      : await requestAccess(assetToRequest.id, formik.values.email);
    if (accessResponse.data?.message === "Requested asset") {
      addToast("Request sent!", ToastTypes.SUCCESS);
    } else {
      addToast("There was an error sending your request.", ToastTypes.FAIL);
      setSubmitted(false);
    }
  };

  if (!assetToRequest) return;
  return (
    <motion.div
      key="noAccess"
      className={styles.noAccessFullscreen}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transitionEnd: { display: "none" } }}
      transition={{ duration: 0.5 }}
    >
      <span className={styles.fileInfo}>
        {`${assetToRequest.owner.username} / ${assetToRequest.name}`}
      </span>
      <img
        src={getUserProfileImage(assetToRequest.owner?.profile?.avatar)}
        alt="User Profile"
      />
      {touched && !loggedInUser && (
        <motion.div
          key="email-field"
          className={styles.noAccessEmailField}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transitionEnd: { display: "none" } }}
          transition={{ duration: 0.5 }}
        >
          <TextField
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            className={styles.emailInput}
            label="Email"
            disabled={submitted}
            autoFocus
          />
        </motion.div>
      )}
      <Button
        className={classNames(
          "super-button",
          "brand-secondary",
          styles.requestAccessButton
        )}
        onClick={handleAccessRequest}
        disabled={submitted}
      >
        {submitted ? "Request sent" : "Request Access"}
      </Button>
    </motion.div>
  );
};

export default RequestAccessPrompt;
