import React, { useEffect } from "react";
import { TabPanelProps } from "../index.types";
import styles from "../index.module.scss";
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import { validationSchema } from "./data/form.data";
import { useAuth } from "@/contexts/auth/auth.hook";
import { updateUser } from "@/services/users-service";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { components } from "@/types/beta.schema";

function MyDetailsTab({ value, index, ...other }: TabPanelProps) {
  const { user, setUser } = useAuth();
  const { addToast } = useSuperToast();

  useEffect(() => {
    const { email, username } = user;

    formik.setFieldValue("email", email);
    formik.setFieldValue("username", username);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      username: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload: components["schemas"]["UpdateUserData"] = {
        // TODO: Readd this when email changing is reenabled
        // email: values.email,
        username: values.username
      };

      const { data, statusCode } = await updateUser(user.id, payload);

      if (statusCode !== 200) {
        addToast("There was an error updating your profile", ToastTypes.FAIL);
      } else {
        await formik.setFieldValue("email", data.data.email);
        await formik.setFieldValue("username", data.data.username);

        setUser((prevUser) => ({
          ...prevUser,
          email: data.data.email,
          username: data.data.username
        }));
        addToast("Profile updated successfully", ToastTypes.SUCCESS);
      }
    }
  });

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={styles.tabsContainer}>
          <div className={styles.tabContent}>
            <div className={styles.sectionTitle}>
              <span>Your details</span>
            </div>
            <div className={styles.contentBody}>
              <form className={styles.form} onSubmit={formik.handleSubmit}>
                <div className={styles.inputWrapper}>
                  <label htmlFor="email">Email</label>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    className="text-input"
                    placeholder="Email"
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <label htmlFor="username">username</label>
                  <TextField
                    fullWidth
                    id="username"
                    name="username"
                    type="text"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                    className="text-input"
                    placeholder="Username"
                  />
                </div>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={`${styles.button} super-button brand-secondary`}
                >
                  Save
                </Button>
              </form>
            </div>
          </div>

          <div className={styles.tabContent}>
            <div className={styles.sectionTitle}>
              <span>Disable</span>
            </div>
            <div
              className={`${styles.contentBody} ${styles.disableAccontWrapper}`}
            >
              <span className={styles.disableTitle}>Disable Account</span>
              <span className={styles.disableSubtext}>
                Once you disable your account, you will be able to delete your
                account.
              </span>
              <Button
                size="small"
                color="primary"
                variant="contained"
                type="button"
                className={`super-button ${styles.disableAccountButton}`}
              >
                Disable Account
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyDetailsTab;
