import React, { useState, useEffect, useMemo } from "react";
import ActivityItem from "../ActivityItem/ActivityItem.component";
import { useAssets } from "@/contexts/assets/assets.hook";
import { components } from "@/types/beta.schema";
import { isApiErrorResponse } from "@/lib/error";
import styles from "./ActivityLog.module.scss";
import { ActivityLogProps } from "./ActivityLog.types";
import { paginatedQuery } from "@/components/screens/Dashboard/partials/SuperTables/FilesSuperTable/data/helpers";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import { usePreviewPanel } from "@/components/shared/asset-previews/preview-panel/usePreviewPanel";
import { LinkDto, MetaDto } from "@/types/network";

const ActivityLog: React.FC<ActivityLogProps> = ({
  assetResource,
  isShortcut,
  onClick
}) => {
  const [activityList, setActivityList] = useState<
    Array<components["schemas"]["ActivityData"]>
  >([]);
  const [meta, setMeta] = useState<MetaDto>(null);
  const [links, setLinks] = useState<Array<LinkDto>>(null);

  const { activityLog } = useAssets();
  const { previewPanelRef } = usePreviewPanel();

  const assetId = isShortcut ? assetResource.asset_id : assetResource.id;

  useEffect(() => {
    window.Echo.private(`asset.${assetId}.manager`).listen(
      "ActivityLogged",
      (activity) => {
        setActivityList((prev) => {
          const merged = [activity, ...prev];

          const uniqueActivityLog = Array.from(
            new Map(merged.map((item) => [item.id, item])).values()
          );

          return uniqueActivityLog;
        });
      }
    );

    return () => {
      window.Echo.leave(`asset.${assetId}.manager`);
    };
  }, [activityLog]);

  const page = useMemo(
    () => paginatedQuery(links ? links[links?.length - 1]?.url : undefined),
    [links]
  );

  const fetchActivityLog = async () => {
    const activityResponse = await activityLog(assetId, { page });

    if (isApiErrorResponse(activityResponse)) {
      console.error(activityResponse.message);
    } else {
      if (activityList.length < meta?.total) {
        setActivityList((prev) => {
          const merged = [...prev, ...activityResponse.data];

          const uniqueActivityLog = Array.from(
            new Map(merged.map((item) => [item.id, item])).values()
          );

          return uniqueActivityLog;
        });
      } else {
        setActivityList(activityResponse.data);
      }
      setMeta(activityResponse.meta);
      setLinks(activityResponse.links);
    }
  };

  useEffect(() => {
    fetchActivityLog();
  }, [assetId]);

  useInfiniteScroll({
    callback: () => fetchActivityLog(),
    wait: 250,
    threshold: 150,
    nextPage: links ? links[links?.length - 1]?.url : undefined,
    scrollContainerRef: previewPanelRef
  });

  return (
    <div className={styles.logContainer}>
      <h3 className={styles.sectionTitle}>Activity</h3>
      <ul className={styles.logs}>
        {activityList.map((log) => (
          <ActivityItem key={log.id} activityItem={log} onClick={onClick} />
        ))}
      </ul>
    </div>
  );
};

export default ActivityLog;
