import React, { useState, useEffect } from "react";
import PreviewModalContext from "./PreviewModal.context";
import {
  PreviewModalContextType,
  ModalProviderProps,
  OpenModalProps,
  AssetInfoState
} from "./PreviewModal.types";

function PreviewModalProvider({ children }: ModalProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [assetInfo, setAssetInfo] = useState<null | AssetInfoState>(null);
  const [isClosing, setIsClosing] = useState(false);

  const openModal = (params: OpenModalProps) => {
    setIsOpen(true);
    setAssetInfo({
      ownerName: params.ownerName,
      assetName: params.assetName,
      assetId: params.assetId
    });
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const closeModal = () => {
    setIsClosing(true); // Trigger blocker fade-in
    setTimeout(() => {
      setAssetInfo(null);
      setIsOpen(false);
      setIsClosing(false); // Reset for next open
    }, 500); // Match the blocker fade-in duration
  };

  const value: PreviewModalContextType = {
    isOpen,
    openModal,
    closeModal,
    assetInfo,
    isClosing,
    setIsClosing
  };

  return (
    <PreviewModalContext.Provider value={value}>
      {children}
    </PreviewModalContext.Provider>
  );
}

export default PreviewModalProvider;
