export interface BaseIconProps {
  color?: string;
  className?: string;
  onClick?: () => void;
}

export interface IconConfig {
  color?: string;
  name: IconName;
}

export enum IconName {
  FRIENDS_ICON = "FRIENDS_ICON",
  MESSAGE_SQUARE_ICON = "MESSAGE_SQUARE_ICON",
  VIDEO_CHAT_ICON = "VIDEO_CHAT_ICON",
  REPORT_ICON = "REPORT_ICON",
  BLOCK_ICON = "BLOCK_ICON"
}
