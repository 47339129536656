import React from "react";
import { AuthProvider } from "@/contexts//auth/auth.provider";
import { ProfileProvider } from "@/contexts//profile/profile.provider";
import { AssetsProvider } from "@/contexts//assets/assets.provider";
import { UsersProvider } from "@/contexts/users/users.provider";
import { PlayerProvider } from "@/contexts/player/player.provider";
import { ScreenProvider } from "@/contexts/screen/screen.provider";
import { MapProvider } from "react-map-gl";
import { ModalProvider } from "@/components/shared/Modal/Modal.provider";
import BottomSheetProvider from "@/components/shared/BottomSheet/BottomSheet.provider";
import PreviewPanelProvider from "@/components/shared/asset-previews/preview-panel/PreviewPanel.provider";
import { SuperToastProvider } from "@/components/shared/super-toast/SuperToast.provider";
import { PrimeReactProvider } from "primereact/api";
import Modal from "@/components/shared/Modal/Modal.component";
import BottomSheet from "@/components/shared/BottomSheet/BottomSheet.component";
import PreviewModalProvider from "@/components/shared/asset-previews/preview-modal/PreviewModal.provider";
import PreviewModal from "@/components/shared/asset-previews/preview-modal/PreviewModal.component";
import { SharedProviderProps } from "@/types/contexts/shared-context";

const SharedProviders = ({ children }: SharedProviderProps) => {
  return (
    <AuthProvider>
      <SuperToastProvider>
        <PrimeReactProvider>
          <ScreenProvider>
            <PlayerProvider>
              <ProfileProvider>
                <AssetsProvider>
                  <UsersProvider>
                    <ModalProvider>
                      <BottomSheetProvider>
                        <MapProvider>
                          <PreviewPanelProvider>
                            <PreviewModalProvider>
                              {children}
                              <Modal />
                              <BottomSheet />
                              <PreviewModal />
                            </PreviewModalProvider>
                          </PreviewPanelProvider>
                        </MapProvider>
                      </BottomSheetProvider>
                    </ModalProvider>
                  </UsersProvider>
                </AssetsProvider>
              </ProfileProvider>
            </PlayerProvider>
          </ScreenProvider>
        </PrimeReactProvider>
      </SuperToastProvider>
    </AuthProvider>
  );
};

export default SharedProviders;
