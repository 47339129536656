import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import Header from "@/components/Header/Header.component";
import { Container } from "@mui/material";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { useCurrentScreen } from "@/contexts/screen/screen.hook";
import { SCREEN_TYPES } from "@/types/contexts/screen-context";
import Login from "@/components/screens/Login/Login.component";
import Dashboard from "@/components/screens/Dashboard/Dashboard.component";
import Logo from "@/components/screens/Logo/Logo.component";
import ProfileSectionTemplate from "@/components/screens/Profile/partials/profile-section-template/ProfileSectionTemplate.component";
import AccountBody from "@/components/screens/Account/tabs/AccountBody";
import { useAssets } from "@/contexts/assets/assets.hook";
import { useAuth } from "@/contexts/auth/auth.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";

import { getUserInfo } from "@/services/users-service";
import { useProfile } from "@/contexts/profile/profile.hook";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { getAssetByOwner } from "@/services/asset-service";
import {
  getAssetPathFromPath,
  getAssetNameFromPath,
  getOwnerNameFromPath
} from "@/lib/files";
import { isApiErrorResponse } from "@/lib/error";
import { NewViewingProfile, RefreshParams } from "./PageLayout.types";
import FullscreenAnalogPreview from "@/components/shared/asset-previews/fullscreen/fullscreen-analog-preview/FullscreenAnalogPreview.component";

// Within this pagelayout we are only working with "/" "/username/*" and "/account".
//  All other routes are handled in App.tsx
const PageLayout = () => {
  const {
    currentScreen,
    setCurrentScreen,
    currentScreenProfile,
    setCurrentScreenProfile
  } = useCurrentScreen();
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const { refresh, getAssetDetails } = useAssets();
  const { setViewingProfile, viewingProfile } = useProfile();
  const location = useLocation();
  const { setFolderState } = useAssets();
  const { addToast } = useSuperToast();

  const [screenToRender, setScreenToRender] = useState<SCREEN_TYPES>(null);

  const { user } = useAuth();

  const retrieveViewingProfile = useCallback(
    async (profileName: string, ownerName: string) => {
      if (profileName === ownerName) return null;
      try {
        const { data, statusCode } = await getUserInfo(profileName);

        if (statusCode !== 200) {
          setViewingProfile(null);
          addToast("User not found", ToastTypes.FAIL);
          setCurrentScreen(
            user ? SCREEN_TYPES.DASHBOARD_ROOT : SCREEN_TYPES.LOGO
          );
          return;
        }

        return data.data;
      } catch (error) {
        console.error(error);
        addToast("User not found", ToastTypes.FAIL);
        setCurrentScreen(
          user ? SCREEN_TYPES.DASHBOARD_ROOT : SCREEN_TYPES.LOGO
        );
      }
    },
    [getUserInfo]
  );

  useEffect(() => {
    // Set currentScreen based on current pathname. Handles direct links + browser navigation buttons
    const setScreenFromPath = async () => {
      const path = location.pathname;

      if (path === "/") {
        return setCurrentScreen(
          user ? SCREEN_TYPES.DASHBOARD_ROOT : SCREEN_TYPES.LOGO
        );
      }
      if (path == "/account") {
        return setCurrentScreen(SCREEN_TYPES.ACCOUNT);
      }

      // Hidden route for testing (go to specific assetId)
      if (path.startsWith("/asset/")) {
        const asset_id = getAssetNameFromPath(path);
        const assetDetails = await getAssetDetails(asset_id);
        if (!isApiErrorResponse(assetDetails)) {
          if (assetDetails.type !== "folder") {
            const asset_owner = assetDetails.owner?.username;
            const asset_name = assetDetails.name;
            navigate(`/${asset_owner}/${asset_name}`);
            return setCurrentScreen(SCREEN_TYPES.FULLSCREEN_ANALOG_PREVIEW);
          }
        }
        addToast(
          "Invalid url, specified asset does not exist",
          ToastTypes.FAIL
        );
        return setCurrentScreen(
          user ? SCREEN_TYPES.DASHBOARD_ROOT : SCREEN_TYPES.LOGO
        );
      }

      const pathOwner = getOwnerNameFromPath(path);
      const assetPath = getAssetPathFromPath(path);
      const userIsOwner = pathOwner === user?.username;

      if (userIsOwner) {
        const atRootFolder = path === `/${user?.username}`;
        let folder_id = null;
        if (!atRootFolder) {
          const { data: incomingAssetDetails } = await getAssetByOwner(
            pathOwner,
            assetPath
          );
          if (!incomingAssetDetails) {
            addToast(
              "Invalid url, specified asset does not exist",
              ToastTypes.FAIL
            );
            return setCurrentScreen(SCREEN_TYPES.DASHBOARD_ROOT);
          }
          if (!(incomingAssetDetails.type === "folder")) {
            return setCurrentScreen(SCREEN_TYPES.FULLSCREEN_ANALOG_PREVIEW);
          }
          folder_id = incomingAssetDetails.id;
        }
        setCurrentScreenProfile({
          owner_id: user?.id,
          path,
          username: user?.username,
          folder_id
        });

        return setCurrentScreen(SCREEN_TYPES.DASHBOARD_PROFILE);
      } else {
        // If we reach this point, we are viewing a profile (not the user's)
        let localViewingProfile = null;
        if (!viewingProfile || viewingProfile.username !== pathOwner) {
          const profileUsername = `${location.pathname.split("/")[1]}`;
          localViewingProfile = await retrieveViewingProfile(
            profileUsername,
            user?.username
          );
          if (!localViewingProfile)
            return setCurrentScreen(
              user ? SCREEN_TYPES.DASHBOARD_ROOT : SCREEN_TYPES.LOGO
            );
        } else {
          localViewingProfile = viewingProfile;
        }

        const isUserProfilePath = path === `/${localViewingProfile.username}`;
        const isUserAssetPath = path.startsWith(
          `/${localViewingProfile.username}/`
        );

        if (isUserProfilePath || isUserAssetPath) {
          const updatedProfile = {
            owner_id: localViewingProfile.id,
            path,
            username: localViewingProfile.username
          };

          if (isUserProfilePath) {
            setCurrentScreenProfile(updatedProfile);
            return setCurrentScreen(SCREEN_TYPES.PROFILE);
          }

          const { data: incomingAssetDetails } = await getAssetByOwner(
            pathOwner,
            assetPath
          );
          if (!incomingAssetDetails) {
            addToast(
              "Invalid url, specified asset does not exist or you do not have access",
              ToastTypes.FAIL
            );
            return setCurrentScreen(
              user ? SCREEN_TYPES.DASHBOARD_ROOT : SCREEN_TYPES.LOGO
            );
          }

          if (incomingAssetDetails.type !== "folder") {
            return setCurrentScreen(SCREEN_TYPES.FULLSCREEN_ANALOG_PREVIEW);
          }

          setCurrentScreenProfile({
            ...updatedProfile,
            folder_id: incomingAssetDetails.id
          });
          return setCurrentScreen(SCREEN_TYPES.PROFILE);
        }
      }
    };

    if (navigationType === "POP") {
      setScreenFromPath();
    }
  }, [navigationType, location]);

  useEffect(() => {
    const handleNavigation = async () => {
      if (!currentScreen) return;

      let refreshParams: RefreshParams = null;
      let newNavPath: string = null;
      // "setEmpty" will clear the profile, vs null which will make no changes
      let newViewingProfile: NewViewingProfile = null;
      let resetFolderState = false;

      switch (currentScreen) {
        case SCREEN_TYPES.LOGO:
        case SCREEN_TYPES.LOGIN:
          newNavPath = "/";
          break;

        case SCREEN_TYPES.DASHBOARD_ROOT:
          refreshParams = { owner_id: user.id };
          newViewingProfile = "setEmpty";
          resetFolderState = true;
          newNavPath = "/";
          break;

        case SCREEN_TYPES.ACCOUNT:
          newNavPath = "/account";
          break;

        case SCREEN_TYPES.DASHBOARD_PROFILE:
          if (currentScreenProfile) {
            newViewingProfile = "setEmpty";

            refreshParams = {
              owner_id: currentScreenProfile.owner_id,
              folder_id: currentScreenProfile.folder_id
            };

            if (currentScreenProfile.path === currentScreenProfile.username) {
              resetFolderState = true;
            }

            newNavPath = currentScreenProfile.path;
          }
          break;

        case SCREEN_TYPES.PROFILE:
          if (currentScreenProfile) {
            newViewingProfile = await retrieveViewingProfile(
              currentScreenProfile.username,
              user?.username
            );
            if (!currentScreenProfile.folder_id) {
              resetFolderState = true;
            }

            refreshParams = {
              owner_id: currentScreenProfile.owner_id,
              folder_id: currentScreenProfile.folder_id
            };

            newNavPath = currentScreenProfile.path;
          }
          break;

        default:
          return;
      }

      // After properties set based on screen_type, need to refresh assets, set folder state +
      //  viewing profile and finally navigate to the new route and render that screen

      if (refreshParams)
        await refresh(
          {
            folder_id: refreshParams.folder_id,
            owner_id: refreshParams.owner_id
          },
          true
        );

      setScreenToRender(currentScreen);
      if (resetFolderState)
        setFolderState({
          currentFolder: null,
          breadcrumbs: []
        });
      if (newNavPath && location.pathname !== newNavPath) navigate(newNavPath);
      if (newViewingProfile)
        setViewingProfile(
          newViewingProfile === "setEmpty" ? null : newViewingProfile
        );
    };

    handleNavigation();
  }, [currentScreen, currentScreenProfile]);

  const renderContent = () => {
    switch (screenToRender) {
      case SCREEN_TYPES.LOGIN:
        return (
          <Login
            goToLogoScreen={() => setCurrentScreen(SCREEN_TYPES.LOGO)}
            goToDashboardScreen={() =>
              setCurrentScreen(SCREEN_TYPES.DASHBOARD_ROOT)
            }
          />
        );
      case SCREEN_TYPES.DASHBOARD_ROOT:
      case SCREEN_TYPES.DASHBOARD_PROFILE:
        return (
          <>
            <Header />
            <Dashboard />
          </>
        );

      case SCREEN_TYPES.PROFILE:
        return (
          <>
            <Header />
            <ProfileSectionTemplate />
          </>
        );
      case SCREEN_TYPES.ACCOUNT:
        return (
          <>
            <Header />
            <AccountBody />
          </>
        );
      case SCREEN_TYPES.LOGO:
        return <Logo onClick={() => setCurrentScreen(SCREEN_TYPES.LOGIN)} />;

      default:
        return <Logo onClick={() => setCurrentScreen(SCREEN_TYPES.LOGIN)} />;
    }
  };

  // Note: FullscreenPreview has to be outside of the renderContent() as it needs to exist outside
  // of the container to be formatted correctly
  if (currentScreen === SCREEN_TYPES.FULLSCREEN_ANALOG_PREVIEW) {
    return <FullscreenAnalogPreview />;
  }
  return <Container className="page-layout">{renderContent()}</Container>;
};

export default PageLayout;
