import React, { useCallback, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import {
  getSharedUsersOnAssetsList,
  unshareAllFilesWithUser
} from "@/services/users-service";
import { components } from "@/types/beta.schema";
import { Column } from "primereact/column";
import styles from "./UsersSuperTable.module.scss";
import superTableStyles from "../SuperTables.module.scss";
import {
  faArrowAltCircleUp,
  faBackspace
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { motion } from "framer-motion";
import useResponsive from "@/hooks/useResponsive";
import { useBottomSheet } from "@/components/shared/BottomSheet/useBottomSheet";
import { UsersSortField } from "../FilesSuperTable/data/helpers.types";
import { SortOrder } from "primereact/datatable";
import { useUsers } from "@/contexts/users/users.hook";
import MobileSort from "../MobileSort/MobileSort.component";
import { useCurrentScreen } from "@/contexts/screen/screen.hook";
import { SCREEN_TYPES } from "@/types/contexts/screen-context";
import { SuperTableProps } from "@/components/screens/Dashboard/partials/SuperTables/SuperTables.types";

function UsersSuperTable({ tableHeader, mobileSortState }: SuperTableProps) {
  const { mobileSortTrigger, setMobileSortTrigger } = mobileSortState;
  const { openBottomSheet, isOpen: mobileSortOpen } = useBottomSheet();
  const { setCurrentScreen, setCurrentScreenProfile } = useCurrentScreen();

  const [selectedUsers, setSelectedUsers] = useState<
    Array<components["schemas"]["UserData"]>
  >([]);

  const { users, setUsers } = useUsers();

  const [sortField, setSortField] = useState<UsersSortField>(
    UsersSortField.USER
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>(-1);

  const { addToast } = useSuperToast();
  const { isDesktop } = useResponsive();

  const getUsers = useCallback(async (showToast = true) => {
    const { data, error, statusCode } = await getSharedUsersOnAssetsList();

    if (statusCode !== 200) {
      if (showToast) {
        addToast(`There was an error fetching the user list`, ToastTypes.FAIL);
      }
      return error;
    } else {
      setUsers(data.data);
    }
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const filteredUsers = () => {
    if (!users || users?.length === 0) {
      return [];
    }

    return users;
  };

  const usernameTemplate = (rowData: components["schemas"]["UserData"]) => {
    const avatar = rowData.profile?.avatar;

    return (
      <div
        className={styles.userCell}
        onClick={() => {
          setCurrentScreenProfile({
            owner_id: `${rowData.id}`,
            path: `${rowData.username}`,
            username: `${rowData.username}`
          });
          setCurrentScreen(SCREEN_TYPES.PROFILE);
        }}
      >
        <img src={avatar ?? "/avatars/0.png"} />
        <span className={styles.userName}>{rowData.username}</span>
      </div>
    );
  };

  const numOfAssetsTemplate = (rowData: components["schemas"]["UserData"]) => {
    const afterNumText =
      Number(rowData.share_count) > 1 ? "files shared" : "file shared";

    return (
      <div className={styles.shareCount}>
        {rowData.share_count} {afterNumText}
      </div>
    );
  };

  const handleUnshareAll = async ({
    userId,
    username,
    showToast = true
  }: {
    userId: string;
    username: string;
    showToast?: boolean;
  }) => {
    const { error, statusCode } = await unshareAllFilesWithUser(userId);

    if (statusCode !== 200) {
      if (showToast) {
        addToast(
          `There was an error unsharing all assets with ${username}`,
          ToastTypes.FAIL
        );
      }
      return error;
    } else {
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      setSelectedUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== userId)
      );
      if (showToast) {
        addToast(
          `Successsfully unshared all assets with ${username}`,
          ToastTypes.SUCCESS
        );
      }
      return statusCode;
    }
  };

  const handleBulkUnshareAll = async () => {
    let allSuccessful = true;
    const errorMessage = [];

    await Promise.all(
      selectedUsers.map(async (user) => {
        const statusCode = await handleUnshareAll({
          userId: user.id,
          username: user.username,
          showToast: false
        });

        if (statusCode !== 200) {
          allSuccessful = false;
          errorMessage.push(user.username);
        }
      })
    );

    if (allSuccessful) {
      addToast(
        `Successfully unshared with ${selectedUsers.length} ${selectedUsers.length > 1 ? "users" : "user"}`,
        ToastTypes.SUCCESS
      );
    } else {
      addToast(
        `There was an error unsharing assets from ${errorMessage.join(", ")}`,
        ToastTypes.FAIL
      );
    }
  };

  const actionsTemplate = (rowData: components["schemas"]["UserData"]) => {
    return (
      <div className={styles.actionsCell}>
        <div
          className={styles.viewProfileButton}
          onClick={() => {
            setCurrentScreenProfile({
              owner_id: `${rowData.id}`,
              path: `${rowData.username}`,
              username: `${rowData.username}`
            });
            setCurrentScreen(SCREEN_TYPES.PROFILE);
          }}
        >
          View profile
        </div>
        <button
          onClick={() =>
            handleUnshareAll({ userId: rowData.id, username: rowData.username })
          }
        >
          Unshare all
        </button>
      </div>
    );
  };

  const bulkActionsTemplate = (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.2,
        scale: { type: "spring", visualDuration: 0.2, bounce: 0 }
      }}
      className={styles.bulkActions}
    >
      <button
        className={styles.unshareAllButton}
        onClick={handleBulkUnshareAll}
      >
        <FontAwesomeIcon icon={faBackspace} size={"lg"} />
        Unshare assets with {selectedUsers.length}{" "}
        {selectedUsers.length > 1 ? "users" : "user"}
      </button>
    </motion.div>
  );

  const emptyMessageTemplate = (
    <div className={superTableStyles.emptyMessage}>
      <strong>You have not shared any files</strong>
      <span>
        Start securing files above
        <FontAwesomeIcon icon={faArrowAltCircleUp} size={"lg"} />
      </span>
    </div>
  );
  //While mobile sort is open, allow it to rerender
  //When it closes, only the sort button in dashboard can render it again
  useEffect(() => {
    if (mobileSortTrigger) setMobileSortTrigger(mobileSortOpen);
  }, [mobileSortOpen]);

  useEffect(() => {
    if (mobileSortTrigger) {
      openBottomSheet({
        content: (
          <MobileSort
            onSort={onSort}
            sortField={sortField}
            sortOrder={sortOrder}
            tableType={"users"}
          />
        ),
        showActions: false
      });
    }
  }, [mobileSortTrigger, sortOrder, sortField]);

  const onSort = (e) => {
    if (e.sortOrder === sortOrder && e.sortField === sortField) {
      return;
    }
    setSortOrder(e.sortOrder);
    setSortField(e.sortField);
  };

  return (
    <div className={superTableStyles.glassPane}>
      <DataTable
        value={filteredUsers()}
        stripedRows={isDesktop}
        rows={25}
        dataKey="id"
        header={tableHeader}
        showHeaders={isDesktop}
        removableSort
        selectionMode={"checkbox"}
        selection={selectedUsers}
        onSelectionChange={(e) => setSelectedUsers(e.value)}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSort}
        emptyMessage={emptyMessageTemplate}
        className={superTableStyles.datatable}
      >
        {isDesktop && (
          <Column
            selectionMode="multiple"
            headerClassName={styles.selectionWrapper}
          ></Column>
        )}
        <Column
          field="username"
          header="User"
          sortable
          sortField="username"
          body={usernameTemplate}
          headerClassName={styles.userNameWrapper}
        />
        <Column
          field="numOfAssets"
          header="Files"
          body={numOfAssetsTemplate}
          headerClassName={styles.numOfAssetsWrapper}
        />
        <Column
          field="actions"
          header={selectedUsers.length > 0 && bulkActionsTemplate}
          body={actionsTemplate}
          headerClassName={styles.actionsWrapper}
          className={styles.actionsRow}
        />
      </DataTable>
    </div>
  );
}

export default UsersSuperTable;
