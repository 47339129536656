import React, { useState, useEffect, useMemo, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./Header.module.scss";
import useResponsive from "@/hooks/useResponsive";
import { Button } from "@mui/material";
import { useBottomSheet } from "@/components/shared/BottomSheet/useBottomSheet";
import { useAuth } from "@/contexts/auth/auth.hook";
import { useProfile } from "@/contexts/profile/profile.hook";
import NotificationsMenu from "./partials/NotificationsMenu/NotificationsMenu";
import { useAssets } from "@/contexts/assets/assets.hook";
import classNames from "classnames";
import { useCurrentScreen } from "@/contexts/screen/screen.hook";
import { SCREEN_TYPES } from "@/types/contexts/screen-context";

function Header() {
  const { isMobile } = useResponsive();
  const [menuOpen, setMenuOpen] = useState(false);
  const userPopupMenuRef = useRef(null);
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const { signOut, user } = useAuth();
  const { getAvatar, setViewingProfile } = useProfile();
  const { setFolderState, refresh } = useAssets();
  const { setCurrentScreen } = useCurrentScreen();

  const avatar = useMemo(() => getAvatar(), [user]);

  const handleMenuToggle = () => {
    if (isMobile) {
      return openBottomSheet({
        content: (
          <div className={styles.mobileMenuContainer}>
            {renderMenuContent()}
          </div>
        ),
        showActions: false
      });
    }

    return setMenuOpen((prev) => !prev);
  };

  const handleSignOutClick = () => {
    closeBottomSheet();
    signOut();
    setCurrentScreen(SCREEN_TYPES.LOGO);
  };

  const handleClickOutside = (event) => {
    if (
      !event.target.closest(`.${styles.menuContainer}`) &&
      !event.target.closest(`.${styles.userProfile}`)
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => document.removeEventListener("click", handleClickOutside);
  }, [menuOpen]);

  const handleManageAccountClick = () => {
    setCurrentScreen(SCREEN_TYPES.ACCOUNT);
    setMenuOpen(false);
    if (isMobile) {
      return closeBottomSheet();
    }
  };

  const renderMenuContent = () => {
    return (
      <>
        <div className={styles.menuHeader}>
          <span>{user.email}</span>
          <span>Managed by superfile.com</span>
        </div>
        <div className={styles.userInfo}>
          <img
            src={avatar}
            alt="User Profile"
            className="round-image-with-border"
          />
          <span
            className={styles.username}
          >{`Hello, ${user.profile?.name}!`}</span>
        </div>
        <div className={styles.menuFooter}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            className={`${styles.manageAccountButton} super-button brand-secondary`}
            onClick={handleManageAccountClick}
            size="small"
          >
            Manage your account
          </Button>
        </div>
        <button className={styles.signOutLink} onClick={handleSignOutClick}>
          Sign out
        </button>
      </>
    );
  };

  return (
    <div className={styles.header}>
      <div
        onClick={async () => {
          setCurrentScreen(
            user ? SCREEN_TYPES.DASHBOARD_ROOT : SCREEN_TYPES.LOGO
          );
        }}
      >
        <img
          className={styles.headerLogo}
          src="/images/SuperSecureCombomark.svg"
          alt="Logo"
        />
      </div>
      {user ? (
        <div className={styles.userProfile}>
          <NotificationsMenu />
          <button onClick={handleMenuToggle}>
            <img
              src={avatar}
              alt="User Profile"
              className="round-image-with-border"
            />
          </button>
          <AnimatePresence>
            {menuOpen && (
              <motion.div
                ref={userPopupMenuRef}
                className={styles.menuContainer}
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {renderMenuContent()}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ) : (
        <Button
          component="a"
          href="/"
          color="primary"
          variant="contained"
          className={classNames(
            "super-button",
            "brand-secondary",
            styles.loginButton
          )}
        >
          Log In
        </Button>
      )}
    </div>
  );
}

export default Header;
