import React from "react";
import { ShareAssetTemplateProps } from "./ShareAssetTemplate.types";
import { validationSchema } from "./data/form";
import { useFormik } from "formik";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { Button, TextField } from "@mui/material";
import styles from "./ShareAssetTemplate.module.scss";
import { useModal } from "@/components/shared/Modal/useModal";
import { shareAssetWithUser } from "@/services/users-service";
import { SHARED_ACCESS_TYPE } from "@/types/contexts/share-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import useResponsive from "@/hooks/useResponsive";
import { useBottomSheet } from "@/components/shared/BottomSheet/useBottomSheet";
import { useLocation } from "react-router-dom";
import { useAuth } from "@/contexts/auth/auth.hook";
import { generateAssetLink } from "@/lib/link";
import { components } from "@/types/beta.schema";

function ShareAssetTemplate({ asset }: ShareAssetTemplateProps) {
  const { id: assetId } = asset;

  const { addToast } = useSuperToast();
  const { closeModal } = useModal();
  const { closeBottomSheet } = useBottomSheet();
  const { isMobile } = useResponsive();
  const { user } = useAuth();
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema,
    onSubmit: async (values) => {
      const payload: components["schemas"]["CreateAssetShareData"] = {
        email: values.email,
        access_type: SHARED_ACCESS_TYPE.PublicNoAccess
      };

      const { error, statusCode } = await shareAssetWithUser(assetId, payload);

      if (isMobile) {
        closeBottomSheet();
      } else {
        closeModal();
      }

      if (statusCode !== 200) {
        return addToast(error.message, ToastTypes.FAIL);
      }

      addToast("Email sent", ToastTypes.SUCCESS);
    }
  });

  const handleCopy = () => {
    const username = user.username;
    const assetName = asset.name;
    const pathName = location.pathname;
    const protocol = window.location.protocol;
    const host = window.location.host;

    const assetLink = generateAssetLink(
      username,
      assetName,
      pathName,
      protocol,
      host
    );

    navigator.clipboard.writeText(assetLink);
    addToast("Your link has been copied", ToastTypes.SUCCESS);

    if (isMobile) {
      return closeBottomSheet();
    }

    return closeModal();
  };

  return (
    <div>
      <div className={styles.header}>
        <span className="title">Share Asset</span>
        <button className={styles.copyLinkButton} onClick={handleCopy}>
          <FontAwesomeIcon icon={faCopy} size={"lg"} />
          <span>Copy link</span>
        </button>
      </div>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div className={styles.shareSection}>
          <TextField
            fullWidth
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            className="text-input"
            placeholder="Email"
          />
        </div>
        <div className={styles.actions}>
          <Button
            fullWidth
            size="small"
            color="primary"
            variant="contained"
            type="submit"
            className={`super-button brand-secondary ${styles.button}`}
          >
            {"Confirm"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ShareAssetTemplate;
