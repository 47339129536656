import React, { useState } from "react";
import ModalContext from "./Modal.context";
import {
  ModalContextType,
  ModalProviderProps,
  OpenModalProps
} from "./Modal.types";

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<React.ReactNode | null>(null);
  const [showActions, setShowActions] = useState(null);
  const [classes, setClasses] = useState(null);
  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(
    null
  );

  const openModal = (params: OpenModalProps) => {
    const { content, showActions, classes: incomingClasses } = params;

    setTriggerElement(document.activeElement as HTMLElement);
    setContent(content);
    setIsOpen(true);
    setShowActions(showActions);
    setClasses(incomingClasses);
  };

  const closeModal = () => {
    setContent(null);
    setIsOpen(false);
    triggerElement?.focus();
  };

  const value: ModalContextType = {
    isOpen,
    content,
    openModal,
    closeModal,
    showActions,
    classes
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
