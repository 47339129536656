import { getFocusableElements } from "@/lib/focusable-elements";
import { MutableRefObject, useEffect } from "react";

// TODO: Review this file! I accidentally pushed it when I did a rebase, so it didn't get a review
// OOPS!!!

interface UseTrapFocusProps {
  isOpen: boolean;
  handleClose: () => void;
  ref: MutableRefObject<HTMLElement | null>;
}

const useTrapFocus = ({ isOpen, handleClose, ref }: UseTrapFocusProps) => {
  useEffect(() => {
    if (isOpen) {
      const refElement = ref.current;

      if (!refElement) return;

      let visibleFocusableElements: Array<HTMLElement> | null;
      let firstElement: HTMLElement | undefined;
      let lastElement: HTMLElement | undefined;

      const updateFocusableElements = () => {
        visibleFocusableElements = getFocusableElements(refElement); // Recalculate focusable elements
        firstElement = visibleFocusableElements?.[0];
        lastElement =
          visibleFocusableElements?.[visibleFocusableElements.length - 1];
      };

      // Initial calculation when panel opens
      updateFocusableElements(); // Now only runs once!

      if (visibleFocusableElements.length > 0) {
        if (
          !visibleFocusableElements.includes(
            document.activeElement as HTMLElement
          )
        ) {
          firstElement.focus();
        }
      }

      const handleTabKeyPress = (event) => {
        if (event.key === "Tab") {
          if (visibleFocusableElements.length > 0) {
            if (event.shiftKey && document.activeElement === firstElement) {
              event.preventDefault();
              lastElement.focus();
            } else if (
              !event.shiftKey &&
              document.activeElement === lastElement
            ) {
              event.preventDefault();
              firstElement.focus();
            }
          }
        }
      };

      const handleEscapeKeyPress = (event) => {
        if (event.key === "Escape") {
          handleClose();
        }
      };

      const observer = new MutationObserver((mutationsList) => {
        // TODO: Prevent this from running when the MUI Ripple button is changing
        // Figure out some way for the mutation to be meaningful/significant
        for (const mutation of mutationsList) {
          if (mutation.type === "childList" || mutation.type === "attributes") {
            updateFocusableElements();
            break;
          }
        }
      });

      observer.observe(refElement, {
        childList: true, // Detect added/removed elements
        subtree: true, // Observe deep inside the panel
        attributes: true, // Detect attribute changes
        attributeFilter: ["disabled", "tabindex", "hidden", "aria-hidden"] // Track focusability
      });

      refElement.addEventListener("keydown", handleTabKeyPress);
      refElement.addEventListener("keydown", handleEscapeKeyPress);

      return () => {
        refElement.removeEventListener("keydown", handleTabKeyPress);
        refElement.removeEventListener("keydown", handleEscapeKeyPress);
        observer.disconnect();
      };
    }
  }, [isOpen, handleClose, ref]);
};

export default useTrapFocus;
