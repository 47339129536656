import React, { useState, useEffect, useCallback } from "react";
import styles from "./information-tab.module.scss";
import AssetMetadata from "./AssetMetadata/AssetMetadata.component";
import SharedWithList from "./SharedWithList/SharedWithList.component";
import AssetViews from "./AssetViews/AssetViews.component";
import {
  gifMimeTypes,
  pdfMimeTypes,
  videoMimeTypes,
  documentMimeTypes
} from "@/lib/files";
import { InformationTabProps } from "./information-tab.types";
import { getManifests } from "@/services/asset-service";
import { IStreamConfiguration } from "@/components/shared/ShakaPlayer/ShakaPlayer.interface";
import ShakaPlayer from "@/components/shared/ShakaPlayer/ShakaPlayer.component";
import { usePlayer } from "@/contexts/player/player.hook";

function InformationTab({
  assetResource,
  isShortcut,
  isOwner,
  isManager
}: InformationTabProps) {
  const [playerConfig, setPlayerConfig] = useState<IStreamConfiguration>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const assetId = isShortcut ? assetResource.asset_id : assetResource.id;
  const { playerCapabilities } = usePlayer();

  const fetchOnLoad = useCallback(async () => {
    const manifestResponse = await getManifests(assetId);

    setPlayerConfig(manifestResponse);
    setIsLoading(false);
  }, [assetId]);

  useEffect(() => {
    if (playerCapabilities.isDeviceSupported) {
      fetchOnLoad();
    }
  }, [assetId, fetchOnLoad, playerCapabilities.isDeviceSupported]);

  const showUI =
    videoMimeTypes.includes(assetResource?.mime_type) ||
    pdfMimeTypes.includes(assetResource?.mime_type);
  const autoPlay =
    videoMimeTypes.includes(assetResource?.mime_type) ||
    gifMimeTypes.includes(assetResource?.mime_type);
  const pdfControls =
    pdfMimeTypes.includes(assetResource?.mime_type) ||
    documentMimeTypes.includes(assetResource?.mime_type);

  if (!assetResource) return null;

  return (
    <div className={styles.informationTabWrapper}>
      <AssetMetadata
        assetResource={assetResource}
        isShortcut={isShortcut}
        isOwner={isOwner}
      />
      {/* TODO: This does not look nice */}
      {isLoading && playerCapabilities.isDeviceSupported ? (
        <div>Loading...</div>
      ) : playerCapabilities.isDeviceSupported ? (
        <ShakaPlayer
          autoPlay={autoPlay}
          showUI={showUI}
          config={playerConfig}
          loop={autoPlay}
          muted
          pdfControls={pdfControls}
          assetId={assetId}
        />
      ) : (
        <div className={styles.unsupportedBrowserText}>
          Viewing SuperFiles in this browser is not currently supported
        </div>
      )}
      <AssetViews assetResource={assetResource} isShortcut={isShortcut} />
      {(isOwner || isManager) && (
        <SharedWithList assetResource={assetResource} isShortcut={isShortcut} />
      )}
    </div>
  );
}

export default InformationTab;
