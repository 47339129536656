import { isProd } from "@/utils";
import {
  checkDeviceSupport,
  fetchDeviceValidation,
  getDeviceInformation
} from "@/utils/device";
import {
  ALLOW_PLAYREADY,
  STREAM_FPS_CERT_URL,
  STREAM_PR_PX_VALUE,
  STREAM_WV_PX_VALUE,
  STREAM_FPS_ASSET_ID
} from "@/config/constants";
import {
  SupportedDRMConfigurations,
  DRM_SYSTEM,
  IStreamConfiguration
} from "@/components/shared/ShakaPlayer/ShakaPlayer.interface";
import { getAccessToken } from "@/lib/network";

const widevineLaUrl = `https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=${STREAM_WV_PX_VALUE}`;
const playreadyLaUrl = `https://playready.ezdrm.com/cency/preauth.aspx?pX=${STREAM_PR_PX_VALUE}`;
const fairplayLaUrl = `https://fps.ezdrm.com/api/licenses/auth?px=${STREAM_FPS_ASSET_ID}`;

interface Robustness {
  videoRobustness?: string;
  audioRobustness?: string;
}

const getRobustness = async () => {
  const supportedConfiguration = isPlayreadyEnabled()
    ? SupportedDRMConfigurations.playready
    : SupportedDRMConfigurations.widevine;

  const robustnessLevels = [
    { level: "HW_SECURE_ALL", isValid: true }, // Widevine L1
    { level: "HW_SECURE_DECODE", isValid: true }, // Widevine L1
    { level: "HW_SECURE_CRYPTO", isValid: true }, // Widevine L2
    { level: "SW_SECURE_DECODE", isValid: false }, // Widevine L3
    { level: "SW_SECURE_CRYPTO", isValid: false }, // Widevine L3
    { level: "3000", isValid: true } // Hardware-based robustness.
  ];

  let isValidRobustness = false;
  let videoRobustness = "";
  let audioRobustness = "";

  for (let index = 0; index < robustnessLevels.length; index++) {
    const { level, isValid } = robustnessLevels[index];

    const videoTestConfig = [
      {
        videoCapabilities: [
          {
            contentType: `video/mp4; codecs="avc1.42E01E"`,
            robustness: level
          }
        ]
      }
    ];

    const audioTestConfig = [
      {
        audioCapabilities: [
          {
            contentType: `audio/mp4;codecs="mp4a.40.2"`,
            robustness: level
          }
        ]
      }
    ];

    try {
      // eslint-disable-next-line no-await-in-loop
      await navigator.requestMediaKeySystemAccess(
        supportedConfiguration,
        videoTestConfig
      );

      videoRobustness = level;
      isValidRobustness = isValid;
    } catch (err) {
      isValidRobustness = false;
      if (!isProd) {
        console.info("VIDEO ROBUSTNESS CHECK:", level, err);
      }
    }

    try {
      // eslint-disable-next-line no-await-in-loop
      await navigator.requestMediaKeySystemAccess(
        supportedConfiguration,
        audioTestConfig
      );
      audioRobustness = level;
    } catch (err) {
      if (!isProd) {
        console.info("AUDIO ROBUSTNESS CHECK:", level, err);
      }
    }

    if (isValidRobustness) {
      break;
    }
  }

  return {
    isValid: isValidRobustness,
    videoRobustness: videoRobustness,
    audioRobustness: audioRobustness
  };
};

const keySystems = [
  {
    keySystem: SupportedDRMConfigurations.playready,
    drm: DRM_SYSTEM.PLAYREADY
  },
  {
    keySystem: SupportedDRMConfigurations.widevine,
    drm: DRM_SYSTEM.WIDEVINE
  }
];

const checkDRMSupport = async () => {
  if (!window.navigator.requestMediaKeySystemAccess) {
    return DRM_SYSTEM.NODRM;
  }

  const device = getDeviceInformation();
  const { isDeviceSupported } = checkDeviceSupport(device);

  let drm = DRM_SYSTEM.NODRM;

  for (const keySystem of keySystems) {
    try {
      const config = [
        {
          initDataTypes: ["cenc"],
          audioCapabilities: [{ contentType: 'audio/mp4; codecs="mp4a.40.2"' }],
          videoCapabilities: [
            { contentType: 'video/mp4; codecs="avc1.42E01E"' }
          ]
        }
      ];

      await navigator.requestMediaKeySystemAccess(keySystem.keySystem, config);

      if (keySystem.drm === DRM_SYSTEM.PLAYREADY && !isPlayreadyEnabled()) {
        drm = DRM_SYSTEM.NODRM;
      } else {
        drm = keySystem.drm;
      }
    } catch (err) {
      if (!isProd) {
        console.warn("DRM SUPPORT CHECK:", keySystem.drm, err);
      }
    }
  }

  if (drm === DRM_SYSTEM.NODRM && device.isAppleDevice && isDeviceSupported) {
    drm = DRM_SYSTEM.FAIRPLAY;
  }

  return drm;
};

const selectManifestUrl = (config: IStreamConfiguration, drm: DRM_SYSTEM) => {
  let hls = null;
  let dash = null;
  let thumbnailTrack = null;

  if (config.manifestUrl) {
    hls = config.manifestUrl;
    dash = config.manifestUrl;
    thumbnailTrack = config.thumbnailTrack;
  } else {
    if (drm === DRM_SYSTEM.PLAYREADY) {
      hls = config.hlsManifestUrl;
      dash = config.playreadyManifestUrl;
      thumbnailTrack = config.playreadyThumbnailTrack;
    } else {
      hls = config.hlsManifestUrl;
      dash = config.dashManifestUrl;
      thumbnailTrack = config.thumbnailTrack;
    }
  }

  return { hls, dash, thumbnailTrack };
};

const getPlayerCapabilities = async () => {
  const result = {
    ipAddress: null,
    isLocationAllowed: false,
    isDeviceSupported: false,
    isL1Enabled: false,
    supportedDevice: null,
    drm: await checkDRMSupport(),
    isAppleDevice: false,
    isWindowsDevice: false
  };

  const deviceInfo = await fetchDeviceValidation();

  if (deviceInfo) {
    const { ipAddress, isLocationAllowed, device } = deviceInfo;

    result.ipAddress = ipAddress;
    result.isAppleDevice = device.isAppleDevice;
    result.isWindowsDevice = device.isWindowsDevice;

    if (isLocationAllowed) {
      result.isLocationAllowed = isLocationAllowed;

      const { isDeviceSupported, supportedDevice } = checkDeviceSupport(device);

      result.isDeviceSupported = isDeviceSupported;
      result.supportedDevice = supportedDevice;

      if (device.isAppleDevice && isDeviceSupported) {
        result.isL1Enabled = true;
      } else if (isDeviceSupported) {
        const { isValid } = await getRobustness();
        result.isL1Enabled = isValid;
      } else {
        result.isL1Enabled = false;
      }
    }
  }

  return result;
};

const isPlayreadyEnabled = () =>
  ALLOW_PLAYREADY && getDeviceInformation().isWindowsDevice;

const fetchWithHeaders = async (
  url: string,
  method = "GET"
): Promise<Response> => {
  const token = getAccessToken();
  const headers = new Headers();

  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  const options: RequestInit = { method, headers };

  return fetch(url, options);
};

const createRangeFetchingBlob = async (videoUrl: string) => {
  const stream = new ReadableStream({
    async start(controller) {
      try {
        const chunk = await (
          await fetchWithHeaders(videoUrl, "GET")
        ).arrayBuffer();
        controller.enqueue(new Uint8Array(chunk));
      } catch (error) {
        console.error(`Failed to fetch stream`, error);
        return;
      }
      controller.close();
    }
  });

  const response = new Response(stream, {
    headers: { "Content-Type": "video/mp4" }
  });

  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

const fetchAndPrepareM3U8 = async (masterUrl: string) => {
  try {
    const masterBlob = await (await fetchWithHeaders(masterUrl)).blob();
    const masterText = await masterBlob.text();

    const streamRef = masterText
      .split("\n")
      .find((line) => !line.startsWith("#") && line.trim() !== "");
    if (!streamRef) {
      throw new Error("No stream reference found in master.m3u8");
    }

    const masterBaseUrl = masterUrl.substring(
      0,
      masterUrl.lastIndexOf("/") + 1
    );
    const streamUrl = new URL(streamRef, masterBaseUrl).href;

    let streamText = await fetchWithHeaders(streamUrl).then((response) =>
      response.text()
    );

    const videoRef = streamText
      .split("\n")
      .find((line) => !line.startsWith("#") && line.trim() !== "");
    if (!videoRef) throw new Error("No video reference found in stream_0.mpd");

    const videoUrl = new URL(videoRef, masterBaseUrl).href;
    const videoBlobUrl = await createRangeFetchingBlob(videoUrl);
    streamText = streamText.replace(new RegExp(videoRef, "g"), videoBlobUrl);

    const streamDataUrl = `data:application/vnd.apple.mpegurl;base64,${btoa(
      streamText
    )}`; // To use data uri instead of blob uri since for blob uri it always generates a range request

    return streamDataUrl;
  } catch (error) {
    console.error("Error processing M3U8 files:", error);
  }
};

const fetchAndPrepareMPD = async (masterUrl: string) => {
  try {
    let masterText = await (await fetchWithHeaders(masterUrl)).text();
    const videoRef = masterText.match(/<BaseURL>(.*?)<\/BaseURL>/)?.[1];
    if (!videoRef) throw new Error("No stream reference found in MPD file");
    const masterBaseUrl = masterUrl.substring(
      0,
      masterUrl.lastIndexOf("/") + 1
    );
    const videoUrl = new URL(videoRef, masterBaseUrl).href;
    const videoBlobUrl = await createRangeFetchingBlob(videoUrl);
    masterText = masterText.replace(new RegExp(videoRef, "g"), videoBlobUrl);
    const streamDataUrl = `data:application/dash+xml;base64,${btoa(masterText)}`; // To use data uri instead of blob uri since for blob uri it always generates a range request
    return streamDataUrl;
  } catch (error) {
    console.error("Error processing MPD files:", error);
  }
};

const getDrmConfig = async (robustness: Robustness) => {
  return {
    drm: {
      advanced: {
        "com.apple.fps": {
          serverCertificateUri: STREAM_FPS_CERT_URL
        },
        "com.microsoft.playready": {
          videoRobustness: robustness.videoRobustness || undefined
        },
        "com.widevine.alpha": {
          videoRobustness: robustness.videoRobustness || undefined
        }
      },
      keySystemsMapping: {
        "com.microsoft.playready": "com.microsoft.playready.recommendation"
      },
      servers: {
        "com.apple.fps": fairplayLaUrl,
        "com.microsoft.playready": playreadyLaUrl,
        "com.widevine.alpha": widevineLaUrl
      }
    }
  };
};

export {
  getRobustness,
  selectManifestUrl,
  getPlayerCapabilities,
  fetchAndPrepareM3U8,
  fetchAndPrepareMPD,
  getDrmConfig
};
