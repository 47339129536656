const supportedFileTypes = {
  // Image filetypes
  "image/bmp": [".bmp"],
  "image/gif": [".gif"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/x-portable-pixmap": [".ppm"],
  "image/apng": [".apng"],
  "image/png": [".png"],
  "image/webp": [".webp"],
  "image/heic": [".heic"],
  // Document filetypes
  "application/pdf": [".pdf"],
  "application/rtf": [".rtf"],
  "text/rtf": [".rtf"],
  "text/plain": [".txt"],
  // Audio filetypes
  "audio/mp3": [".mp3"],
  "audio/x-wav": [".wav"],
  "audio/webm": [".webm"],
  "audio/mpeg": [".mpeg"],
  // Video filetypes
  "video/quicktime": [".mov"],
  "video/mp4": [".mp4"],
  "video/webm": [".webm"],
  // Spreadsheet filetypes
  "text/csv": [".csv"],
  "text/html": [".html"],
  // "application/x-iwork-numbers-sffnumbers": [".numbers"],
  "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
  "application/vnd.oasis.opendocument.spreadsheet-template": [".ots"],
  "application/vnd.oasis.opendocument.spreadsheet-flat-xml": [".fods"],
  "text/spreadsheet": [".slk"],
  "text/xml": [".xml"],
  "application/rss+xml": [".xml"],
  "application/vnd.ms-excel": [".xlc", ".xls", ".xlt", ".xlw"],
  "application/vnd.ms-excel.sheet.macroenabled.12": [".xlsm"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx"
  ],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template": [
    ".xltx"
  ],
  // Word processor filetypes
  "application/msword": [".doc", ".dot"],
  "application/vnd.ms-word.template.macroEnabled.12": [".docm"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx"
  ],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template": [
    ".dotx"
  ],
  "application/vnd.oasis.opendocument.text": [".odt"],
  "application/vnd.oasis.opendocument.text-template": [".ott"],
  "application/vnd.oasis.opendocument.text-flat-xml": [".fodt"],
  // "application/x-iwork-pages-sffpages": [".pages"],
  "application/vnd.wordperfect": [".wpd"],
  // Slideshow filetypes
  // "application/x-iwork-keynote-sffkey": [".key"],
  "application/vnd.oasis.opendocument.graphics": [".odg"],
  "application/vnd.oasis.opendocument.presentation": [".odp"],
  "application/vnd.oasis.opendocument.presentation-template": [".otp"],
  "application/vnd.oasis.opendocument.presentation-flat-xml": [".fodp"],
  "application/vnd.ms-powerpoint": [".pot", ".pps", ".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptm",
    ".pptx"
  ],
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow": [
    ".ppsx"
  ],
  "application/vnd.openxmlformats-officedocument.presentationml.template": [
    ".potx"
  ],
  // Database filetypes
  "application/vnd.dbf": [".dbf"],
  "application/x-dbase": [".dbf"]
  // "video/x-dv": [".dif"]
};

export { supportedFileTypes };
