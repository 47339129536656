import React from "react";
import { BaseIconProps } from "./icon.types";

function ReportIcon({ className }: BaseIconProps) {
  const classes = className ? `${className}` : "";

  return (
    <svg
      className={classes}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="report_svgrepo.com">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66667 0.666748C2.29848 0.666748 2 0.965228 2 1.33341V14.6667C2 15.0349 2.29848 15.3334 2.66667 15.3334C3.03485 15.3334 3.33333 15.0349 3.33333 14.6667V9.06561C3.64401 8.91095 4.13515 8.69535 4.66167 8.56375C5.60607 8.32768 6.36399 8.41481 6.77867 9.03675C7.55267 10.1977 9.0308 10.2999 10.1684 10.1866C11.3689 10.067 12.5565 9.67388 13.1569 9.45181C13.6845 9.25675 14 8.75581 14 8.22728V3.81566C14 2.78139 12.9052 2.17757 12.0326 2.57329C11.325 2.8942 10.3849 3.25258 9.5354 3.37095C8.6466 3.49479 8.1304 3.32721 7.888 2.96371C7.0134 1.65183 5.51705 1.49652 4.44463 1.58589C4.03293 1.6202 3.65039 1.69219 3.33333 1.76841V1.33341C3.33333 0.965228 3.03485 0.666748 2.66667 0.666748ZM3.33333 3.14746V7.60321C3.62722 7.48321 3.97077 7.36208 4.33831 7.27021C5.39391 7.00635 6.96933 6.91921 7.888 8.29708C8.20707 8.77568 8.94233 8.96881 10.0362 8.85988C11.0505 8.75881 12.0948 8.42148 12.6667 8.21155V3.81566C12.6667 3.78537 12.6054 3.77757 12.5833 3.78759C11.8383 4.12544 10.7597 4.54656 9.7194 4.69152C8.7184 4.83101 7.4736 4.74575 6.77867 3.70333C6.31993 3.01531 5.48293 2.83732 4.55536 2.91461C4.0827 2.95401 3.64285 3.05759 3.33333 3.14746Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

export default ReportIcon;
