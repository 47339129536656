import React, { useCallback, useEffect, useRef, useState } from "react";
import debounce from "@/lib/debounce";
import { Box, TextField } from "@mui/material";
import styles from "../SuperTables/SuperTables.module.scss";
import { useFormik } from "formik";
import { userSearchValidationSchema } from "../secure-file/step-1/data/validation-schemas.data";
import { getSharedUsersOnAssetsList } from "@/services/users-service";
import { components } from "@/types/beta.schema";
import { useUsers } from "@/contexts/users/users.hook";
import { useAuth } from "@/contexts/auth/auth.hook";
import { useProfile } from "@/contexts/profile/profile.hook";

function UserSearch() {
  const { refresh, users, setUsers, setSearchQuery } = useUsers();
  const hasInteracted = useRef(false);
  const [userSearchResults, setUserSearchResults] =
    useState<Array<components["schemas"]["UserData"]>>(users);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { user: authenticatedUser } = useAuth();
  const { viewingProfile } = useProfile();
  const currentUser = viewingProfile ?? authenticatedUser;

  const userSearchCache = useRef(
    new Map<string, Array<components["schemas"]["UserData"]>>()
  ).current;

  const userSearchFormik = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema: userSearchValidationSchema,
    onSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    }
  });

  const debouncedGetSearchResults = useCallback(
    debounce(async (searchStr: string) => {
      setSearchQuery(searchStr);
      if (searchStr.length >= 1) {
        if (userSearchCache.has(searchStr)) {
          setUserSearchResults(userSearchCache.get(searchStr) || []);
        } else {
          const { users: fetchedUsers } = await handleGetUserSearchResults({
            searchStr
          });

          userSearchCache.set(searchStr, fetchedUsers);
          setUserSearchResults(fetchedUsers);
        }
      } else {
        setUserSearchResults([]);
      }
    }, 250),
    []
  );

  // Fetch users from the API
  const handleGetUserSearchResults = async ({
    searchStr
  }: {
    searchStr: string;
  }) => {
    const { data } = await getSharedUsersOnAssetsList({
      "filter[search]": searchStr,
      "filter[excluded_emails]": currentUser["email"]
    });

    return { users: data?.data };
  };

  useEffect(() => {
    if (!hasInteracted.current) {
      return;
    }

    if (userSearchResults.length > 0) {
      setUsers(userSearchResults);
    } else if (userSearchFormik.values.name.length > 0) {
      setUsers([]);
    } else {
      // Search bar has been cleared
      refresh({}, true);
    }
  }, [userSearchResults]);

  // Handle input change
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasInteracted.current) {
      hasInteracted.current = true;
    }

    userSearchFormik.handleChange(e);
    debouncedGetSearchResults(e.target.value);
  };

  // Handle blur event to manage error message and close of dropdown
  const handleBlur = () => {
    userSearchFormik.setFieldTouched("name", false); // removes error message
  };

  // Handle focus event to reopen the dropdown if there are users
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const currentValue = e.target.value || "";
    if (currentValue.length >= 3) {
      debouncedGetSearchResults(currentValue);
    }
  };

  // Handle key press (for "Enter" functionality)
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "Enter": {
        e.preventDefault();
        userSearchFormik.handleSubmit();
        break;
      }
      default:
        break;
    }
  };

  return (
    <Box className={`${styles.searchBar}`}>
      <TextField
        fullWidth
        ref={inputRef}
        id="email"
        name="name"
        className={`text-input`}
        value={userSearchFormik.values.name}
        onChange={handleNameChange}
        onBlur={handleBlur}
        aria-owns="user-search-dropdown"
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        error={
          userSearchFormik.touched.name && Boolean(userSearchFormik.errors.name)
        }
        helperText={
          userSearchFormik.touched.name && userSearchFormik.errors.name
        }
        placeholder="Search..."
      />
    </Box>
  );
}

export default UserSearch;
