import React from "react";
import { useMap } from "react-map-gl";
import { MapViewer } from "../CustomMarker/CustomMarker.types";
import styles from "./LiveViewerItem.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { usePreviewPanel } from "@/components/shared/asset-previews/preview-panel/usePreviewPanel";

const LiveViewerItem: React.FC<MapViewer> = (props: MapViewer) => {
  const { map } = useMap();
  const { closePreviewPanel } = usePreviewPanel();
  const navigate = useNavigate();
  return (
    <li className={styles.viewer}>
      {props?.username ? (
        <Link
          to={`${props?.username}`}
          className={styles.userInfo}
          onClick={() => {
            closePreviewPanel();
            navigate(`/${props?.username}`);
          }}
        >
          <img src={props.avatar} className={styles.avatar} />
          <div>{`${props.username ?? "Anonymous"}`}</div>
        </Link>
      ) : (
        <div className={styles.userInfo}>
          <img src={props.avatar} className={styles.avatar} />
          <div>{`${props.username ?? "Anonymous"}`}</div>
        </div>
      )}

      <div className={styles.right}>
        <div>{`${props.ip}`}</div>
        <button
          onClick={() => {
            map?.flyTo({
              center: [props.location.longitude, props.location.latitude],
              zoom: 6,
              speed: 0.75
            });
            (
              document.getElementsByClassName(
                `marker-${props.id}`
              )[0] as HTMLElement
            )?.click();
          }}
          className={styles.goToButton}
        >
          Go to
        </button>
      </div>
    </li>
  );
};

export default LiveViewerItem;
