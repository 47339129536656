const createdDate = (value) => {
  const date = new Date(value);

  const day = date.getDate(); // Day of the month without leading zeros
  const month = date.toLocaleString("default", { month: "long" }); // Full textual representation of the month
  const year = date.getFullYear(); // Full numeric representation of the year

  return `${month} ${day}, ${year}`;
};

export default createdDate;
