import React, { useState } from "react";
import {
  ACCESS_REQUEST_RESPONSE,
  NotificationItemTemplateProps
} from "./NotificationItemTemplate.types";
import styles from "./NotificationItemTemplate.module.scss";
import { markAsRead } from "@/services/notifications-service";
import formatDate from "@/lib/format-date";
import useFetchUserMiddleware from "@/hooks/useFetchUserMiddleware";
import classNames from "classnames";
import { deleteNotification } from "@/services/notifications-service";
import {
  declineAccessRequest,
  shareAssetWithUser
} from "@/services/users-service";
import { ACCESS_TYPE } from "@/types/contexts/share-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { components } from "@/types/beta.schema";

const NotificationItemTemplate: React.FC<NotificationItemTemplateProps> = ({
  notificationItem: { notification, sharedAsset },
  updateNotifications
}) => {
  const user = useFetchUserMiddleware(notification.data.user_id);

  const [accessResponse, setAccessResponse] = useState<ACCESS_REQUEST_RESPONSE>(
    ACCESS_REQUEST_RESPONSE.NO_RESPONSE
  );

  const handleAccessApprove = async () => {
    await markAsRead(notification.id);
    updateNotifications(true);

    const payload: components["schemas"]["UpdateAssetShareData"] = {
      user_id: user.id,
      access_type: ACCESS_TYPE.ViewOnly
    };

    const shareResponse = await shareAssetWithUser(
      notification.data.asset_id,
      payload
    );

    // TODO: Handle error
    if (shareResponse.data) {
      setAccessResponse(ACCESS_REQUEST_RESPONSE.REQUEST_APPROVED);
      await deleteNotification(notification.id);
    }
  };

  const handleAccessDeny = async () => {
    await markAsRead(notification.id);
    updateNotifications(true);

    const shareResponse = await declineAccessRequest(
      notification.data.asset_id,
      user.id
    );

    // TODO: Handle error
    if (shareResponse.data) {
      setAccessResponse(ACCESS_REQUEST_RESPONSE.REQUEST_DENIED);
      await deleteNotification(notification.id);
    }
  };

  const deleteSelfAndRefresh = async () => {
    if (notification.type == "request_access") {
      // Treat deleting a request notification as a "Deny" action
      await handleAccessDeny();
    } else {
      await deleteNotification(notification.id);
    }
    updateNotifications();
  };

  const renderNotificationText = () => {
    const messages = {
      asset_shared: "shared access to",
      request_access: "requested access to"
    };

    return messages[notification.type] ? (
      <span>
        <span className={styles.boldNotificationText}>{user?.username}</span>{" "}
        {messages[notification.type]}{" "}
        <span className={styles.boldNotificationText}>{sharedAsset?.name}</span>
      </span>
    ) : null;
  };

  return (
    <div
      className={`${styles.notificationItem} ${!notification.read_at ? styles.unread : ""}`}
      onClick={() => {
        if (!notification.read_at) {
          markAsRead(notification.id);
          updateNotifications();
        }
      }}
    >
      <div className={styles.notificationInfoContainer}>
        <img
          src={user?.profile?.avatar ?? "/images/logo-yellow-bg.png"}
          className={styles.profileAvatar}
          alt="User Profile"
        />
        <div className={styles.notificationText}>
          {renderNotificationText()}
          <div className={styles.notificationDate}>
            {formatDate(notification.created_at)}
          </div>
        </div>
        <div
          className={classNames(
            styles.notifMarker,
            notification.read_at ? styles.readMarker : styles.unreadMarker
          )}
        />
      </div>
      {notification.type === "request_access" && (
        <div className={styles.actionButtonContainer}>
          {accessResponse === ACCESS_REQUEST_RESPONSE.NO_RESPONSE ? (
            <>
              <button
                className={styles.actionButton}
                onClick={handleAccessApprove}
              >
                {"Approve"}
              </button>
              <button
                className={`${styles.actionButton} ${styles.denyAction}`}
                onClick={handleAccessDeny}
              >
                {"Deny"}
              </button>
            </>
          ) : (
            <div className={styles.accessResponse}>{accessResponse}</div>
          )}
        </div>
      )}
      <button onClick={deleteSelfAndRefresh} className={styles.deleteButton}>
        <FontAwesomeIcon icon={faXmark} />
      </button>
    </div>
  );
};

export default NotificationItemTemplate;
