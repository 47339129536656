import * as yup from "yup";

export const assetSearchValidationSchema = yup.object({
  name: yup.string().required()
});

export const userSearchValidationSchema = yup.object({
  name: yup.string().required()
});

export const userShareSearchValidationSchema = yup.object({
  email: yup.string().required("Email is required").email("Enter a valid email")
});

export const uploadedFileNameSchema = yup.object({
  name: yup.string().required()
});
