import React, { useEffect, useRef } from "react";
import { Button, TextField } from "@mui/material";
import anime from "animejs/lib/anime.es.js";
import { validationSchema } from "./data/form.data";
import { LoginPageProps } from "./Login.types";
import { useFormik } from "formik";
import styles from "./Login.module.scss";
import { Link } from "react-router-dom";
import { SignInPayload } from "@/types/services/auth-service";
import { useAuth } from "@/contexts/auth/auth.hook";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";

function Login({ goToLogoScreen, goToDashboardScreen }: LoginPageProps) {
  const { signIn } = useAuth();
  const { addToast } = useSuperToast();

  const headerRef = useRef(null);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  useEffect(() => {
    anime({
      targets: headerRef.current,
      opacity: [0, 1],
      easing: "easeInOutQuad",
      duration: 350
    });
    anime({
      targets: [emailInputRef.current, passwordInputRef.current],
      marginLeft: ["-100px", "0px"],
      marginRight: ["100px", "0px"],
      delay: anime.stagger(100, {
        start: 0
      })
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const payload: SignInPayload = {
          email: values.email,
          password: values.password
        };

        await signIn(payload);

        goToDashboardScreen();
      } catch (error) {
        console.error(error.message);
        addToast(error.message, ToastTypes.FAIL);
      }
    }
  });

  return (
    <div className={`${styles.loginScreen} page-layout`}>
      <div className={styles.wrapper}>
        <button
          ref={headerRef}
          className={styles.imageWrapper}
          onClick={goToLogoScreen}
        >
          <img src="/images/Super_Secure_Combomark 2.svg" alt="Logo" />
        </button>

        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            className="text-input"
            placeholder="Email"
            inputRef={emailInputRef}
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            className="text-input"
            placeholder="Password"
            inputRef={passwordInputRef}
          />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            className="super-button"
          >
            Sign In
          </Button>
        </form>

        <Link className={styles.forgotPassword} to="/forgot-password">
          Forgot password?
        </Link>
      </div>
    </div>
  );
}

export default Login;
