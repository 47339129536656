import { Button, TextField } from "@mui/material";
import React from "react";
import styles from "./CreateFolderModalTemplate.module.scss";
import { useFormik } from "formik";
import { validationSchema } from "./data/form";
import { components } from "@/types/beta.schema";
import { createAsset } from "@/services/asset-service";
import { useModal } from "@/components/shared/Modal/useModal";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { useAssets } from "@/contexts/assets/assets.hook";

function CreateFolderModalTemplate() {
  const { closeModal } = useModal();
  const { addToast } = useSuperToast();
  const { folderState, refresh } = useAssets();

  const formik = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const folderId = folderState?.currentFolder?.id ?? null;

      const payload: components["schemas"]["CreateAssetData"] = {
        name: values.name,
        type: "folder",
        visibility: "private",
        folder_id: folderId,
        pinned: false
      };

      const { data, error } = await createAsset(payload);

      closeModal();

      if (data) {
        await refresh({ folder_id: folderId }, true);
        return addToast("Folder created", ToastTypes.SUCCESS);
      } else {
        return addToast(error.message, ToastTypes.FAIL);
      }
    }
  });

  return (
    <div>
      <span className="title">Create a folder</span>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          className="text-input"
          placeholder="Folder name"
        />
        <div className={styles.formActions}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            className={`${styles.button} super-button brand-secondary`}
            size="small"
          >
            Create
          </Button>
        </div>
      </form>
    </div>
  );
}

export default CreateFolderModalTemplate;
