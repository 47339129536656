import styles from "../FilesSuperTable/FilesSuperTable.module.scss";
import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { useBottomSheet } from "@/components/shared/BottomSheet/useBottomSheet";
import {
  faLock,
  faUnlock,
  faStar as faStarFilled,
  faShare
} from "@fortawesome/free-solid-svg-icons";

import { components } from "@/types/beta.schema";
import { useAssets } from "@/contexts/assets/assets.hook";
import { isApiErrorResponse } from "@/lib/error";
import { useModal } from "@/components/shared/Modal/useModal";
import {
  favoriteAsset,
  unfavoriteAsset,
  updateAsset
} from "@/services/asset-service";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import MenuActionTemplate from "../../menu-action-template/MenuActionTemplate.component";

import { useProfile } from "@/contexts/profile/profile.hook";
import useResponsive from "@/hooks/useResponsive";
import { ACCESS_TYPE } from "@/types/contexts/share-context";
import RequestAccessTemplate from "../../request-access-template/RequestAccessTemplate.component";
import useAssetRouting from "@/hooks/useAssetRouting";
import { LinearProgress, CircularProgress } from "@mui/material";
import ShareAssetTemplate from "../../share-asset-template/ShareAssetTemplate.component";
import { PusherPrivateChannel } from "laravel-echo/dist/channel";

const RowActionTemplate = (rowData: components["schemas"]["AssetData"]) => {
  const { viewingProfile } = useProfile();

  const assetId = rowData.id;
  const { isDesktop, isMobile } = useResponsive();
  const { openModal } = useModal();
  const { addToast } = useSuperToast();
  const { openBottomSheet, isOpen: mobileSortOpen } = useBottomSheet();
  const { handleFolderNav } = useAssetRouting();
  const { refresh, folderState } = useAssets();

  // TODO: this should be fixed after schema update
  const isFavorited = rowData.favorite as unknown as boolean;

  const isShortcut = rowData.type == "shortcut";
  const isOwner =
    !isShortcut && rowData.access.access_type === ACCESS_TYPE.Owner;

  const isManager =
    !isOwner &&
    ((isShortcut && rowData.asset.access?.access_type == ACCESS_TYPE.Manager) ||
      (viewingProfile && rowData.access?.access_type == ACCESS_TYPE.Manager));

  const privacyLevel = isShortcut
    ? rowData.asset.visibility
    : rowData.visibility;
  const privacyToggleAssetId =
    isShortcut && isManager ? rowData.asset_id : assetId;

  const starIcon = isFavorited ? faStarFilled : faStar;
  const lockIcon = privacyLevel === "public" ? faUnlock : faLock;
  const progress = isDesktop ? (
    <LinearProgress
      variant="indeterminate"
      classes={{
        colorPrimary: styles.colorPrimary,
        barColorPrimary: styles.barColorPrimary
      }}
    />
  ) : (
    <div className={styles.rowActionCell}>
      <CircularProgress
        variant="indeterminate"
        sx={() => ({
          color: "#e5fa0c"
        })}
      />
    </div>
  );
  const progressChannel = useRef<PusherPrivateChannel>(null);
  useEffect(() => {
    if (!viewingProfile) {
      const isShortcut = rowData.type == "shortcut";
      const isOwner =
        !isShortcut && rowData.access.access_type === ACCESS_TYPE.Owner;
      if (isOwner && rowData.processed !== true) {
        progressChannel.current = window?.Echo?.private(
          `asset.${assetId}.owner`
        );
        progressChannel.current.listen("AssetProgress", (e) => {
          if (e.step === e.max_steps - 1 && e.progress === 100) {
            refresh(
              {
                folder_id: folderState?.currentFolder?.id,
                owner_id: viewingProfile?.id
              },
              true
            );
          }
        });
      }
    }

    return () => {
      progressChannel?.current?.stopListening("AssetProgress");
      progressChannel.current = null;
    };
  }, [viewingProfile]);

  const handleFavoriteToggle = async (
    assetId: string,
    isFavorited: boolean
  ) => {
    // TODO: Add error handling once the api client is ready

    if (isFavorited) {
      await unfavoriteAsset(assetId);
    } else {
      await favoriteAsset(assetId);
    }

    // TODO: Look into updating table state/provider without making a network request
    // so loading spinner doesn't show during this process

    // setAssets((prevAssets) =>
    //   prevAssets.map((asset) =>
    //     asset.id === assetId ? { ...asset, isFavorited } : asset
    //   )
    // );
    addToast("Favorite status updated", ToastTypes.SUCCESS);
    refresh(
      {
        folder_id: folderState?.currentFolder?.id,
        owner_id: viewingProfile?.id
      },
      true
    );
  };

  const handlePrivacyToggle = async (
    assetId: string,
    visibilityType: components["schemas"]["VisibilityType"]
  ) => {
    const visibility = visibilityType === "public" ? "restricted" : "public";

    const { error } = await updateAsset(assetId, {
      visibility
    });

    if (isApiErrorResponse(error)) {
      console.error(error.message);
      return addToast(error.message, ToastTypes.FAIL);
    }

    addToast("File visibility was successfully updated.", ToastTypes.SUCCESS);
    refresh(
      {
        folder_id: folderState?.currentFolder?.id,
        owner_id: viewingProfile?.id
      },
      true
    );
  };

  // Show RequestAccessTemplate only if viewing profile is true
  // And current auth user does not have access, unless visibility is public
  if (viewingProfile) {
    if (rowData.access?.access_type === ACCESS_TYPE.NoAccess) {
      return <RequestAccessTemplate assetId={assetId} />;
    }
  }

  const isFolder = rowData.type == "folder";
  const visibility = rowData.visibility;
  const isPrivate = visibility === "private";

  return isOwner && rowData.processed !== true ? (
    progress
  ) : (
    <div className={styles.rowActionCell}>
      {!isFolder && isOwner && !isPrivate && (
        <button
          className={styles.iconContainer}
          onClick={() => {
            if (isMobile) {
              return openBottomSheet({
                content: <ShareAssetTemplate asset={rowData} />,
                showActions: false
              });
            }

            openModal({
              content: <ShareAssetTemplate asset={rowData} />,
              classes: styles.modalWidth,
              showActions: false
            });
          }}
        >
          <FontAwesomeIcon icon={faShare} />
        </button>
      )}
      {!isFolder && (
        <button
          className={styles.iconContainer}
          onClick={() =>
            handlePrivacyToggle(privacyToggleAssetId, privacyLevel)
          }
          disabled={!isOwner && !isManager}
        >
          <FontAwesomeIcon icon={lockIcon} />
        </button>
      )}
      {isDesktop && (
        <button
          className={styles.iconContainer}
          onClick={() => handleFavoriteToggle(assetId, isFavorited)}
        >
          <FontAwesomeIcon icon={starIcon} size={"lg"} />
        </button>
      )}
      {(isDesktop || (!isDesktop && !isFolder)) && (
        <MenuActionTemplate asset={rowData} />
      )}
      {!isDesktop && isFolder && (
        <button
          className={styles.mobileFolderOpen}
          onClick={() => handleFolderNav(rowData.name, rowData.id)}
        >
          Open
        </button>
      )}
    </div>
  );
};
export default RowActionTemplate;
