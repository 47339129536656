import React from "react";
import { BaseIconProps } from "./icon.types";

function VideoChatIcon({ color = "white", className }: BaseIconProps) {
  const classes = className ? `${className}` : "";

  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="video-chat_svgrepo.com">
        <path
          id="Vector"
          d="M18.68 1.47998H5.32C4.30687 1.47998 3.33524 1.88244 2.61885 2.59883C1.90246 3.31522 1.5 4.28685 1.5 5.29998V14.84C1.5 15.8531 1.90246 16.8247 2.61885 17.5411C3.33524 18.2575 4.30687 18.66 5.32 18.66H9.14L12 21.52L14.86 18.66H18.68C19.6931 18.66 20.6648 18.2575 21.3811 17.5411C22.0975 16.8247 22.5 15.8531 22.5 14.84V5.29998C22.5 4.28685 22.0975 3.31522 21.3811 2.59883C20.6648 1.88244 19.6931 1.47998 18.68 1.47998Z"
          stroke={color}
          strokeMiterlimit="10"
        />
        <path
          id="Vector_2"
          d="M10.96 7.2002H9.22998C8.12541 7.2002 7.22998 8.09563 7.22998 9.2002V11.8802C7.22998 12.9848 8.12541 13.8802 9.22998 13.8802H10.96C12.0645 13.8802 12.96 12.9848 12.96 11.8802V9.2002C12.96 8.09563 12.0645 7.2002 10.96 7.2002Z"
          stroke={color}
          strokeMiterlimit="10"
        />
        <path
          id="Vector_3"
          d="M13.2529 9.77707C13.0653 9.9646 12.96 10.219 12.96 10.4842V10.6068C12.96 10.8714 13.0648 11.1252 13.2516 11.3126L15.6232 13.6925C15.7492 13.8189 15.9203 13.89 16.0987 13.89V13.89C16.4694 13.89 16.77 13.5894 16.77 13.2187V7.88171C16.77 7.51072 16.4692 7.20996 16.0982 7.20996V7.20996C15.9201 7.20996 15.7492 7.28073 15.6232 7.40671L13.2529 9.77707Z"
          stroke={color}
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
}

export default VideoChatIcon;
