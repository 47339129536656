import React, { useState } from "react";
import {
  SecureFileContextProps,
  SecureFileProviderProps,
  UploadedFile
} from "./secure-file.types";
import SecureFileContext from "./secure-file.context";
import { SharedUserDetails } from "@/types/contexts/share-context";
import { removeExtensionFromName } from "@/lib/files";

export const SecureFileProvider = ({ children }: SecureFileProviderProps) => {
  const [files, setFiles] = useState<Array<UploadedFile>>([]);
  const [step, setStep] = useState<number>(1);
  const [isPublic, setIsPublic] = useState(false);
  const [fileForEdit, setFileForEdit] = useState<null | UploadedFile>(null);
  const [sharedUsers, setSharedUsers] = useState<Array<SharedUserDetails>>([]);

  const removeFile = (uploadedFile: UploadedFile): void => {
    setFiles(
      files.filter(
        (file) =>
          removeExtensionFromName(file.name) !==
          removeExtensionFromName(uploadedFile.name)
      )
    );
  };

  const updateFileName = (fileName: string): void => {
    const blob = fileForEdit;
    const updatedFile = new File([blob], removeExtensionFromName(fileName), {
      type: fileForEdit.type
    });
    const updatedFiles = files.map((file) =>
      file.name === fileForEdit?.name ? updatedFile : file
    );

    setFiles(updatedFiles);
    setFileForEdit(null);
  };

  const value: SecureFileContextProps = {
    files,
    setFiles,
    step,
    setStep,
    isPublic,
    setIsPublic,
    removeFile,
    setFileForEdit,
    updateFileName,
    fileForEdit,
    sharedUsers,
    setSharedUsers
  };

  return (
    <SecureFileContext.Provider value={value}>
      {children}
    </SecureFileContext.Provider>
  );
};
