import React, { useCallback, useEffect, useRef, useState } from "react";
import debounce from "@/lib/debounce";
import { Box, TextField } from "@mui/material";
import styles from "../SuperTables/SuperTables.module.scss";
import { useFormik } from "formik";
import { assetSearchValidationSchema } from "../secure-file/step-1/data/validation-schemas.data";
import { getAssets } from "@/services/asset-service";
import { components } from "@/types/beta.schema";
import { useAssets } from "@/contexts/assets/assets.hook";
import { useProfile } from "@/contexts/profile/profile.hook";

function AssetSearch() {
  const { folderState, refresh, assets, setAssets, setSearchQuery } =
    useAssets();
  const { viewingProfile } = useProfile();
  const hasInteracted = useRef(false);

  const [assetSearchResults, setAssetSearchResults] =
    useState<Array<components["schemas"]["AssetData"]>>(assets);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const assetSearchCache = useRef(
    new Map<string, Array<components["schemas"]["AssetData"]>>()
  ).current;

  const assetSearchFormik = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema: assetSearchValidationSchema,
    onSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    }
  });

  // Reset on nav change
  useEffect(() => {
    assetSearchFormik.resetForm();
    setSearchQuery(null);
    setAssetSearchResults([]);
    hasInteracted.current = false;
  }, [folderState]);

  // Debounced function to fetch assets
  const debouncedGetSearchResults = useCallback(
    debounce(async (searchStr: string) => {
      setSearchQuery(searchStr);
      if (searchStr.length >= 1) {
        const folderId = folderState.currentFolder?.id ?? "null";
        const cacheKey = `${searchStr}-${folderId}`;

        if (assetSearchCache.has(cacheKey)) {
          setAssetSearchResults(assetSearchCache.get(cacheKey) || []);
        } else {
          const { assets: fetchedAssets } = await handleGetAssetSearchResults({
            searchStr
          });

          assetSearchCache.set(cacheKey, fetchedAssets);
          setAssetSearchResults(fetchedAssets);
        }
      } else {
        setAssetSearchResults([]);
      }
    }, 250),
    [folderState]
  );

  // Fetch assets from the API
  const handleGetAssetSearchResults = async ({
    searchStr
  }: {
    searchStr: string;
  }) => {
    const { data } = await getAssets({
      "filter[search]": searchStr,
      folder_id: folderState.currentFolder?.id ?? null
    });

    return { assets: data?.data };
  };

  useEffect(() => {
    if (!hasInteracted.current) {
      return;
    }

    if (assetSearchResults.length > 0) {
      setAssets(assetSearchResults);
    } else if (assetSearchFormik.values.name.length > 0) {
      setAssets([]);
    } else {
      // Search bar has been cleared
      refresh(
        {
          folder_id: folderState?.currentFolder?.id ?? null,
          owner_id: viewingProfile?.id
        },
        true
      );
    }
  }, [assetSearchResults]);

  // Handle input change
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasInteracted.current) {
      hasInteracted.current = true;
    }

    assetSearchFormik.handleChange(e);
    debouncedGetSearchResults(e.target.value);
  };

  // Handle blur event to manage error message and close of dropdown
  const handleBlur = () => {
    assetSearchFormik.setFieldTouched("name", false); // removes error message
  };

  // Handle focus event to reopen the dropdown if there are assets
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const currentValue = e.target.value || "";
    if (currentValue.length >= 3) {
      debouncedGetSearchResults(currentValue);
    }
  };

  // Handle key press (for "Enter" functionality)
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "Enter": {
        e.preventDefault();
        assetSearchFormik.handleSubmit();
        break;
      }
      default:
        break;
    }
  };

  return (
    <Box className={`${styles.searchBar}`}>
      <TextField
        fullWidth
        ref={inputRef}
        id="email"
        name="name"
        className={`text-input`}
        value={assetSearchFormik.values.name}
        onChange={handleNameChange}
        onBlur={handleBlur}
        aria-owns="asset-search-dropdown"
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        error={
          assetSearchFormik.touched.name &&
          Boolean(assetSearchFormik.errors.name)
        }
        helperText={
          assetSearchFormik.touched.name && assetSearchFormik.errors.name
        }
        placeholder="Search..."
      />
    </Box>
  );
}

export default AssetSearch;
