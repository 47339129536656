import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./ActionsBar.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "@mui/material";
import useResponsive from "@/hooks/useResponsive";
import { ActionsBarProps } from "./ActionsBar.types";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { usePlayer } from "@/contexts/player/player.hook";
import { useModal } from "@/components/shared/Modal/useModal";
import SharedWithList from "@/components/SideSheetTabs/partials/InformationTab/SharedWithList/SharedWithList.component";
import classNames from "classnames";
import { useBottomSheet } from "@/components/shared/BottomSheet/useBottomSheet";
import { components } from "@/types/beta.schema";
import { useAssets } from "@/contexts/assets/assets.hook";
import { isApiErrorResponse } from "@/lib/error";
import { generateAssetLink } from "@/lib/link";

function ActionsBar({
  user,
  assetName,
  onRotate,
  asset,
  isPopup
}: ActionsBarProps) {
  const [showActions, setShowActions] = useState(false);
  const [assetSharesList, setAssetSharesList] = useState<
    Array<components["schemas"]["UserData"]>
  >([]);

  const timeoutRef = useRef(null);

  const { isMobile } = useResponsive();
  const { addToast } = useSuperToast();
  const { playerRef } = usePlayer();
  const { openModal } = useModal();
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const { getAssetSharedUsers, deleteUserAccess } = useAssets();

  const fetchOnLoad = useCallback(async () => {
    const sharesResponse = await getAssetSharedUsers(asset?.id);
    if (isApiErrorResponse(sharesResponse)) {
      console.error(sharesResponse.message);
    } else {
      setAssetSharesList(sharesResponse);
    }
  }, [getAssetSharedUsers, asset]);

  useEffect(() => {
    fetchOnLoad();
  }, [asset, fetchOnLoad]);

  useEffect(() => {
    if (!isMobile) {
      const handleMouseMove = () => {
        setShowActions(true);

        if (timeoutRef.current) clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
          setShowActions(false);
        }, 3000);
      };

      document.addEventListener("mousemove", handleMouseMove);

      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        clearTimeout(timeoutRef.current);
      };
    }
  }, []);

  const analogUsersModalClasses = classNames(styles.analogUsersModal);
  const analogUsersSheetClasses = classNames(styles.analogUsersSheet);

  const handleCopyLinkClick = (e) => {
    const username = user.username;
    const assetName = asset.name;
    const pathName = location.pathname;
    const protocol = window.location.protocol;
    const host = window.location.host;

    const assetLink = generateAssetLink(
      username,
      assetName,
      pathName,
      protocol,
      host,
      !isPopup
    );

    navigator.clipboard.writeText(assetLink);
    addToast("Your link has been copied", ToastTypes.SUCCESS);
  };

  const handleUnshare = async () => {
    const users = await getAssetSharedUsers(asset?.id);

    for (const user of Object.values(users)) {
      await deleteUserAccess(asset?.id, user.id);
    }

    setAssetSharesList([]);
    addToast("Unshared successfully", ToastTypes.SUCCESS);
  };

  const renderMobileShare = () => {
    if (Object.values(assetSharesList).length > 0)
      return (
        <Button
          size="large"
          color="primary"
          variant="text"
          type="button"
          className={`${styles.button} ${styles.unshare} super-button`}
          onClick={handleUnshare}
        >
          {"Unshare"}
        </Button>
      );

    return (
      <Button
        size="large"
        color="primary"
        variant="text"
        type="button"
        className={`${styles.button} ${styles.share} clear-cancel`}
        fullWidth
        onClick={() =>
          openBottomSheet({
            content: (
              <div>
                <SharedWithList
                  assetResource={asset}
                  isShortcut={!!asset?.asset}
                />
                <Button
                  onClick={() => {
                    closeBottomSheet();
                    fetchOnLoad();
                  }}
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  className="super-button"
                >
                  Close
                </Button>
              </div>
            ),
            classes: analogUsersSheetClasses
          })
        }
      >
        {"Share"}
      </Button>
    );
    // <Button
    //   size="large"
    //   color="primary"
    //   variant="text"
    //   type="button"
    //   className={`${styles.button} ${styles.unshare} super-button`}
    // >
    //   {"Unshare"}
    // </Button>
  };

  const render = () => {
    if (isMobile) {
      return (
        <div className={styles.mobileContainer}>
          <div className={styles.actionsContainer}>
            <button
              className={styles.actionButton}
              onClick={handleCopyLinkClick}
            >
              <img src="/images/share.svg" alt="Share" />
            </button>
            <button className={styles.actionButton}>
              <img src="/images/save.svg" alt="Save" />
            </button>
          </div>
          <div className={styles.shareContainer}>{renderMobileShare()}</div>
          <div className={styles.actionsContainer}>
            <button className={styles.actionButton} onClick={onRotate}>
              <img src="/images/rotate.svg" alt="Rotate" />
            </button>
            <button
              className={styles.actionButton}
              onClick={() => {
                playerRef.requestFullscreen();
              }}
            >
              <img src="/images/fullscreen.svg" alt="Fullscreen" />
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.wrapper}>
        <AnimatePresence>
          {showActions && (
            <motion.div
              initial={{ y: "300%" }}
              animate={{ y: "0%" }}
              exit={{ y: "300%" }}
              transition={{ duration: 0.4, ease: "easeInOut" }}
              className={styles.container}
            >
              <button
                className={styles.actionButton}
                onClick={handleCopyLinkClick}
              >
                <img src="/images/share.svg" alt="Share" />
              </button>
              <button className={styles.actionButton}>
                <img src="/images/save.svg" alt="Save" />
              </button>
              <div className={styles.shareContainer}>
                <Button
                  size="large"
                  color="primary"
                  variant="text"
                  type="button"
                  className={`${styles.button} ${styles.share} clear-cancel`}
                  onClick={() =>
                    openModal({
                      content: (
                        <SharedWithList
                          assetResource={asset}
                          isShortcut={!!asset?.asset}
                        />
                      ),
                      classes: analogUsersModalClasses
                    })
                  }
                >
                  {"Share"}
                </Button>
                <Button
                  size="large"
                  color="primary"
                  variant="text"
                  type="button"
                  className={`${styles.button} ${styles.unshare} super-button`}
                  onClick={handleUnshare}
                >
                  {"Unshare"}
                </Button>
              </div>
              <button className={styles.actionButton} onClick={onRotate}>
                <img src="/images/rotate.svg" alt="Rotate" />
              </button>
              <button
                className={styles.actionButton}
                onClick={() => {
                  playerRef.requestFullscreen();
                }}
              >
                <img src="/images/fullscreen.svg" alt="Fullscreen" />
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  return render();
}

export default ActionsBar;
