import React, { useState, useEffect, useRef, useMemo } from "react";
import { motion } from "framer-motion";
import styles from "./SideSheetTabs.module.scss";
import { usePreviewPanel } from "../shared/asset-previews/preview-panel/usePreviewPanel";
import SuperMap from "../SuperMap/SuperMap.component";
import ActivityLog from "./partials/ActivityLog/ActivityLog.component";
import SettingsPanel from "./partials/SettingsTab/SettingsTab.component";
import InformationTab from "./partials/InformationTab/InformationTab.component";
import { useAssets } from "@/contexts/assets/assets.hook";
import { useAuth } from "@/contexts/auth/auth.hook";
import { useSuperToast } from "../shared/super-toast/SuperToast.hook";
import { ToastTypes } from "../shared/super-toast/SuperToast.types";
import { useProfile } from "@/contexts/profile/profile.hook";
import { ACCESS_TYPE } from "@/types/contexts/share-context";
import { ViewState } from "react-map-gl";
import { isApiErrorResponse } from "@/lib/error";

const SideSheetTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState("information");
  const [activeIndicator, setActiveIndicator] = useState({ left: 0, width: 0 });
  const [viewState, setViewState] = useState<Partial<ViewState>>({
    longitude: 0,
    latitude: 0,
    zoom: 1
  });
  const markerRef = useRef(null);
  const { assets, currentAsset, getAssetDetails } = useAssets();
  const { userChannel } = useAuth();
  const { addToast } = useSuperToast();
  const { viewingProfile } = useProfile();

  const assetResource = useMemo(
    () =>
      assets.find((a) => {
        return a.id === currentAsset || a.asset_id === currentAsset;
      }),
    [currentAsset, assets]
  );

  const isShortcut = assetResource?.type == "shortcut";
  const isOwner =
    !isShortcut && assetResource?.access?.access_type === ACCESS_TYPE.Owner;
  const isManager =
    !isOwner &&
    ((isShortcut &&
      assetResource?.asset?.access?.access_type == ACCESS_TYPE.Manager) ||
      (viewingProfile &&
        assetResource?.access?.access_type == ACCESS_TYPE.Manager));

  const tabRefs = useRef<HTMLButtonElement[]>([]);
  const { setContent, closePreviewPanel } = usePreviewPanel();

  const tabs = [
    { id: "information", label: "Information" },
    ...(isOwner || isManager ? [{ id: "map", label: "Map" }] : []),
    ...(isOwner || isManager ? [{ id: "activity", label: "Activity" }] : []),
    ...(viewingProfile &&
    assetResource?.access?.access_type === ACCESS_TYPE.PublicNoAccess
      ? []
      : [{ id: "settings", label: "Settings" }])
  ];

  useEffect(() => {
    if (currentAsset) {
      window.Echo.channel(`asset.${currentAsset}.public`).listen(
        "AssetUpdated",
        async (data) => {
          if (data.visibility) {
            const asset = await getAssetDetails(currentAsset);
            if (isApiErrorResponse(asset) || asset.access.access_type === -1) {
              closePreviewPanel();
              addToast(
                "You have lost access to this file.",
                ToastTypes.PERMISSION_LOCK
              );
            }
          }
        }
      );
    }

    return () => {
      if (currentAsset) window.Echo.leave(`asset.${currentAsset}.public`);
    };
  }, [currentAsset]);

  useEffect(() => {
    userChannel?.listen("AssetUnshared", (e) => {
      if (
        e.asset_id === assetResource?.asset_id ||
        e.asset_id === assetResource.id
      ) {
        addToast(
          "You have lost access to this file.",
          ToastTypes.PERMISSION_LOCK
        );
        closePreviewPanel();
      }
    });
  }, []);

  useEffect(() => {
    if (!assetResource) {
      closePreviewPanel();
      return;
    }

    const tabContentMap = {
      information: (
        <InformationTab
          assetResource={assetResource}
          isShortcut={isShortcut}
          isOwner={isOwner}
          isManager={isManager}
        />
      ),
      map: (
        <SuperMap
          assetResource={assetResource}
          isShortcut={isShortcut}
          initialViewState={viewState}
          markerToFocus={markerRef}
        />
      ),
      activity: (
        <ActivityLog
          assetResource={assetResource}
          isShortcut={isShortcut}
          onClick={(e) => {
            const target = e.target as HTMLSpanElement;
            setViewState({
              latitude: +target.getAttribute("data-latitude"),
              longitude: +target.getAttribute("data-longitude"),
              zoom: 6
            });
            setActiveTab("map");
            console.log(target.id);
            markerRef.current = `${target.id}`;
          }}
        />
      ),
      settings: (
        <SettingsPanel
          assetResource={assetResource}
          isShortcut={isShortcut}
          isOwner={isOwner}
          isManager={isManager}
        />
      )
    };

    setContent(tabContentMap[activeTab]);
  }, [activeTab, setContent, assetResource, markerRef]);

  useEffect(() => {
    const updateIndicator = () => {
      const activeTabIndex = tabs.findIndex((tab) => tab.id === activeTab);
      const activeTabElement = tabRefs.current[activeTabIndex];

      if (activeTabElement) {
        const { offsetLeft, offsetWidth } = activeTabElement;
        setActiveIndicator({ left: offsetLeft, width: offsetWidth });
      }
    };

    updateIndicator();

    const handleResize = () => {
      requestAnimationFrame(updateIndicator);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeTab]);

  // Hide tabs if only info tab is visible
  if (tabs.length === 1) {
    return null;
  }

  return (
    <div className={styles.sideSheetTabs}>
      <div className={styles.tabs}>
        <motion.div
          className={styles.activeBackground}
          animate={activeIndicator}
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 30
          }}
          style={{
            left: activeIndicator.left,
            width: activeIndicator.width
          }}
        />

        {tabs.map((tab, index) => (
          <button
            key={tab.id}
            ref={(element) => (tabRefs.current[index] = element!)}
            className={`${styles.infoTab} ${
              activeTab === tab.id ? styles.active : ""
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SideSheetTabs;
