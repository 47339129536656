import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./auth.context";
import {
  getAccessToken,
  removeAccessToken,
  storeAccessToken
} from "@/lib/network";
import { getUserInfo } from "@/services/users-service";
import {
  authenticSignIn,
  getAuthenticUserDetails
} from "@/services/auth-service";
import { isServicesError } from "@/lib/services";
import {
  AuthContextType,
  AuthProviderProps
} from "@/types/contexts/user-context";
import { SignInPayload } from "@/types/services/auth-service";
import { PusherPrivateChannel } from "laravel-echo/dist/channel";
import { components } from "@/types/beta.schema";
import { createSocketConnection } from "@/services/websockets";

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const knownUsers: Record<string, components["schemas"]["UserData"]> =
    useMemo(() => {
      return {};
    }, [user?.id]);
  const [userChannel, setUserChannel] = useState<PusherPrivateChannel>(null);
  const addKnownUser = (user: components["schemas"]["UserData"]) => {
    if (!knownUsers[user.id]) {
      knownUsers[user.id] = user;
    }
  };
  const navigate = useNavigate();

  const retrieveUserInfo = useCallback(async () => {
    const token = getAccessToken();
    if (token) {
      try {
        const { data } = await getUserInfo();
        setUser(data.data);
      } catch (error) {
        console.error("Failed to retrieve user info:", error);
        removeAccessToken();
        navigate("/");
      }
    }
    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    createSocketConnection();
  }, [user, window?.Echo]);

  useEffect(() => {
    retrieveUserInfo();
  }, [retrieveUserInfo]);

  useEffect(() => {
    if (user) {
      addKnownUser(user);
    }
  }, [user]);

  useEffect(() => {
    if (!userChannel) {
      joinUserChannel();
    }
    window.onbeforeunload = leaveUserChannel;
    return () => {
      window.onbeforeunload = null;
    };
  }, [user, window?.Echo]);

  const signIn = async (data: SignInPayload): Promise<void> => {
    const { email, password } = data;

    const payload: SignInPayload = {
      email,
      password
    };

    const signInRes = await authenticSignIn(payload);

    if (isServicesError(signInRes)) {
      throw new Error(signInRes.message);
    }

    const { accessToken, userId } = signInRes;

    storeAccessToken(accessToken);

    const userDetailsRes = await getAuthenticUserDetails({
      userId
    });

    if (isServicesError(userDetailsRes)) {
      removeAccessToken();
      throw new Error(userDetailsRes.message);
    }

    const { data: info } = await getUserInfo();
    setUser(info.data);
  };

  const signOut = () => {
    removeAccessToken();
    setUser(null);
  };

  const joinUserChannel = () => {
    if (window?.Echo && user) {
      setUserChannel(window.Echo.private(`user.${user.id}`));
    }
  };

  const leaveUserChannel = () => {
    if (window?.Echo && user) {
      setUserChannel(null);
      window.Echo.leave(`user.${user.id}`);
    }
  };

  const value: AuthContextType = {
    user,
    setUser,
    signIn,
    signOut,
    knownUsers,
    addKnownUser,
    userChannel
  };

  // Shows nothing while loading -- bg only
  if (loading) return null;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
