import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./BackgroundVideo.module.scss";
import { classNames } from "primereact/utils";
import { useProfile } from "@/contexts/profile/profile.hook";
import { useAuth } from "@/contexts/auth/auth.hook";
import {
  applyMobileOnlyBackgroundBlur,
  removeMobileOnlyBackgroundBlur
} from "@/lib/theme";

function BackgroundVideo() {
  const defaultVideoSrc = "/videos/iridescence.mp4";
  const videoExtensions = ["mp4", "m4p", "ogv", "ogg", "webm"];
  const location = useLocation();
  const { user } = useAuth();
  const { getBackground } = useProfile();
  const [backgroundSrc, setBackgroundSrc] = useState(defaultVideoSrc);
  const [isSrcVideo, setIsSrcVideo] = useState(true);
  const videoRef = useRef(null);

  // TODO: This can be optimized to only use background from either `profile`
  //  or `user`.

  const isVideo = (src) => {
    const ext = src.split(/[?]+/).shift().split(/[.]+/).pop();
    setIsSrcVideo(videoExtensions.indexOf(ext) > -1);
    return videoExtensions.indexOf(ext) > -1;
  };

  const getVideoBackgroundType = (fileName) => {
    const ext = fileName.split(/[?]+/).shift().split(/[.]+/).pop();
    return "video/" + ext;
  };

  useEffect(() => {
    if (location.pathname == "/account") {
      applyMobileOnlyBackgroundBlur();
    } else {
      removeMobileOnlyBackgroundBlur();
    }
    return () => removeMobileOnlyBackgroundBlur();
  }, [location.pathname, user]);

  useEffect(() => {
    if (!user?.profile?.background) {
      setBackgroundSrc(defaultVideoSrc);
    } else {
      setBackgroundSrc(getBackground());
    }
  }, [user]);

  useEffect(() => {
    if (isVideo(backgroundSrc)) {
      videoRef.current?.load();
    }
  }, [backgroundSrc]);

  useEffect(() => {
    if (isSrcVideo) {
      videoRef.current?.load();
    }
  }, [isSrcVideo]);

  return (
    <div className={styles.backgroundVideoWrapper}>
      <div
        className={classNames(
          styles.backgroundOverlay,
          "background-video-overlay"
        )}
      ></div>
      {isSrcVideo ? (
        <video
          className={styles.backgroundVideo}
          autoPlay
          loop
          muted
          playsInline
          ref={videoRef}
        >
          <source
            src={backgroundSrc}
            type={getVideoBackgroundType(backgroundSrc)}
          />
        </video>
      ) : (
        <img className={styles.backgroundVideo} src={backgroundSrc} />
      )}
    </div>
  );
}

export default BackgroundVideo;
