import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SuccessTemplate.module.scss";

function SuccessTemplate() {
  const navigate = useNavigate();

  return (
    <div className={styles.baseWrapper}>
      <span className="description">
        Your account is successfully verified. Tap the button below to sign in
        and continue.
      </span>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        className="super-button"
        onClick={() => navigate("/")}
      >
        Lets go
      </Button>
    </div>
  );
}

export default SuccessTemplate;
