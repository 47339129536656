import React, { useState } from "react";
import ProfileContext from "./profile.context";
import {
  ProfileContextType,
  ProfileProviderProps
} from "@/types/contexts/profile-context";
import { updateProfile } from "@/services/profile-service";
import { components } from "@/types/beta.schema";
import { useAuth } from "../auth/auth.hook";
import { UserDetails } from "@/types/contexts/user-context";

// TODO: This can be heavily refactored when we can update the user instead of the profile

export const ProfileProvider = ({ children }: ProfileProviderProps) => {
  const [viewingProfile, setViewingProfile] = useState<null | UserDetails>(
    null
  );

  const { user, setUser } = useAuth();

  const updateAvatar = async (
    payload: Pick<components["schemas"]["UpdateProfileData"], "avatar">
  ) => {
    const { data, error } = await updateProfile(payload);
    if (data) {
      setUser((prevUser) => {
        return {
          ...prevUser,
          profile: data.data
        };
      });
    } else {
      return { message: error.message };
    }
  };

  const updateBackground = async (
    payload: Pick<components["schemas"]["UpdateProfileData"], "background">
  ) => {
    const { data, error } = await updateProfile(payload);
    if (data) {
      setUser((prevUser) => {
        return {
          ...prevUser,
          profile: data.data
        };
      });
    } else {
      return { message: error.message };
    }
  };

  const getAvatar = () => {
    return user?.profile?.avatar || "/images/logo-yellow-bg.png";
  };

  const getBackground = () => {
    return user?.profile?.background || "/videos/iridescence.mp4";
  };

  const value: ProfileContextType = {
    updateAvatar,
    updateBackground,
    getAvatar,
    getBackground,
    setViewingProfile,
    viewingProfile
  };

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};
