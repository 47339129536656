import React, { useState } from "react";
import styles from "./CodeScreen.module.scss";
import { Button } from "@mui/material";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { verifySignUp } from "@/services/auth-service";
import { CodeScreenProps } from "./CodeScreen.types";
import { isServicesError } from "@/lib/services";
import { RegistrationScreen } from "@/pages/register/register.types";

function CodeScreen({ id, setScreen }: CodeScreenProps) {
  const [code, setCode] = useState(["", "", "", "", "", ""]);

  const { addToast } = useSuperToast();

  const handleClick = () => {
    // TO-DO: Add resend code logic here
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const codeString = code.join("");

      const res = await verifySignUp({ id, confirmationCode: codeString });

      if (isServicesError(res)) {
        console.error(res);
        return addToast(res.message, ToastTypes.FAIL);
      }

      return setScreen(RegistrationScreen.CONFIRMATION);
    } catch (error) {
      console.error(error);

      return addToast(error.message, ToastTypes.FAIL);
    }
  };

  const handleChange = (event, index: number) => {
    const value = event.target.value;
    const newCode = [...code];
    newCode[index] = value;

    setCode(newCode);

    if (value && index < 5) {
      document.getElementById(`code-input-${index + 1}`).focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardCode = event.clipboardData.getData("Text").slice(0, 6); // Note: JG | Limit to 6 characters -- Just in case
    const _code = clipboardCode.split("");

    setCode(_code);
  };

  return (
    <div className={styles.content}>
      <div className={styles.dialog}>
        <span className="description">
          We have just sent a confirmation code to your email.
        </span>
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.inputs}>
          {code.map((value, index) => {
            return (
              <input
                id={`code-input-${index}`}
                key={`code-input-${index}`}
                type="text"
                maxLength={1}
                className={styles.confirmationCodeInput}
                value={value}
                onChange={(e) => handleChange(e, index)}
                onPaste={handlePaste}
              />
            );
          })}
        </div>

        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          className="super-button"
        >
          Confirm
        </Button>
      </form>

      <button className={styles.description} onClick={handleClick}>
        I haven&lsquo;t received a confirmation code
      </button>
    </div>
  );
}

export default CodeScreen;
