import UAParser from "ua-parser-js";
import axios from "axios";
import { isBrowser } from "@/utils";
import {
  ISupportedDevice,
  IDeviceInfo
} from "@/components/shared/ShakaPlayer/ShakaPlayer.interface";

const supportedDeviceBrowser: ISupportedDevice[] = [
  { os: "Mac OS", browser: "Safari" },
  { os: "Mac OS", browser: "Chrome" },
  { os: "Mac OS", browser: "Edge" },
  { os: "Mac OS", browser: "Opera" },
  { os: "iOS", browser: "Mobile Safari" },
  { os: "iOS", browser: "Chrome" },
  { os: "iOS", browser: "Edge" },
  { os: "iOS", browser: "Opera" },
  { os: "Android", browser: "Chrome" },
  { os: "Android", browser: "Edge" },
  { os: "Android", browser: "Opera" },
  { os: "Android", browser: "Samsung Internet" },
  { os: "Windows", browser: "Edge" },
  { os: "Windows", browser: "Edge Chromium" },
  { os: "Windows", browser: "Chrome" },
  { os: "Windows", browser: "Opera" }
];

const getDeviceInformation = (userAgent?: string): IDeviceInfo => {
  if (!userAgent && isBrowser) {
    userAgent = navigator.userAgent;
  }

  const parser = new UAParser();
  const result = parser.setUA(userAgent).getResult();

  return {
    name: result.browser.name,
    version: result.browser.version,
    os: result.os.name,
    isAppleDevice: ["Mac OS", "iOS"].includes(result.os.name),
    isWindowsDevice: result.os.name.toLowerCase().includes("windows")
  };
};

interface DeviceCapabilities {
  ipAddress: string;
  isLocationAllowed: boolean;
  device: IDeviceInfo;
}

const fetchDeviceValidation = async (): Promise<DeviceCapabilities> => {
  // const allowedContinents = ["NA", "EU"];
  try {
    // Get user agent from the browser
    const userAgent = navigator.userAgent;
    const device = getDeviceInformation(userAgent);

    // Get IP address using a third-party service
    const ipResponse = await axios.get("https://api.ipify.org?format=json");
    const ipAddress = ipResponse.data.ip;

    const isLocationAllowed = true;

    // if (ENABLE_GEO_RESTRICTION) {
    //   const locationResponse = await axios.get(`https://ipinfo.io/${ipAddress}?token=${IPINFO_API_KEY}`);
    //   const location = locationResponse.data;
    //   isLocationAllowed = allowedContinents.includes(location.continent);
    // }

    return {
      ipAddress,
      device,
      isLocationAllowed
    };
  } catch (error) {
    console.error(error);
    throw new Error(
      `Failed to validate user device: ${error.message || error}`
    );
  }
};

const checkDeviceSupport = (device: IDeviceInfo) => {
  const isDeviceSupported = supportedDeviceBrowser.some(
    (s) =>
      s.os.toLowerCase() === device.os.toLowerCase() &&
      s.browser.toLowerCase() === device.name.toLowerCase()
  );
  const supportedDevice = supportedDeviceBrowser.find(
    (s) => s.os.toLowerCase() === device.os.toLowerCase()
  );

  return {
    isDeviceSupported,
    supportedDevice
  };
};

export { getDeviceInformation, fetchDeviceValidation, checkDeviceSupport };
