import React, { useState } from "react";
import { Marker, Popup } from "react-map-gl";
import { CustomMarkerProps } from "./CustomMarker.types";
import styles from "./CustomMarker.module.scss";
import useFetchUserMiddleware from "@/hooks/useFetchUserMiddleware";
import { getUserProfileImage } from "@/lib/user";

const CustomMarker: React.FC<CustomMarkerProps> = (
  props: CustomMarkerProps
) => {
  const {
    id,
    longitude,
    latitude,
    className,
    city,
    region,
    ip,
    country,
    isPopupOpen,
    causer_id,
    isHistorical
  } = props;
  const [popupOpen, setPopupOpen] = useState(isPopupOpen ?? false);
  const user = useFetchUserMiddleware(causer_id);
  const handleClick = (e) => {
    e.originalEvent.stopPropagation();
    setPopupOpen((prev) => !prev);
  };

  return (
    <Marker
      key={`marker-${id}`}
      longitude={longitude}
      latitude={latitude}
      anchor="bottom"
      className={`${className ?? ""} marker-${id}`} //Markers in react-map-gl don't seem to allow dataset attributes or ids. Hacky way to uniquely identify them.
      onClick={handleClick}
    >
      {!isHistorical ? (
        <img
          src={getUserProfileImage(user?.profile?.avatar)}
          className={styles.markerPfp}
        />
      ) : (
        <div className={styles.historicalMarker}></div>
      )}
      {popupOpen && (
        <Popup
          key={`popup-${id}`}
          data-id={`popup-${id}`}
          longitude={longitude}
          latitude={latitude}
          onClose={() => setPopupOpen(false)}
          closeButton={true}
          className={styles.popup}
          offset={!isHistorical ? 48 : 16}
        >
          <div className={styles.customPopup}>
            <span>
              {`${city}, ${region}, ${country}`}
              <p
                className={styles.username}
              >{`${user?.username ?? "Guest"}`}</p>
              <p className={styles.ip}>{`${ip}`}</p>
            </span>
          </div>
        </Popup>
      )}
    </Marker>
  );
};

export default CustomMarker;
