import { SortOrder } from "primereact/datatable";
import { getSortOrder } from "@/lib/sort";
import { SortField } from "./helpers.types";

export const sortQuery = (sortField: SortField, sortOrder: SortOrder) => {
  if (!sortField) return "";

  return `${getSortOrder(sortOrder)}${sortField}`;
};

export const paginatedQuery = (link: null | string): number => {
  if (!link) {
    return 1;
  }

  return parseInt(link?.substring(link.indexOf("=") + 1, link.length));
};
