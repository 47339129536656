import React, { useCallback, useEffect } from "react";
import DropZone from "@/components/DropZone/DropZone.component";
import { useState } from "react";
import SecureFileContent from "./partials/secure-file";
import { SecureFileProvider } from "./partials/secure-file/secure-file.provider";
import { useModal } from "@/components/shared/Modal/useModal";
import { useBottomSheet } from "@/components/shared/BottomSheet/useBottomSheet";
import useResponsive from "@/hooks/useResponsive";
import withAuth from "@/hoc/withAuth";
import BreadcrumbsTemplate from "./partials/breadcrumbs-template/BreadcrumbsTemplate.component";
import { DASHBOARD_FILTERS } from "@/types/pages/dashboard";
import FileListFilter from "@/components/FileListFilter/FileListFilter.component";
import CreateFolderModalTemplate from "./partials/create-folder-modal-template/CreateFolderModalTemplate.component";
import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./partials/SuperTables/SuperTables.module.scss";
import FilesSuperTable from "./partials/SuperTables/FilesSuperTable/FilesSuperTable.component";
import UsersSuperTable from "./partials/SuperTables/UsersSuperTable/UsersSuperTable.component";
import AssetSearch from "./partials/AssetSearch/AssetSearch.component";
import GroupsSuperTable from "./partials/SuperTables/GroupsSuperTable/GroupsSuperTable.component";
import UserSearch from "@/components/screens/Dashboard/partials/UserTableSearch/UserSearch.component";
import { components } from "@/types/beta.schema";
import { getSharedUsersOnAssetsList } from "@/services/users-service";
import { AnimatePresence, motion } from "framer-motion";
import { breadcrumbsAnimation } from "@/components/screens/Dashboard/data/breadcrumbs";
import { useAssets } from "@/contexts/assets/assets.hook";

function Dashboard() {
  // Temporary placeholder
  const [resourcePresent] = useState(true);

  const { openModal } = useModal();
  const { isMobile } = useResponsive();
  const { openBottomSheet } = useBottomSheet();
  const [activeFilter, setActiveFilter] = useState<DASHBOARD_FILTERS>(
    DASHBOARD_FILTERS.FILES
  );
  const [mobileSortTrigger, setMobileSortTrigger] = useState(false);
  const { folderState } = useAssets();

  const handleUploadComplete = ({ files }) => {
    if (isMobile) {
      return openBottomSheet({
        content: (
          <SecureFileProvider>
            <SecureFileContent files={files} />
          </SecureFileProvider>
        ),
        showActions: false
      });
    }

    return openModal({
      content: (
        <SecureFileProvider>
          <SecureFileContent files={files} />
        </SecureFileProvider>
      ),
      showActions: false
    });
  };

  const renderSearch = () => {
    switch (activeFilter) {
      case DASHBOARD_FILTERS.FILES:
        return <AssetSearch />;
      case DASHBOARD_FILTERS.GROUPS:
        // TODO: Add GroupSearch when groups are added
        break;
      case DASHBOARD_FILTERS.USERS:
        return <UserSearch />;

      default:
        break;
    }
  };

  const renderBreadcrumbs = () => {
    return (
      <AnimatePresence mode="wait">
        {folderState.currentFolder && (
          <motion.div
            key={folderState.currentFolder.name}
            initial="exit"
            animate="enter"
            exit="exit"
            variants={breadcrumbsAnimation}
          >
            <BreadcrumbsTemplate />
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  const tableHeader = (
    <div className={styles.tableHeader}>
      <FileListFilter
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
      <div className={styles.headerActions}>
        {renderSearch()}

        <button
          className={styles.createFolderButton}
          onClick={() =>
            openModal({
              content: <CreateFolderModalTemplate />,
              classes: styles.modalWidth,
              showActions: false
            })
          }
        >
          <FontAwesomeIcon icon={faFolderPlus} size={"lg"} />
          <span>Create folder</span>
        </button>
        <button
          className={styles.mobileSortButton}
          onClick={() => setMobileSortTrigger(true)}
        >
          <span>Sort</span>
          <img src="/images/sort-alt.svg" alt="sorting icon" />
        </button>
      </div>
    </div>
  );

  const [users, setUsers] = useState<Array<components["schemas"]["UserData"]>>(
    []
  );
  // TODO: Error handling
  const getUsers = useCallback(async () => {
    const response = await getSharedUsersOnAssetsList();

    if (response.data.data) {
      setUsers(response.data.data);
    } else {
      console.error("fetch users failed");
    }
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    if (activeFilter === DASHBOARD_FILTERS.USERS) getUsers();
  }, [activeFilter]);

  const renderTable = () => {
    switch (activeFilter) {
      case DASHBOARD_FILTERS.FILES:
        return (
          <FilesSuperTable
            tableHeader={tableHeader}
            mobileSortState={{ mobileSortTrigger, setMobileSortTrigger }}
          />
        );
      case DASHBOARD_FILTERS.GROUPS:
        return (
          <GroupsSuperTable
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
        );
      case DASHBOARD_FILTERS.USERS:
        return (
          <UsersSuperTable
            tableHeader={tableHeader}
            mobileSortState={{ mobileSortTrigger, setMobileSortTrigger }}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <DropZone
        resourcePresent={resourcePresent}
        currentNumberOfFiles={0}
        onUploadComplete={handleUploadComplete}
      />
      <BreadcrumbsTemplate />
      {renderTable()}
    </>
  );
}

export default withAuth(Dashboard);
