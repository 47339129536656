export enum SCREEN_TYPES {
  LOGO = "LOGO",
  LOGIN = "LOGIN",
  DASHBOARD_ROOT = "DASHBOARD_ROOT",
  DASHBOARD_PROFILE = "DASHBOARD_PROFILE",
  PROFILE = "PROFILE",
  ACCOUNT = "ACCOUNT",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  FULLSCREEN_CARD_PREVIEW = "FULLSCREEN_CARD_PREVIEW",
  FULLSCREEN_ANALOG_PREVIEW = "FULLSCREEN_ANALOG_PREVIEW"
}

export type CurrentScreenProfile = {
  path?: string;
  username?: string;
  owner_id?: string;
  folder_id?: string;
};

export interface ScreenContextType {
  currentScreen: SCREEN_TYPES;
  setCurrentScreen: (screen: SCREEN_TYPES) => void;

  currentScreenProfile: CurrentScreenProfile | null;
  setCurrentScreenProfile: (profile: CurrentScreenProfile | null) => void;
}
