import React from "react";
import { isApiErrorResponse } from "@/lib/error";
import styles from "./BackgroundChange.module.scss";
import BackgroundDropZone from "@/components/BackgroundDropZone/BackgroundDropZone.component";
import { useBottomSheet } from "@/components/shared/BottomSheet/useBottomSheet";
import { useModal } from "@/components/shared/Modal/useModal";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { useProfile } from "@/contexts/profile/profile.hook";
import withAuth from "@/hoc/withAuth";
import { Button } from "@mui/material";
import useResponsive from "@/hooks/useResponsive";
import classNames from "classnames";

function BackgroundChange() {
  const { addToast } = useSuperToast();
  const { updateBackground } = useProfile();
  const { isMobile } = useResponsive();
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const { openModal, closeModal } = useModal();
  const modalClasses = classNames(styles.modalWidth, styles.modalPosition);

  const handleResetBackgroundClick = async () => {
    const backgroundUpdateResponse = await updateBackground({
      background: null
    });

    if (isApiErrorResponse(backgroundUpdateResponse)) {
      console.error(backgroundUpdateResponse.message);
      addToast(
        "An error occurred while attempting to reset your background.",
        ToastTypes.FAIL
      );
    } else {
      addToast("Your background was successfully reset.", ToastTypes.SUCCESS);
    }
  };

  const handleCancelClick = () => {
    if (isMobile) {
      return closeBottomSheet();
    } else {
      closeModal();
    }
  };

  const handleBackgroundChangeToggle = () => {
    if (isMobile) {
      return openBottomSheet({
        content: (
          <div className={styles.mobileMenuContainer}>{renderContent()}</div>
        ),
        showActions: false
      });
    } else {
      return openModal({
        content: renderContent(),
        classes: modalClasses,
        showActions: false
      });
    }
  };

  const renderContent = () => {
    return (
      <div className={styles.backgroundChangeContainer}>
        <div className={styles.backgroundChangeHeader}>
          <span>Change Background</span>
          <div className={`${styles.backgroundChangeDescription} text-dimmed`}>
            Set a new background for SuperFile. <br /> You can upload any image
            or video up to <b>10 MB</b> in size.
          </div>
        </div>
        <BackgroundDropZone />
        <div className={styles.backgroundChangeFooter}>
          <Button
            component="label"
            color="success"
            variant="text"
            size="large"
            disableRipple
            className={`${styles.resetBackgroundButton}`}
            onClick={handleResetBackgroundClick}
          >
            Reset Background
          </Button>
          <Button
            component="label"
            color="info"
            variant="text"
            size="large"
            disableRipple
            className={`${styles.cancelBackgroundButton}`}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.updateBackground}>
      <Button
        component="label"
        variant="outlined"
        size="medium"
        id={styles.updateBackgroundButton}
        onClick={handleBackgroundChangeToggle}
      >
        Change background
      </Button>
    </div>
  );
}

export default withAuth(BackgroundChange);
