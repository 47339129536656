import React from "react";
import { BaseIconProps } from "./icon.types";

function ViewsIcon({ color = "white", className }: BaseIconProps) {
  const classes = className ? `${className}` : "";

  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <g clipPath="url(#clip0_2699_1981325)">
        <path
          d="M15.9354 8.46252C15.9127 8.41138 15.3649 7.19623 14.1553 5.98657C12.5397 4.37067 10.4976 3.5166 8.25 3.5166C6.00238 3.5166 3.96033 4.37066 2.34467 5.98657C1.13507 7.19623 0.587341 8.41138 0.564636 8.46252C0.52202 8.55842 0.5 8.66218 0.5 8.76712C0.5 8.87206 0.52202 8.97582 0.564636 9.07172C0.587403 9.12286 1.13513 10.3378 2.34473 11.5472C3.96039 13.1627 6.00238 14.0166 8.25 14.0166C10.4976 14.0166 12.5396 13.1627 14.1553 11.5472C15.3649 10.3378 15.9126 9.12286 15.9354 9.07172C15.978 8.97582 16 8.87206 16 8.76712C16 8.66218 15.978 8.55842 15.9354 8.46252ZM13.0571 10.5238C11.7166 11.8461 10.0992 12.5166 8.25 12.5166C6.40076 12.5166 4.78339 11.8461 3.44287 10.5238C2.91574 10.0022 2.4622 9.41122 2.09479 8.76709C2.4622 8.12278 2.91576 7.53158 3.44293 7.00983C4.78345 5.68719 6.40076 5.0166 8.25 5.0166C10.0992 5.0166 11.7166 5.68719 13.0571 7.00983C13.5842 7.53155 14.0378 8.1227 14.4052 8.76697C14.0378 9.41115 13.5843 10.0022 13.0571 10.5238ZM8.25 6.01709C7.7061 6.01709 7.17442 6.17837 6.72218 6.48055C6.26995 6.78272 5.91747 7.21221 5.70933 7.71471C5.50119 8.21721 5.44673 8.77014 5.55284 9.30359C5.65895 9.83704 5.92086 10.327 6.30546 10.7116C6.69005 11.0962 7.18005 11.3581 7.7135 11.4642C8.24695 11.5704 8.79988 11.5159 9.30238 11.3078C9.80488 11.0996 10.2344 10.7471 10.5365 10.2949C10.8387 9.84267 11 9.31099 11 8.76709C10.9992 8.038 10.7092 7.339 10.1936 6.82346C9.67809 6.30791 8.97909 6.01791 8.25 6.01709ZM8.25 10.0171C8.00277 10.0171 7.7611 9.94378 7.55554 9.80643C7.34998 9.66908 7.18976 9.47385 7.09515 9.24544C7.00054 9.01704 6.97579 8.7657 7.02402 8.52323C7.07225 8.28075 7.1913 8.05802 7.36612 7.88321C7.54093 7.70839 7.76366 7.58934 8.00614 7.54111C8.24861 7.49288 8.49995 7.51763 8.72835 7.61224C8.95676 7.70685 9.15199 7.86706 9.28934 8.07263C9.42669 8.27819 9.5 8.51986 9.5 8.76709C9.49963 9.0985 9.36782 9.41623 9.13348 9.65057C8.89914 9.88491 8.58141 10.0167 8.25 10.0171Z"
          fill="#ABABAB"
        />
      </g>
      <defs>
        <clipPath id="clip0_2699_1981325">
          <rect
            width="16"
            height="16"
            fill="white"
            stroke={color}
            transform="translate(0.25 0.767578)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ViewsIcon;
