import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { usePreviewPanel } from "./usePreviewPanel";
import SideSheetTabs from "@/components/SideSheetTabs/SideSheetTabs.component";
import styles from "./PreviewPanel.module.scss";
import BackButton from "@/components/BackButton/BackButton";
import useTrapFocus from "@/hooks/useTrapFocus";

function PreviewPanel({
  previewPanelRef
}: {
  previewPanelRef: React.RefObject<HTMLDivElement>;
}) {
  const { isOpen, content, closePreviewPanel } = usePreviewPanel();

  useTrapFocus({
    isOpen,
    handleClose: closePreviewPanel,
    ref: previewPanelRef
  });

  const panelVariants = {
    hidden: { opacity: 0, x: "100%" },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: "100%" }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className={styles.overlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={closePreviewPanel}
          />
          <motion.div
            ref={previewPanelRef}
            className={styles.sheet}
            onClick={(e) => e.stopPropagation()}
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={panelVariants}
            transition={{
              duration: 0.5,
              type: "spring",
              damping: 25,
              stiffness: 150
            }}
          >
            <div className={styles.sheetContent}>
              <BackButton onClick={closePreviewPanel} text={"Back"} />
              <SideSheetTabs />
              <div className={styles.wrapper}>{content}</div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}

export default PreviewPanel;
