/* eslint-disable react/react-in-jsx-scope */
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BackgroundVideo from "./components/BackgroundVideo/BackgroundVideo.component";
import ForgotPassword from "./pages/forgot-password/index.page";
import RegisterPage from "./pages/register/register.page";
import SharedProviders from "./contexts/shared-providers/SharedProviders.provider";

import PageLayout from "./layouts/PageLayout";

function App() {
  return (
    <BrowserRouter>
      <div className="content">
        <SharedProviders>
          <BackgroundVideo />
          <Routes>
            <Route element={<PageLayout />}>
              <Route path="/" />
              <Route path="/account" />
              <Route path="/:username/*" />
            </Route>
            <Route path="/forgot-password" Component={ForgotPassword} />
            <Route path="register" Component={RegisterPage} />
          </Routes>
        </SharedProviders>
      </div>
    </BrowserRouter>
  );
}

export default App;
