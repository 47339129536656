import React, { useState, useEffect, useRef } from "react";
import PreviewPanelContext from "./PreviewPanel.context";
import {
  PreviewPanelContextType,
  PreviewPanelProviderProps,
  OpenPreviewPanelParams
} from "./PreviewPanel.types";
import PreviewPanel from "./PreviewPanel.component";
import { useAssets } from "@/contexts/assets/assets.hook";

function PreviewPanelProvider({ children }: PreviewPanelProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<React.ReactNode | null>(null);
  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(
    null
  );

  const { leaveAssetChannel } = useAssets();
  const previewPanelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const openPreviewPanel = (params: OpenPreviewPanelParams) => {
    const { content } = params;

    setTriggerElement(document.activeElement as HTMLElement);
    setContent(content);
    setIsOpen(true);
  };

  const closePreviewPanel = () => {
    setContent(null);
    setIsOpen(false);
    leaveAssetChannel();
    triggerElement?.focus();
  };

  const value: PreviewPanelContextType = {
    isOpen,
    content,
    openPreviewPanel,
    closePreviewPanel,
    setContent,
    previewPanelRef
  };

  return (
    <PreviewPanelContext.Provider value={value}>
      {children}
      <PreviewPanel previewPanelRef={previewPanelRef} />
    </PreviewPanelContext.Provider>
  );
}

export default PreviewPanelProvider;
