/**
 * @param {string} name - The file name to be validated.
 * @returns {isValid: boolean, message: string} - An object with an error message for invalid names and an isValid value of false,
 * or isValid value of true with an empty message.
 */
export const isValidFileName = (name: string) => {
  if (!name) {
    return { isValid: false, message: "Please enter a name." };
  } else if (name.length > 30) {
    return {
      isValid: false,
      message: "Please enter a name shorter than 30 characters."
    };
  } else {
    return { isValid: true, message: "" };
  }
};
