import React, { useState } from "react";
import UsersContext from "./users.context";
import { getSharedUsersOnAssetsList } from "@/services/users-service";
import { components, operations } from "@/types/beta.schema";
import { isApiErrorResponse } from "@/lib/error";
import { UserProviderProps, UserContextType } from "./users.types";

export const UsersProvider = ({ children }: UserProviderProps) => {
  const [users, setUsers] = useState<Array<components["schemas"]["UserData"]>>(
    []
  );

  // Search Query
  const [searchQuery, setSearchQuery] = useState<string>("");

  const refresh = async (
    payload?: operations["users.index"]["parameters"]["query"],
    reload?: boolean
  ) => {
    const sortPayload = {
      ...payload,
      "filter[search]": payload?.["filter[search]"] ?? searchQuery
    };
    const fetchedUsers = await fetchSharedUsers(sortPayload);
    if (isApiErrorResponse(fetchedUsers)) {
      console.error(fetchedUsers.message);
    } else {
      if (!reload) {
        setUsers((prev) => {
          const merged = [...prev, ...fetchedUsers.data];

          // Remove duplicates based on unique ID
          const uniqueUsers = Array.from(
            new Map(merged.map((item) => [item.id, item])).values()
          );

          return uniqueUsers;
        });
      } else {
        setUsers(fetchedUsers.data);
      }
    }
  };

  const fetchSharedUsers = async (
    payload?: operations["shares.index"]["parameters"]["query"]
  ) => {
    const { data, error } = await getSharedUsersOnAssetsList(payload);
    return data ? data : { message: error.message };
  };

  const value: UserContextType = {
    users,
    setUsers,
    refresh,
    searchQuery,
    setSearchQuery
  };

  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
};
