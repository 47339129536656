import { useFormik } from "formik";
import React from "react";
import { validationSchema } from "./data/form";
import { ResetPasswordPayload } from "@/types/services/auth-service";
import { Button, TextField } from "@mui/material";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import { Step1Props } from "@/types/pages/forgot-password";
import { resetPassword } from "@/services/auth-service";
import { isServicesError } from "@/lib/services";

function Step1({ resetId, goToConfirmationScreen }: Step1Props) {
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const payload: ResetPasswordPayload = {
          id: resetId,
          password: values.password
        };

        const res = await resetPassword(payload);

        if (isServicesError(res)) {
          // Note: JG | Add error toast here
          return console.error(res);
        }

        goToConfirmationScreen();
      } catch (error) {
        // Note: JG | Add error toast here
        console.error(error);
      }
    }
  });
  return (
    <div className={styles.container}>
      <div className={styles.dialog}>
        <div className="title">Set new password</div>
        <div className="description">
          To create a secure password, please include at least 8 characters with
          a mix of uppercase and lowercase letters, a number, and one special
          character (e.g., !@#$%^&*).
        </div>
      </div>

      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          className="text-input"
          placeholder="Password"
          type="password"
        />
        <TextField
          fullWidth
          id="passwordConfirmation"
          name="passwordConfirmation"
          value={formik.values.passwordConfirmation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.passwordConfirmation &&
            Boolean(formik.errors.passwordConfirmation)
          }
          helperText={
            formik.touched.passwordConfirmation &&
            formik.errors.passwordConfirmation
          }
          className="text-input"
          placeholder="Confirm Password"
          type="password"
        />
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          className="super-button"
        >
          Reset Password
        </Button>
      </form>

      <Link className={styles.back} to="/">
        Back to log in
      </Link>
    </div>
  );
}

export default Step1;
