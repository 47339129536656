import React, { useState } from "react";
import useResponsive from "@/hooks/useResponsive";
import styles from "./ProfileSectionTemplate.module.scss";
import { Button } from "@mui/material";
import LinkIcon from "@/components/icons/LinkIcon";
import ProfileStatsTemplate from "../profile-stats-template/ProfileStatsTemplate.component";
import ProfileAction from "@/components/ProfileAction/ProfileAction";
import { IconName } from "@/components/icons/icon.types";
import { useProfile } from "@/contexts/profile/profile.hook";
import { getUserProfileImage } from "@/lib/user";
import BackButton from "@/components/BackButton/BackButton";
import { useAuth } from "@/contexts/auth/auth.hook";
import AssetSearch from "@/components/screens/Dashboard/partials/AssetSearch/AssetSearch.component";
import BreadcrumbsTemplate from "@/components/screens/Dashboard/partials/breadcrumbs-template/BreadcrumbsTemplate.component";
import FilesSuperTable from "@/components/screens/Dashboard/partials/SuperTables/FilesSuperTable/FilesSuperTable.component";
import { useCurrentScreen } from "@/contexts/screen/screen.hook";
import { SCREEN_TYPES } from "@/types/contexts/screen-context";
import DropZone from "@/components/DropZone/DropZone.component";
import { useModal } from "@/components/shared/Modal/useModal";
import { useBottomSheet } from "@/components/shared/BottomSheet/useBottomSheet";
import { SecureFileProvider } from "@/components/screens/Dashboard/partials/secure-file/secure-file.provider";
import SecureFileContent from "@/components/screens/Dashboard/partials/secure-file";
import PromptSignIn from "@/components/screens/Dashboard/partials/prompt-sign-in/PromptSignIn.component";
import {
  ACCESS_TYPE,
  SHARED_ACCESS_TYPE,
  SharedUserDetails
} from "@/types/contexts/share-context";
import { UserDetails } from "@/types/contexts/user-context";

function ProfileSectionTemplate() {
  const { isMobile } = useResponsive();
  const { viewingProfile } = useProfile();
  const { user } = useAuth();
  const [mobileSortTrigger, setMobileSortTrigger] = useState(false);
  const { setCurrentScreen } = useCurrentScreen();
  const [resourcePresent] = useState(true);

  const { openModal } = useModal();
  const { openBottomSheet } = useBottomSheet();

  const transformUserDetailsToShared = (
    userDetails: UserDetails
  ): SharedUserDetails => {
    return {
      id: userDetails.id,
      email: userDetails.email,
      access: {
        access_type: SHARED_ACCESS_TYPE.ViewOnly
      },
      profile: userDetails.profile?.avatar
        ? { avatar: userDetails.profile.avatar }
        : undefined
    };
  };

  const handleUploadComplete = ({ files }) => {
    const sharedUserFromProfile = viewingProfile
      ? transformUserDetailsToShared(viewingProfile)
      : null;
    const content = user ? (
      <SecureFileProvider>
        <SecureFileContent
          files={files}
          initialSharedUsers={[sharedUserFromProfile]}
        />
      </SecureFileProvider>
    ) : (
      <PromptSignIn />
    );

    if (isMobile) {
      return openBottomSheet({
        content,
        showActions: false
      });
    }

    return openModal({
      content,
      showActions: false
    });
  };

  const renderBackButton = () => {
    if (!user) {
      return null;
    }

    return (
      <div className={styles.backButton}>
        <BackButton
          text="Back to dashboard"
          onClick={() => {
            setCurrentScreen(SCREEN_TYPES.DASHBOARD_ROOT);
          }}
        />
      </div>
    );
  };

  const tableHeader = (
    <div className={styles.tableHeader}>
      <div className={styles.headerActions}>
        <AssetSearch />

        <button
          className={styles.mobileSortButton}
          onClick={() => setMobileSortTrigger(true)}
        >
          <span>Sort</span>
          <img src="/images/sort-alt.svg" alt="sorting icon" />
        </button>
      </div>
    </div>
  );

  const renderContent = () => {
    if (!viewingProfile) return null;
    if (isMobile) {
      return (
        <>
          {renderBackButton()}
          <div className={styles.profileWrapper}>
            <div className={styles.mobileProfileSection}>
              <div className={styles.mobileProfileLeft}>
                <img
                  src={getUserProfileImage(viewingProfile?.profile?.avatar)}
                  alt="User Profile"
                  className="round-image-with-border"
                />
              </div>
              <div className={styles.mobileProfileRight}>
                <span>{viewingProfile?.username}</span>
                <div className={styles.profileActions}>
                  <Button
                    onClick={() => null}
                    variant="contained"
                    fullWidth
                    className={`${styles.profileActionButton} ${styles.disabled}`}
                  >
                    Follow
                  </Button>
                  <Button
                    onClick={() => null}
                    variant="contained"
                    fullWidth
                    className={`${styles.profileActionButton} ${styles.disabled}`}
                  >
                    Send File
                  </Button>
                </div>
                <p>{viewingProfile?.profile?.bio}</p>
                <div className={styles.link}>
                  <LinkIcon />
                  <a
                    href="https://superfile.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    superfile.com
                  </a>
                </div>
                <ProfileStatsTemplate />
              </div>
            </div>
            <div className={styles.mainWrapper}>
              <DropZone
                resourcePresent={resourcePresent}
                currentNumberOfFiles={0}
                onUploadComplete={handleUploadComplete}
                viewingProfile={viewingProfile}
              />
              <BreadcrumbsTemplate />
              <FilesSuperTable
                tableHeader={tableHeader}
                mobileSortState={{ mobileSortTrigger, setMobileSortTrigger }}
              />
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        {renderBackButton()}
        <div className={styles.profileWrapper}>
          <div className={styles.desktopProfileSection}>
            <div className={styles.sidepanelWrapper}>
              <div className={styles.top}>
                <div className={styles.pfp}>
                  <img
                    src={getUserProfileImage(viewingProfile?.profile?.avatar)}
                    alt="User Profile"
                    className="round-image-with-border"
                  />
                  <span>{viewingProfile?.username}</span>
                </div>
                <ProfileStatsTemplate />
                <div className={styles.topActions}>
                  <ProfileAction
                    text="Follow"
                    icon={{
                      name: IconName.FRIENDS_ICON
                    }}
                    disabled
                  />
                  <ProfileAction
                    text="Message"
                    icon={{
                      name: IconName.MESSAGE_SQUARE_ICON
                    }}
                    disabled
                  />
                  <ProfileAction
                    text="Video Chat"
                    icon={{
                      name: IconName.VIDEO_CHAT_ICON
                    }}
                    disabled
                  />
                </div>
              </div>
              <div className={styles.bottom}>
                <ProfileAction
                  text="Report"
                  icon={{
                    name: IconName.REPORT_ICON
                  }}
                  disabled
                />
                <ProfileAction
                  text="Block"
                  color="red"
                  icon={{
                    name: IconName.BLOCK_ICON
                  }}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className={styles.mainWrapper}>
            <DropZone
              resourcePresent={resourcePresent}
              currentNumberOfFiles={0}
              onUploadComplete={handleUploadComplete}
              viewingProfile={viewingProfile}
            />

            <BreadcrumbsTemplate />
            <FilesSuperTable
              tableHeader={tableHeader}
              mobileSortState={{ mobileSortTrigger, setMobileSortTrigger }}
            />
          </div>
        </div>
      </>
    );
  };

  return renderContent();
}

export default ProfileSectionTemplate;
