import React, { useEffect, useState, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import styles from "./FilesSuperTable.module.scss";
import superTableStyles from "../SuperTables.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { useBottomSheet } from "@/components/shared/BottomSheet/useBottomSheet";
import {
  faLock,
  faFolderPlus,
  faBackspace,
  faArrowAltCircleUp,
  faUnlock,
  faStar as faStarFilled,
  faShare
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import formatDate from "@/lib/format-date";
import { components } from "@/types/beta.schema";
import { useAssets } from "@/contexts/assets/assets.hook";
import { isApiErrorResponse } from "@/lib/error";
import { paginatedQuery, sortQuery } from "./data/helpers";
import { motion } from "framer-motion";
import { useAuth } from "@/contexts/auth/auth.hook";
import { useModal } from "@/components/shared/Modal/useModal";
import {
  favoriteAsset,
  unfavoriteAsset,
  updateAsset
} from "@/services/asset-service";
import FolderSelectionTemplate from "../../folder-selection-template/FolderSelectionTemplate.component";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import MenuActionTemplate from "../../menu-action-template/MenuActionTemplate.component";
import AssetNameTemplate from "../../asset-name-template/AssetNameTemplate.component";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import { SortField } from "./data/helpers.types";
import { useProfile } from "@/contexts/profile/profile.hook";
import { getUserProfileImage } from "@/lib/user";
import useResponsive from "@/hooks/useResponsive";
import { ACCESS_TYPE } from "@/types/contexts/share-context";
import RequestAccessTemplate from "../../request-access-template/RequestAccessTemplate.component";
import { SuperTableProps } from "../SuperTables.types";
import useAssetRouting from "@/hooks/useAssetRouting";
import { LinearProgress, CircularProgress } from "@mui/material";
import ShareAssetTemplate from "../../share-asset-template/ShareAssetTemplate.component";
import MobileSort from "../MobileSort/MobileSort.component";
import { useCurrentScreen } from "@/contexts/screen/screen.hook";
import { SCREEN_TYPES } from "@/types/contexts/screen-context";
import RowActionTemplate from "../RowActionTemplate/RowActionTemplate.component";

const FilesSuperTable: React.FC<SuperTableProps> = ({
  tableHeader,
  mobileSortState
}) => {
  const [selectedFiles, setSelectedFiles] = useState<
    Array<components["schemas"]["AssetData"]>
  >([]);

  const [isMoveToFolderDisabled, setIsMoveToFolderDisabled] = useState(false);
  const [actionsDisabled, setActionsDisabled] = useState(false);

  const {
    remove,
    refresh,
    assets,
    folderState,
    setFolderState,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    links
  } = useAssets();
  const { user, userChannel } = useAuth();
  const { openModal, closeModal, isOpen } = useModal();
  const { addToast } = useSuperToast();
  const { openBottomSheet, isOpen: mobileSortOpen } = useBottomSheet();
  const { handleFolderNav } = useAssetRouting();
  const { viewingProfile, setViewingProfile } = useProfile();
  const { isDesktop, isMobile } = useResponsive();
  const { setCurrentScreen, setCurrentScreenProfile } = useCurrentScreen();
  const activeProgressListenerIds = new Set();

  const filteredData = () => {
    if (!assets || assets?.length === 0) {
      return [];
    }

    return assets.filter((item) => !(item.type === "shortcut" && !item.asset));
  };

  const page = useMemo(
    () => paginatedQuery(links ? links[links?.length - 1]?.url : undefined),
    [links]
  );

  const { mobileSortTrigger, setMobileSortTrigger } = mobileSortState;

  useEffect(() => {
    setActionsDisabled(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!folderState.currentFolder) {
      // If we are in the root folder, check if we have a folder we can move to
      const totalFoldersCount = assets.filter(
        (asset) => asset.type === "folder"
      ).length;
      const folderInSelectedAssetsCount = selectedFiles.filter(
        (asset) => asset.type === "folder"
      ).length;

      //This also handles the case where there are 0 folders
      const shouldDisableMove =
        totalFoldersCount === folderInSelectedAssetsCount;
      setIsMoveToFolderDisabled(shouldDisableMove);
    } else {
      // If we are not in the root folder, can always move to the root (or parent if it exists)
      setIsMoveToFolderDisabled(false);
    }
  }, [selectedFiles, assets, folderState]);

  useEffect(() => {
    if (userChannel) {
      userChannel
        .listen("AssetShared", () => {
          refresh(
            {
              folder_id: folderState?.currentFolder?.id,
              owner_id: viewingProfile?.id
            },
            true
          );
        })
        .listen("AssetUnshared", () => {
          refresh(
            {
              folder_id: folderState?.currentFolder?.id,
              owner_id: viewingProfile?.id
            },
            true
          );
        });
    }
    return () => {
      userChannel?.stopListening("AssetShared");
      userChannel?.stopListening("AssetUnshared");
    };
  }, [userChannel, folderState, viewingProfile]);

  useEffect(() => {
    return () => setSelectedFiles([]);
  }, [folderState]);

  const { loading } = useInfiniteScroll({
    callback: () =>
      refresh(
        {
          page: page,
          folder_id: folderState?.currentFolder?.id,
          owner_id: viewingProfile?.id
        },
        false
      ),
    wait: 250,
    threshold: 150,
    nextPage: links ? links[links?.length - 1]?.url : undefined
  });

  const dateBodyTemplate = (rowData: components["schemas"]["AssetData"]) => {
    const shortcutAsset = rowData?.asset;

    return viewingProfile || rowData.processed === true ? (
      <span className={styles.dateCell}>
        {formatDate(shortcutAsset?.created_at ?? rowData.created_at)}
      </span>
    ) : null;
  };

  const ownedByTemplate = (rowData: components["schemas"]["AssetData"]) => {
    const shortcutAsset = rowData?.asset;

    const profileData = {
      defaultImageUrl: getUserProfileImage(viewingProfile?.profile?.avatar),
      shortcutImageUrl: getUserProfileImage(
        shortcutAsset?.owner?.profile?.avatar
      ),
      ownerName:
        shortcutAsset?.owner?.username ??
        viewingProfile?.username ??
        user?.username,
      imageUrl: shortcutAsset?.owner?.profile?.avatar ?? ""
    };

    // Handle case where `viewingProfile` is missing but `user` exists
    if (!viewingProfile && user) {
      profileData.defaultImageUrl = getUserProfileImage(user?.profile?.avatar);
      profileData.ownerName = shortcutAsset
        ? shortcutAsset.owner?.username
        : user?.username;
    }

    // Determine final image URL
    profileData.imageUrl = shortcutAsset
      ? profileData.shortcutImageUrl
      : profileData.defaultImageUrl;

    const { imageUrl, ownerName } = profileData;

    return viewingProfile || rowData?.processed === true ? (
      <button
        className={styles.ownedByCell}
        onClick={() => {
          setCurrentScreenProfile({
            path: ownerName,
            owner_id:
              ownerName === user.username
                ? null
                : (rowData?.asset?.owner?.id ?? rowData?.owner_id),
            username: ownerName
          });
          setCurrentScreen(
            ownerName === user.username
              ? SCREEN_TYPES.DASHBOARD_PROFILE
              : SCREEN_TYPES.PROFILE
          );
        }}
      >
        <img src={imageUrl} className={styles.userIcon} alt="Owner Avatar" />
        <span className={styles.ownerName}>{ownerName}</span>
      </button>
    ) : null;
  };

  const nameTemplate = (rowData: components["schemas"]["AssetData"]) => {
    return <AssetNameTemplate asset={rowData} />;
  };

  const typeTemplate = (rowData: components["schemas"]["AssetData"]) => {
    let type = "";

    switch (rowData.type) {
      case "folder":
        type = rowData.type;
        break;
      case "shortcut":
        type = rowData.asset.mime_type;
        break;
      default:
        type =
          viewingProfile || rowData.processed === true
            ? rowData.mime_type
            : "Processing...";
        break;
    }
    return <>{type}</>;
  };

  //While mobile sort is open, allow it to rerender
  //When it closes, only the sort button in dashboard can render it again
  useEffect(() => {
    if (mobileSortTrigger) setMobileSortTrigger(mobileSortOpen);
  }, [mobileSortOpen]);

  useEffect(() => {
    if (mobileSortTrigger) {
      openBottomSheet({
        content: (
          <MobileSort
            onSort={onSort}
            sortField={sortField}
            sortOrder={sortOrder}
            tableType={"files"}
          />
        ),
        showActions: false
      });
    }
  }, [mobileSortTrigger, sortOrder, sortField]);

  const handleFolderSelection = async (folderId: string) => {
    let error = false;

    for (let i = 0; i < selectedFiles.length; i++) {
      const assetToUpdateId = selectedFiles[i].id;
      const payload = {
        folder_id: folderId
      };

      const { error: responseError } = await updateAsset(
        assetToUpdateId,
        payload
      );

      if (!error && responseError) {
        error = true;
      } else {
        setSelectedFiles((prev) =>
          prev.filter((toDelete) => toDelete.id !== assetToUpdateId)
        );
      }
    }

    if (error) {
      addToast("Some assets weren't moved over successfully", ToastTypes.FAIL);
    }
    closeModal();
    refresh(
      {
        folder_id: folderState?.currentFolder?.id,
        owner_id: viewingProfile?.id
      },
      true
    );
    setActionsDisabled(false);
  };

  const isFolder = (file: components["schemas"]["AssetData"]): boolean =>
    file.type === "folder";

  const isFolderSelected = (
    folder: components["schemas"]["AssetData"],
    selectedFolders: Array<components["schemas"]["AssetData"]>
  ): boolean =>
    selectedFolders.some((selectedFolder) => selectedFolder.id === folder.id);

  const handleMoveToFolderToggle = () => {
    const selectedFolders = selectedFiles.filter(isFolder);

    const availableFolders = assets
      .filter(
        (asset) => isFolder(asset) && !isFolderSelected(asset, selectedFolders)
      )
      .map(({ id, name }) => ({ folderId: id, folderName: name }));

    if (folderState.breadcrumbs.length > 1) {
      const parentFolder =
        folderState.breadcrumbs[folderState.breadcrumbs.length - 1];

      availableFolders.push({
        folderId: parentFolder.id,
        folderName: parentFolder.name + " (Parent Folder)"
      });
    }

    if (folderState.breadcrumbs.length > 0) {
      availableFolders.push({ folderId: null, folderName: "Root" });
    }

    if (!availableFolders) {
      setActionsDisabled(false);
      return;
    }

    openModal({
      content: (
        <FolderSelectionTemplate
          foldersInfo={availableFolders}
          onSubmit={handleFolderSelection}
        />
      ),
      showActions: false
    });
  };

  const handleBulkDelete = () => {
    const deleteResults = [];
    selectedFiles.forEach((asset) => {
      const removeResponse = remove(asset.id);
      deleteResults.push(removeResponse);
    });
    return Promise.allSettled(deleteResults);
  };

  const bulkActionsTemplate = (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.2,
        scale: { type: "spring", visualDuration: 0.2, bounce: 0 }
      }}
      className={styles.bulkActions}
    >
      <button
        className={classNames(
          styles.bulkActionButton,
          styles.moveToFolderButton,
          {
            [styles.disabled]: isMoveToFolderDisabled
          }
        )}
        onClick={() => {
          setActionsDisabled(true);
          handleMoveToFolderToggle();
        }}
        disabled={isMoveToFolderDisabled}
      >
        <FontAwesomeIcon icon={faFolderPlus} size={"lg"} />
        Move to Folder
      </button>
      <button
        className={classNames(styles.bulkActionButton, styles.deleteAllButton)}
        onClick={() => {
          setActionsDisabled(true);
          handleBulkDelete()
            .then((results) => {
              const successfulRemovals = results
                .filter((result) => {
                  return result.status === "fulfilled";
                })
                .map((filtered) => filtered.value.assetId);
              setSelectedFiles((prev) =>
                prev.filter((file) => !successfulRemovals.includes(file.id))
              );
            })
            .then(() => {
              refresh({
                folder_id: folderState?.currentFolder?.id
              });
              setActionsDisabled(false);
            });
        }}
        disabled={selectedFiles.length > 0 && actionsDisabled}
      >
        <FontAwesomeIcon icon={faBackspace} size={"lg"} />
        Delete
      </button>
    </motion.div>
  );

  const firstLoadTemplate = (
    <div className={superTableStyles.emptyMessage}>
      <strong>{"You do not have any files to display."}</strong>
      <span>
        {"Start securing files above"}
        <FontAwesomeIcon icon={faArrowAltCircleUp} size={"lg"} />
      </span>
    </div>
  );

  const emptyMessageTemplate = (
    <div className={superTableStyles.emptyMessage}>
      <strong>{"Nothing to display..."}</strong>
    </div>
  );

  const onSort = (e) => {
    if (e.sortOrder === sortOrder && e.sortField === sortField) {
      //Loading in with correct sort, no-op to not run a wasted refresh
      return;
    }

    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    refresh(
      {
        folder_id: folderState?.currentFolder?.id,
        owner_id: viewingProfile?.id,
        sort: sortQuery(e.sortField, e.sortOrder)
      },
      true
    );
  };

  return (
    <div className={superTableStyles.glassPane}>
      <DataTable
        value={filteredData()}
        stripedRows={isDesktop}
        rows={25}
        selectionMode={"checkbox"}
        selection={selectedFiles}
        onSelectionChange={(e) => setSelectedFiles(e.value)}
        dataKey="id"
        header={tableHeader}
        showHeaders={isDesktop}
        emptyMessage={
          viewingProfile == user ? emptyMessageTemplate : firstLoadTemplate
        }
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSort}
        removableSort
        lazy
        stateStorage="session"
        stateKey="supers-sort-data"
        className={superTableStyles.datatable}
      >
        {/* TODO: How should selection/bulk action work on a viewing profile? */}
        <Column
          selectionMode="multiple"
          headerClassName={styles.selectionWrapper}
          hidden={!isDesktop || !!viewingProfile}
        />

        {/* In mobile layout, "name" also includes date and restriction values within the same column */}
        <Column
          field="name"
          header="Name"
          sortable
          sortField={SortField.NAME}
          body={nameTemplate}
          headerClassName={styles.nameTemplateWrapper}
        />
        <Column
          field="mime_type"
          header="Type"
          sortable
          sortField={SortField.TYPE}
          headerClassName={styles.fileTypeWrapper}
          bodyClassName={styles.fileTypeColumn}
          body={typeTemplate}
          hidden={!isDesktop}
        />
        <Column
          field="date"
          header="Date"
          sortable
          sortField={SortField.DATE}
          body={dateBodyTemplate}
          headerClassName={styles.dateWrapper}
          hidden={!isDesktop}
        />
        <Column
          field="ownedBy"
          header="Owned By"
          sortable
          sortField={SortField.OWNER_NAME}
          body={ownedByTemplate}
          headerClassName={styles.ownerWrapper}
          hidden={!isDesktop}
        />

        {/* TODO: How should selection/bulk action work on a viewing profile? */}
        <Column
          body={RowActionTemplate}
          header={
            selectedFiles.length > 0 && !viewingProfile && bulkActionsTemplate
          }
          sortable={false}
          headerClassName={styles.actionRowWrapper}
        />
      </DataTable>
    </div>
  );
};

export default FilesSuperTable;
