import React, { useCallback, useEffect, useRef, useState } from "react";
import debounce from "@/lib/debounce";
import { Box, Portal, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useAuth } from "@/contexts/auth/auth.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import UserDropdownComponent from "./UserDropdown.component";
import { userShareSearchValidationSchema } from "../secure-file/step-1/data/validation-schemas.data";
import {
  SHARED_ACCESS_TYPE,
  SharedUserDetails
} from "@/types/contexts/share-context";
import { getUsers } from "@/services/users-service";
import { components } from "@/types/beta.schema";
import { UserShareSearchProps } from "./user-search.types";
import { userSearchQuery } from "./data/helpers.data";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { useProfile } from "@/contexts/profile/profile.hook";

function UserShareSearch({
  onUserSelect,
  sharedUsers,
  shortcutAssetOwner
}: UserShareSearchProps) {
  const { user: authenticatedUser } = useAuth();
  const { viewingProfile } = useProfile();
  const { addToast } = useSuperToast();

  const assetOwner = shortcutAssetOwner ?? viewingProfile ?? authenticatedUser;

  const [userSearchResults, setUserSearchResults] =
    useState<Array<components["schemas"]["UserData"]>>();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const nextFocusableElemRef = useRef<HTMLDivElement | null>(null);

  const userSearchCache = useRef(
    new Map<string, Array<components["schemas"]["UserData"]>>()
  ).current;

  const userSearchFormik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: userShareSearchValidationSchema,
    onSubmit: (values) => {
      const email = values.email.trim();

      const emailIsOwner = assetOwner.email === email;
      const emailIsSelf = authenticatedUser.email === email;
      const emailIsAdded = sharedUsers.some((user) => user.email === email);

      if (emailIsSelf) {
        addToast("Cannot share asset with self", ToastTypes.FAIL);
      } else if (emailIsOwner) {
        addToast("Cannot share asset with owner", ToastTypes.FAIL);
      } else if (emailIsAdded) {
        addToast("User is already in your list", ToastTypes.FAIL);
      } else {
        const newSharedUser: SharedUserDetails = {
          email,
          access: {
            access_type: SHARED_ACCESS_TYPE.ViewOnly
          }
        };

        onUserSelect(newSharedUser);
      }

      userSearchFormik.resetForm();
      setUserSearchResults([]);
      setDropdownVisible(false);
    }
  });

  useEffect(() => {
    userSearchCache.clear();
  }, [sharedUsers]);

  // Debounced function to fetch users
  const debouncedGetSearchResults = useCallback(
    debounce(async (searchStr: string) => {
      if (searchStr.length >= 3) {
        if (userSearchCache.has(searchStr)) {
          const cachedUsers = userSearchCache.get(searchStr) || [];

          setUserSearchResults(cachedUsers);
          setDropdownVisible(cachedUsers.length > 0);
        } else {
          const { users: fetchedUsers } = await handleGetUserSearchResults({
            searchStr
          });
          userSearchCache.set(searchStr, fetchedUsers);

          setUserSearchResults(fetchedUsers);
          setDropdownVisible(fetchedUsers.length > 0);
        }
      } else {
        setUserSearchResults([]);
        setDropdownVisible(false);
      }
    }, 250),
    [sharedUsers]
  );

  // Fetch users from the API
  const handleGetUserSearchResults = async ({
    searchStr
  }: {
    searchStr: string;
  }) => {
    const query = userSearchQuery({
      searchStr,
      sharedUsers,
      assetOwner
    });

    const { data } = await getUsers(query);

    return { users: data?.data };
  };

  // Handle input change
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    userSearchFormik.handleChange(e);
    debouncedGetSearchResults(e.target.value);
  };

  // Handle blur event to manage error message and close of dropdown
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    userSearchFormik.setFieldTouched("email", false); // removes error message

    // Check if the dropdownRef does not have focus
    if (dropdownRef.current && !dropdownRef.current.contains(e.relatedTarget)) {
      setDropdownVisible(false);
    }
  };

  // Handle focus event to reopen the dropdown if there are users
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const currentValue = e.target.value || "";
    if (currentValue.length >= 3) {
      debouncedGetSearchResults(currentValue);
    }
  };

  // Handle key press (for "Enter" functionality)
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "Enter": {
        e.preventDefault();
        userSearchFormik.handleSubmit();
        break;
      }

      case "Tab": {
        if (e.shiftKey) {
          setDropdownVisible(false);
          return;
        } else {
          if (dropdownRef.current && dropdownVisible) {
            e.preventDefault();
            const firstDropdownButton = dropdownRef.current?.querySelector(
              'div[role="button"]'
            ) as HTMLElement;
            firstDropdownButton?.focus();
          }
        }
        break;
      }

      default:
        break;
    }
  };

  // Handle dropdown close
  const handleCloseDropdown = ({ shouldFocusInput = false } = {}) => {
    if (shouldFocusInput) {
      const inputElem = inputRef.current?.querySelector("input");
      inputElem?.focus();
    } else {
      setDropdownVisible(false);
    }
  };

  return (
    <Box>
      <TextField
        fullWidth
        ref={inputRef}
        id="email"
        name="email"
        value={userSearchFormik.values.email}
        onChange={handleEmailChange}
        onBlur={handleBlur}
        aria-owns="user-search-dropdown"
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        error={
          userSearchFormik.touched.email &&
          Boolean(userSearchFormik.errors.email)
        }
        helperText={
          userSearchFormik.touched.email && userSearchFormik.errors.email
        }
        className="text-input"
        placeholder="Search for a user"
      />
      <div ref={nextFocusableElemRef} tabIndex={0} />
      {dropdownVisible && (
        <Portal>
          <UserDropdownComponent
            ref={dropdownRef}
            users={userSearchResults}
            onUserSelect={onUserSelect}
            onClose={handleCloseDropdown}
            nextFocusableElem={nextFocusableElemRef.current}
            inputRef={inputRef}
          />
        </Portal>
      )}
    </Box>
  );
}

export default UserShareSearch;
