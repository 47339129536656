export interface IStreamConfiguration {
  assetID?: string;
  manifestUrl?: string;
  thumbnailTrack?: string;
  hlsManifestUrl?: string;
  hlsThumbnailTrack?: string;
  dashManifestUrl?: string;
  dashThumbnailTrack?: string;
  playreadyManifestUrl?: string;
  playreadyThumbnailTrack?: string;
  fairplayLAURL?: string;
  fairplaycertificateURL?: string;
  widevineLAURL?: string;
  useDRM: boolean;
  useDRMMode2?: boolean;
}

export enum DRM_SYSTEM {
  WIDEVINE = "widevine",
  FAIRPLAY = "fairplay",
  PLAYREADY = "playready",
  NODRM = "no-drm"
}

export enum SupportedDRMConfigurations {
  widevine = "com.widevine.alpha",
  playready = "com.microsoft.playready.recommendation"
}

export interface IPlayerCapabilities {
  allowPlayback: boolean;
  drm: DRM_SYSTEM;
  drmError: boolean;
  ipAddress: string;
  isDeviceSupported: boolean;
  isLocationAllowed: boolean;
  isL1Enabled: boolean;
  loaded: boolean;
  supportedDevice: ISupportedDevice | null;
  isAppleDevice: boolean;
  isWindowsDevice: boolean;
}

export interface ISupportedDevice {
  os: string;
  browser: string;
}

export interface IDeviceInfo {
  name: string;
  version: string;
  os: string;
  isAppleDevice?: boolean;
  isWindowsDevice?: boolean;
}

export interface ShakaPlayerProps {
  className?: string;
  autoPlay?: boolean;
  showUI?: boolean;
  loop?: boolean;
  muted?: boolean;
  pdfControls?: boolean;
  config: IStreamConfiguration;
  onLoad?: (data: OnLoadParams) => void;
  assetId?: string;
  startTime?: number;
  url?: string;
}

export interface OnLoadParams {
  dimensions: {
    width: number;
    height: number;
  };
  duration: number;
  url: string;
}
