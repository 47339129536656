import React from "react";
import { useMemo } from "react";
import {
  MobileSortLabelMap,
  MobileSortProps,
  UserMobileSortLabelMap
} from "./MobileSort.types";
import styles from "./MobileSort.module.scss";
import { SortField } from "../FilesSuperTable/data/helpers.types";

const MobileSort: React.FC<MobileSortProps> = ({
  onSort,
  sortField,
  sortOrder,
  tableType
}) => {
  const handleMobileSort = (fieldType: SortField) => {
    //If sorting on already sorted field, flip the direction, otherwise default to sort up
    const newSortOrder = sortField === fieldType ? sortOrder * -1 : 1;

    onSort({ sortField: fieldType, sortOrder: newSortOrder });
  };

  const labelMap = useMemo(
    () => (tableType === "files" ? MobileSortLabelMap : UserMobileSortLabelMap),
    [tableType]
  );

  return (
    <div className={styles.mobileSortMenuContainer}>
      <span className={styles.sortByLabel}>Sort by</span>
      {Object.entries(labelMap).map(([fieldName, fieldType]) => (
        <button
          className={styles.mobileSortFieldButton}
          key={fieldName}
          onClick={() => handleMobileSort(fieldType)}
        >
          {fieldName}{" "}
          {sortField === fieldType &&
            (sortOrder === 1 ? (
              <img
                className={styles.mobileSortOrderIcon}
                src="/images/sort-up.svg"
                alt="sort up icon"
              />
            ) : (
              <img
                className={styles.mobileSortOrderIcon}
                src="/images/sort-down.svg"
                alt="sort down icon"
              />
            ))}
        </button>
      ))}
    </div>
  );
};

export default MobileSort;
