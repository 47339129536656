import React, { useState } from "react";
import { AssetNameTemplateProps } from "./AssetNameTemplate.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderOpen,
  faFolderClosed
} from "@fortawesome/free-solid-svg-icons";
import styles from "./AssetNameTemplate.module.scss";
import formatDate from "@/lib/format-date";
import useResponsive from "@/hooks/useResponsive";
import useAssetRouting from "@/hooks/useAssetRouting";
import { usePreviewModal } from "../../../../shared/asset-previews/preview-modal/PreviewModal.hook";
import { useAuth } from "@/contexts/auth/auth.hook";
import { useProfile } from "@/contexts/profile/profile.hook";

function AssetNameTemplate({ asset }: AssetNameTemplateProps) {
  const {
    id,
    name: assetName,
    type,
    extension,
    asset: shortcutAsset,
    created_at,
    visibility
  } = asset;

  const [isFolderHovered, setIsFolderHovered] = useState(false);

  const { handleFolderNav } = useAssetRouting();
  const { isDesktop } = useResponsive();
  const { openModal: openPreviewModal } = usePreviewModal();
  const { user } = useAuth();
  const { viewingProfile } = useProfile();

  const handleClick = async (): Promise<void> => {
    if (type === "folder") {
      return handleFolderNav(assetName, id);
    }

    if (type === "file" || type === "shortcut") {
      let ownerName = null;
      let assetId = id;

      if (type === "shortcut") {
        ownerName = shortcutAsset.owner.username;
        assetId = shortcutAsset.id;
      }

      if (type === "file") {
        if (viewingProfile) {
          if (!shortcutAsset) {
            ownerName = viewingProfile.username;
          }
        }
        if (!viewingProfile && user) {
          ownerName = user.username;
        }
      }

      openPreviewModal({ ownerName, assetName, assetId });
    }
  };

  const getVisbilityText = (visibility: string) => {
    let visibilitySpan = null;
    switch (visibility) {
      case "private":
        visibilitySpan = (
          <span className={styles.privateVisibility}>Private</span>
        );
        break;
      case "public":
        visibilitySpan = (
          <span className={styles.publicVisibility}>Public</span>
        );
        break;
      case "restricted":
        visibilitySpan = (
          <span className={styles.restrictedVisibility}>Restricted</span>
        );
        break;
    }
    return <span>&nbsp;· {visibilitySpan}</span>;
  };
  const renderIcon = () => {
    switch (type) {
      case "folder":
        return (
          <span className={styles.folderIconWrapper}>
            <FontAwesomeIcon
              icon={isFolderHovered ? faFolderOpen : faFolderClosed}
              size={"xl"}
            />
          </span>
        );
      case "shortcut":
        return (
          <img
            src={`/images/typeicons/${shortcutAsset.extension}.svg`}
            className={styles.fileTypeIcon}
          />
        );
      default:
        return (
          <img
            src={`/images/typeicons/${extension}.svg`}
            className={styles.fileTypeIcon}
          />
        );
    }
  };

  return (
    <button
      className={styles.fileNameCell}
      onClick={handleClick}
      onMouseEnter={() => setIsFolderHovered(true)}
      onMouseLeave={() => setIsFolderHovered(false)}
    >
      {renderIcon()}
      {isDesktop ? (
        <span className={styles.fileName}>
          {shortcutAsset?.name ?? assetName}
        </span>
      ) : (
        // If mobile layout, this column should also include date and visiblity
        <div className={styles.mobileAssetContainer}>
          <span className={styles.fileName}>
            {shortcutAsset?.name ?? assetName}
          </span>
          <div className={styles.mobileAssetMetadata}>
            <span className={styles.fileCreatedDate}>
              {formatDate(created_at)}
            </span>
            {getVisbilityText(visibility)}
          </div>
        </div>
      )}
    </button>
  );
}

export default AssetNameTemplate;
