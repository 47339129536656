export enum SortField {
  DATE = "created_at",
  NAME = "name",
  TYPE = "mime_type",
  OWNER_NAME = "owner_name"
}

export enum UsersSortField {
  USER = "username"
}
