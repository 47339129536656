import React from "react";
import { ProfileActionProps } from "./ProfileAction.types";
import styles from "./ProfileAction.module.scss";
import { IconName } from "../icons/icon.types";
import FriendsIcon from "../icons/FriendsIcon";
import classNames from "classnames";
import MessageSquareIcon from "../icons/MessageSquareIcon";
import VideoChatIcon from "../icons/VideoChatIcon";
import ReportIcon from "../icons/ReportIcon";
import BlockIcon from "../icons/BlockIcon";

function ProfileAction({ text, icon, disabled, color }: ProfileActionProps) {
  const { name: iconName, color: iconColor } = icon;

  const classes = classNames({
    [styles.profileAction]: true,
    [styles.disabled]: disabled,
    [styles.red]: color === "red"
  });

  const renderIcon = () => {
    if (iconName === IconName.FRIENDS_ICON)
      return <FriendsIcon color={iconColor} />;

    if (iconName === IconName.MESSAGE_SQUARE_ICON)
      return <MessageSquareIcon color={iconColor} />;

    if (iconName === IconName.VIDEO_CHAT_ICON)
      return <VideoChatIcon color={iconColor} />;

    if (iconName === IconName.REPORT_ICON)
      return <ReportIcon color={iconColor} />;

    if (iconName === IconName.BLOCK_ICON)
      return <BlockIcon color="var(--danger-200)" />;
  };

  return (
    <div className={classes}>
      {renderIcon()}
      <span>{text}</span>
    </div>
  );
}

export default ProfileAction;
