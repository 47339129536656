import React from "react";
import { BaseIconProps } from "./icon.types";

function TransferIcon({ className }: BaseIconProps) {
  const classes = className ? `${className}` : "";

  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <g clipPath="url(#clip0_888_50987)">
        <path
          d="M2.20983 2.3746C2.11675 2.47013 2.11675 2.62445 2.21473 2.71753C2.31026 2.81061 2.46458 2.81061 2.55766 2.71263C3.43457 1.81367 4.61032 1.31153 5.86446 1.29683C7.0892 1.28214 8.25025 1.73774 9.13941 2.57546L8.69361 2.58036C8.55889 2.58281 8.45356 2.69059 8.45356 2.82531C8.45601 2.95758 8.56378 3.06536 8.69606 3.06536H8.69851L9.73219 3.05311C9.86691 3.05066 9.97224 2.94288 9.97224 2.80816L9.96244 1.77693C9.95999 1.64466 9.85221 1.53688 9.71994 1.53688H9.71749C9.58277 1.53933 9.47744 1.64711 9.47744 1.78183L9.48234 2.23253C8.5001 1.30173 7.21412 0.797139 5.85956 0.811836C4.47315 0.831431 3.17738 1.38501 2.20983 2.3746Z"
          fill="white"
        />
        <path
          d="M9.28176 9.28437C8.40485 10.1833 7.2291 10.6855 5.97496 10.7002C4.75022 10.7149 3.58917 10.2593 2.70001 9.42154L3.14582 9.41664C3.28054 9.41419 3.38586 9.30641 3.38586 9.17169C3.38342 9.03942 3.27564 8.93164 3.14337 8.93164H3.14092L2.10724 8.94389C1.97252 8.94634 1.86719 9.05411 1.86719 9.18884L1.87943 10.2225C1.88188 10.3548 1.98966 10.4626 2.12193 10.4626H2.12438C2.2591 10.4601 2.36443 10.3523 2.36443 10.2176L2.35953 9.76691C3.32708 10.683 4.58611 11.1876 5.92108 11.1876C5.94067 11.1876 5.96272 11.1876 5.98231 11.1876C7.36627 11.1705 8.66449 10.6169 9.63204 9.62729C9.72512 9.53176 9.72512 9.37745 9.62714 9.28437C9.52916 9.18639 9.37729 9.18884 9.28176 9.28437Z"
          fill="white"
        />
        <path
          d="M1.91549 5.88438C1.91549 5.80355 1.87385 5.72761 1.80772 5.68352C1.76363 5.65413 1.73913 5.60514 1.73913 5.5537V4.66944C1.73913 4.36325 1.98653 4.11586 2.29271 4.11586H2.48377C2.78996 4.11586 3.03735 4.36325 3.03735 4.66944V5.5537C3.03735 5.60514 3.01041 5.65413 2.96877 5.68352C2.90018 5.72761 2.86099 5.80355 2.86099 5.88438V6.7368C2.86099 6.84458 2.92223 6.94256 3.02021 6.9891C3.13043 7.04298 3.70116 7.33202 4.23025 7.76558C4.27189 7.79987 4.29639 7.85376 4.29639 7.9101V8.51512C4.29639 8.64984 4.40416 8.75762 4.53889 8.75762C4.67361 8.75762 4.78138 8.64984 4.78138 8.51512V7.9101C4.78138 7.70924 4.6932 7.52063 4.53644 7.39081C4.07348 7.00869 3.57624 6.7319 3.34599 6.61188V5.99706C3.45867 5.87948 3.5248 5.72027 3.5248 5.5537V4.66944C3.5248 4.09626 3.0594 3.63086 2.48622 3.63086H2.29516C1.72198 3.63086 1.25658 4.09626 1.25658 4.66944V5.5537C1.25658 5.72027 1.32027 5.87948 1.4354 5.99706V6.61188C1.20269 6.7319 0.7079 7.01114 0.244948 7.39081C0.0906307 7.51818 0 7.70679 0 7.9101V8.51512C0 8.64984 0.107777 8.75762 0.242498 8.75762C0.37722 8.75762 0.484997 8.64984 0.484997 8.51512V7.9101C0.484997 7.85376 0.509492 7.80232 0.551133 7.76558C1.07777 7.33202 1.65095 7.04298 1.76118 6.9891C1.85671 6.94256 1.92039 6.84213 1.92039 6.7368V5.88438H1.91549Z"
          fill="white"
        />
        <path
          d="M11.7552 7.39374C11.2922 7.01162 10.795 6.73483 10.5647 6.61481V5.99999C10.6774 5.88241 10.7436 5.7232 10.7436 5.55663V4.67237C10.7436 4.09919 10.2781 3.63379 9.70497 3.63379H9.51391C8.94073 3.63379 8.47533 4.09919 8.47533 4.67237V5.55663C8.47533 5.7232 8.53902 5.88241 8.65414 5.99999V6.61236C8.42144 6.73238 7.92665 7.01162 7.4637 7.39129C7.30938 7.51866 7.21875 7.70727 7.21875 7.91058V8.5156C7.21875 8.65032 7.32653 8.7581 7.46125 8.7581C7.59597 8.7581 7.70375 8.65032 7.70375 8.5156V7.91058C7.70375 7.85424 7.72824 7.8028 7.76988 7.76606C8.29652 7.3325 8.8697 7.04346 8.97993 6.98958C9.07546 6.94304 9.13669 6.84261 9.13669 6.73728V5.88486C9.13669 5.80403 9.09505 5.72809 9.02892 5.684C8.98482 5.65461 8.96033 5.60562 8.96033 5.55418V4.66992C8.96033 4.36373 9.20773 4.11634 9.51391 4.11634H9.70497C10.0112 4.11634 10.2586 4.36373 10.2586 4.66992V5.55418C10.2586 5.60562 10.2316 5.65461 10.19 5.684C10.1214 5.72809 10.0822 5.80403 10.0822 5.88486V6.73728C10.0822 6.84506 10.1434 6.94304 10.239 6.98958C10.3492 7.04346 10.9224 7.3325 11.449 7.76606C11.4906 7.80035 11.5151 7.85424 11.5151 7.91058V8.5156C11.5151 8.65032 11.6229 8.7581 11.7576 8.7581C11.8924 8.7581 12.0001 8.65032 12.0001 8.5156V7.91058C11.9977 7.70972 11.9095 7.52111 11.7552 7.39374Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_888_50987">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TransferIcon;
