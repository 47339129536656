import React, { useState } from "react";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { useAssets } from "@/contexts/assets/assets.hook";
import { isApiErrorResponse } from "@/lib/error";
import { components } from "@/types/beta.schema";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import classNames from "classnames";
import styles from "./FolderSettingsTemplate.module.scss";

function FolderSettingsTemplate({
  asset,
  onClose
}: {
  asset: components["schemas"]["AssetData"];
  onClose: () => void;
}) {
  const [visibility, setVisibility] = useState<string>(asset.visibility);
  const { update, refresh, folderState } = useAssets();
  const { addToast } = useSuperToast();

  const handlePrivacyChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    //No-op if the value isn't actually changing
    if (e.target.value === visibility) {
      return;
    }
    const updatePrivacyResponse = await update(asset.id, {
      visibility: e.target.value as components["schemas"]["VisibilityType"]
    });

    if (isApiErrorResponse(updatePrivacyResponse)) {
      e.preventDefault();
      console.error(updatePrivacyResponse.message);
      addToast(updatePrivacyResponse.message, ToastTypes.FAIL);
    } else {
      addToast("File visibility was successfully updated.", ToastTypes.SUCCESS);
      setVisibility(e.target.value);
      refresh({ folder_id: folderState?.currentFolder?.id });
    }
  };

  return (
    <div className={styles.folderPrivacyContainer}>
      <div className="title">Folder privacy</div>
      <FormControl>
        <RadioGroup
          name="privacy-radio-group"
          value={visibility}
          onChange={handlePrivacyChange}
        >
          <FormControlLabel
            value="public"
            control={
              <Radio
                checked={visibility === "public"}
                sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
              />
            }
            label="Public"
          />
          <FormControlLabel
            value="private"
            control={
              <Radio
                checked={visibility === "private"}
                sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
              />
            }
            label="Private"
          />
          {/* TODO: Add this back when we have the ability to share folders
          <FormControlLabel
            value="restricted"
            control={
              <Radio
                checked={visibility === "restricted"}
                sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
              />
            }
            label="Restricted"
          /> */}
        </RadioGroup>
      </FormControl>
      <Button
        onClick={onClose}
        size="large"
        color="primary"
        variant="text"
        type="button"
        className={classNames("clear-cancel", styles.closeButton)}
      >
        Close
      </Button>
    </div>
  );
}

export default FolderSettingsTemplate;
