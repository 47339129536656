import { useAssets } from "@/contexts/assets/assets.hook";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./AssetViews.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { isApiErrorResponse } from "@/lib/error";
import { AssetViewsProps } from "./AssetViews.types";

function AssetViews({ assetResource, isShortcut }: AssetViewsProps) {
  const [viewCount, setViewCount] = useState<number>(0);

  const assetId = isShortcut ? assetResource.asset_id : assetResource.id;
  const { getAssetDetails } = useAssets();

  const fetchOnLoad = useCallback(async () => {
    /**
     * Note: No views will be recorded until the information tab is done.
     * Views are recorded upon hitting the /v1/assets/{asset} endpoint with a view parameter.
     */

    const asset = await getAssetDetails(assetId);
    if (!isApiErrorResponse(asset)) {
      setViewCount(asset.view_count);
    }
  }, [getAssetDetails, assetId]);

  useEffect(() => {
    fetchOnLoad();
  }, [assetId, fetchOnLoad]);

  return (
    <div className={styles.container}>
      <FontAwesomeIcon icon={faEye} className={styles.view} />
      <span className={styles.countColor}>{viewCount}</span>
    </div>
  );
}

export default AssetViews;
