export const getFocusableElements = (
  refElement: HTMLElement
): HTMLElement[] => {
  const focusableElements = Array.from(
    refElement.querySelectorAll(
      'button:not([disabled]):not([aria-hidden="true"]), \
      [href]:not([aria-hidden="true"]), \
      input:not([disabled]):not([aria-hidden="true"]), \
      select:not([disabled]):not([aria-hidden="true"]), \
      textarea:not([disabled]):not([aria-hidden="true"]), \
      [tabindex]:not([tabindex="-1"]):not([aria-hidden="true"])'
    )
  ) as HTMLElement[];

  return focusableElements.filter((element) => {
    if (
      element.offsetParent === null ||
      window.getComputedStyle(element).display === "none"
    ) {
      return false;
    }

    // Handle radio buttons: Only include the checked one
    if (element instanceof HTMLInputElement && element.type === "radio") {
      const radioGroup = element.name
        ? refElement.querySelectorAll(
            `input[type="radio"][name="${element.name}"]`
          )
        : null;

      if (radioGroup && radioGroup.length > 1) {
        return element.checked; // Only keep the checked one in focusable list
      }
    }

    return true;
  });
};
