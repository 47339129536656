import { API_URL } from "@/config/constants";
import { getAccessToken } from "@/lib/network";
import createClient, { Middleware } from "openapi-fetch";
import type { paths, components, operations } from "@/types/beta.schema";
import { GenericApiResponse } from "@/types/network";
import { GetActivityLogClientResponse } from "@/types/services/asset-service";

const client = createClient<paths>({ baseUrl: `${API_URL}/api` });

//TO-DO: Add in any unprotected asset-related routes here to use in middleware onRequest.
//const UNPROTECTED_ROUTES = [];

const myMiddleware: Middleware = {
  async onRequest({ request, schemaPath }) {
    const accessToken = getAccessToken();
    switch (schemaPath) {
      case "/v1/assets":
        if (request.method !== "POST") {
          request.headers.set("Content-Type", "application/json");
        }
        break;
      default:
        request.headers.set("Content-Type", "application/json");
        break;
    }
    if (accessToken) {
      request.headers.set("Authorization", `Bearer ${accessToken}`);
    }
    request.headers.set("Accept", "application/json");
    return request;
  }
};

client.use(myMiddleware);

export const createAsset = async (
  payload: components["schemas"]["CreateAssetData"]
) => {
  const { data, error } = await client.POST("/v1/assets", {
    body: payload
  });

  return { data, error };
};

export const getAssetInformation = async (assetId: string) => {
  const { data, error, response } = await client.GET("/v1/assets/{asset}", {
    params: {
      path: {
        asset: assetId
      }
    },
    credentials: "include"
  });

  return { data: data?.data, error, statusCode: response.status };
};

export const deleteAsset = async (assetId: string) => {
  const { data } = await client.DELETE("/v1/assets/{asset}", {
    params: {
      path: {
        asset: assetId
      }
    }
  });

  return data;
};

export const updateAsset = async (
  assetId: string,
  payload: components["schemas"]["UpdateAssetData"]
) => {
  const { data, error } = await client.PUT("/v1/assets/{asset}", {
    params: {
      path: {
        asset: assetId
      }
    },
    body: payload
  });

  return { data, error };
};

export const getAssets = async (
  payload?: operations["assets.index"]["parameters"]["query"]
): Promise<any> => {
  const { data, error } = await client.GET(`/v1/assets`, {
    params: {
      query: payload
    }
  });

  return { data, error };
};

/**
 * Makes a network request to retrieve assets from a given path.
 * This approach does not use the api client since the schema is not up to date yet.
 *
 * @param {string} path - The file path to check.
 */

export const getAssetsFromPathWithFolder = async (
  path: string
): Promise<any> => {
  const token = getAccessToken();
  const authorizationValue = token ? `Bearer ${token}` : undefined;

  const response = await fetch(`${API_URL}/api/v1/assets/folder/${path}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authorizationValue,
      Accept: "application/json"
    }
  });

  const jsonResponse = await response.json();

  const responseObj = {
    ...jsonResponse,
    status: response.status
  };

  return await responseObj;
};

export const getActivity = async (
  assetId: string,
  payload?: operations["assets.activity.show"]["parameters"]["query"]
): Promise<GetActivityLogClientResponse> => {
  const { data, error, response } = await client.GET(
    "/v1/assets/{asset}/activity",
    {
      params: {
        path: {
          asset: assetId
        },
        query: payload ?? undefined
      }
    }
  );

  return { data, error, statusCode: response.status };
};

export const getSharedUsers = async (assetId: string) => {
  const { data, error } = await client.GET("/v1/assets/{asset}/shares", {
    params: {
      path: {
        asset: assetId
      }
    }
  });

  return { data, error };
};

export const updateSharedUserAccessType = async (
  assetId: string,
  payload: components["schemas"]["UpdateAssetShareData"]
) => {
  const { data, error } = await client.PUT("/v1/assets/{asset}/share", {
    params: {
      path: {
        asset: assetId
      }
    },
    body: payload
  });

  return { data, error };
};

export const deleteSharedUserAccess = async (
  assetId: string,
  userId: string
) => {
  const { data, error, response } = await client.DELETE(
    "/v1/assets/{asset}/share",
    {
      params: {
        path: {
          asset: assetId
        },
        query: {
          user_id: userId
        }
      }
    }
  );

  return { data, error, statusCode: response.status };
};

// TODO: Switch to api client
export const favoriteAsset = async (
  assetId: string
): Promise<GenericApiResponse> => {
  const token = getAccessToken();

  const response = await fetch(
    `${API_URL}/api/v1/assets/favorites/${assetId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json"
      }
    }
  );

  return await response.json();
};

// TODO: Switch to api client
export const unfavoriteAsset = async (
  assetId: string
): Promise<GenericApiResponse> => {
  const token = getAccessToken();

  const response = await fetch(
    `${API_URL}/api/v1/assets/favorites/${assetId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json"
      }
    }
  );

  return await response.json();
};

export const getAssetByOwner = async (owner: string, asset_path: string) => {
  const { data, error, response } = await client.GET(
    "/v1/assets/{owner}/{name}",
    {
      params: {
        path: {
          owner,
          name: asset_path
        }
      },
      credentials: "include"
    }
  );
  return { data: data?.data, error, statusCode: response.status };
};

//TODO: Switch to api client
export const requestAccess = async (assetId: string, email?: string) => {
  const message = await client.POST("/v1/assets/{asset}/request", {
    body: {
      email: email
    },
    params: {
      path: {
        asset: assetId
      }
    }
  });

  return message;
};

export const getManifests = async (assetId: string) => {
  const token = getAccessToken();

  const headers: HeadersInit = {
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const response = await fetch(
    `${API_URL}/api/v1/assets/stream/${assetId}/manifests.json`,
    {
      method: "GET",
      headers
    }
  );

  return await response.json();
};
